import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ActiveLookup,
  Address,
  AddressModelKeys,
  BUSINESS_PERSONAL_TYPE,
  BUSINESS_PERSONAL_TYPE_LOOKUP,
  COUNTRIES,
} from '@ag-common-lib/public-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { ColumnButtonClickEvent, RowRemovingEvent } from 'devextreme/ui/data_grid';
import { LookupsService } from 'ag-common-svc/public-api';
import { FullAddressPipe } from 'ag-common-svc/shared/pipes/full-address.pipe';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { compareAddressesIsDifferent } from 'ag-common-svc/shared/utils/full-address';
import { AddressFormComponent } from 'ag-common-svc/lib/components/address-form/address-form.component';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { normalizeAddresses } from 'ag-common-svc/lib/utils/address.util';
import { alert } from 'devextreme/ui/dialog';
import { AddressesModalComponent } from '../addresses-modal/addresses-modal.component';

export enum AddressGridType {
  short = 'short',
  shortExtended = 'shortExtended',
  default = 'default',
}
@Component({
  selector: 'ag-shr-addresses-grid',
  templateUrl: './addresses-grid.component.html',
  styleUrls: ['./addresses-grid.component.scss'],
  providers: [FullAddressPipe],
})
export class AddressesGridComponent implements OnChanges {
  @HostBinding('class') className = 'addresses-grid';
  @ViewChild('addressesModalRef') addressesModalComponent: AddressesModalComponent;
  @ViewChild('addressFormRef', { static: false }) addressFormComponent: AddressFormComponent;

  @Input() addresses: Address[] = [];
  @Input() type: AddressGridType = AddressGridType.short;
  @Input() gridTitle: string;
  @Input() emptyMessage: string = 'No Addresses Currently Exist';
  @Input() hasPermissionsToAdd: boolean = true;
  @Input() hasPermissionsToEdit: boolean = true;
  @Input() hasPermissionsToDelete: boolean = true;
  @Input() showPhysicalLocation = true;
  @Input() showAddressType = true;
  @Input() showBillingAddress = true;
  @Input() showShippingAddress = true;
  @Input() updateAddress: (addresses: Address[]) => Promise<void>;
  @Input() typesLookup: Partial<ActiveLookup>[] = BUSINESS_PERSONAL_TYPE_LOOKUP;

  protected readonly countries = COUNTRIES;
  protected readonly AddressGridType = AddressGridType;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly statesLookup$: Observable<ActiveLookup[]> = this.lookupsService.statesLookup$;

  protected readonly inProgress$ = new BehaviorSubject<boolean>(false);
  protected addressToEdit: Address;
  protected addressFormTitle: string = '';

  protected readonly addressUniqAdapter = {
    getValue: e => {
      return this.addressToEdit;
    },
    bypass: () => {
      return !this.addressToEdit?.[AddressModelKeys.address1];
    },
    reset: e => {},
  };

  private defaultAddressType = BUSINESS_PERSONAL_TYPE.BUSINESS;

  protected inEditMode = false;

  constructor(
    private readonly lookupsService: LookupsService,
    protected fullAddressPipe: FullAddressPipe,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.typesLookup) {
      const typesLookup = changes.typesLookup.currentValue;
      const defaultAddressType =
        typesLookup?.find(lookup => lookup?.isDefault)?.[BaseModelKeys.dbId] ??
        typesLookup?.[0]?.[BaseModelKeys.dbId] ??
        BUSINESS_PERSONAL_TYPE.BUSINESS;
      this.defaultAddressType = defaultAddressType;
    }
  }

  protected enableEditMode = () => {
    this.inEditMode = true;
  };

  protected disableEditMode = () => {
    this.inEditMode = false;
  };

  protected handleAddAddress = () => {
    this.addressFormTitle = 'Add Address';

    const hasPrimaryBillingAddresses =
      !!this.addresses?.length && this.addresses?.some(address => address?.[AddressModelKeys.isPrimaryBilling]);
    const hasPrimaryShippingAddresses =
      !!this.addresses?.length && this.addresses?.some(address => address?.[AddressModelKeys.isPrimaryShipping]);

    const address = Object.assign({}, new Address(), {
      [AddressModelKeys.addressType]: this.defaultAddressType,
      [AddressModelKeys.isPrimaryBilling]: this.showBillingAddress && !hasPrimaryBillingAddresses,
      [AddressModelKeys.isPrimaryShipping]: this.showShippingAddress && !hasPrimaryShippingAddresses,
    });
    this.addressToEdit = address;

    this.addressesModalComponent.manageAddressModalComponent.showModal();
  };

  protected handleEditAddress = (e: ColumnButtonClickEvent) => {
    this.addressFormTitle = 'Edit Address';

    this.addressToEdit = e?.row?.data;

    this.addressesModalComponent.manageAddressModalComponent.showModal();
  };

  protected handleDeleteAddress = async (e: ColumnButtonClickEvent) => {
    const rowData: Address = e?.row?.data;
    const isPrimaryBilling = rowData?.[AddressModelKeys.isPrimaryBilling];
    const isPrimaryShipping = rowData?.[AddressModelKeys.isPrimaryShipping];

    if (isPrimaryBilling || isPrimaryShipping) {
      const name = [isPrimaryBilling && 'Billing', isPrimaryShipping && 'Shipping'].filter(Boolean).join('&nbsp&&nbsp');
      await alert(
        `<p>You cannot delete this address because it is marked as <b>Primary&nbsp;${name}</b> .</p> <br/> <p>Please set another address as your <b>Primary&nbsp;${name}</b> address first, then try again.</p>`,
        'Address is Primary',
      );
      return;
    }

    e.component.deleteRow(e.row.rowIndex);
  };

  protected handleRowRemoving = (e: RowRemovingEvent) => {
    const addresses = this.addresses.filter(address => address !== e.key);

    if (!this.updateAddress) {
      e.cancel = true;
      return;
    }
    e.cancel = this.updateAddress(addresses)
      .then(() => {
        return false;
      })
      .catch(() => {
        return true;
      });
  };

  protected canDeleteRow = e => {
    return !e.row.data.is_primary_billing && !e.row.data.is_primary_shipping;
  };

  protected checkIsAddressUniq = async () => {
    return this.addresses.every(address => {
      if (address === this.addressToEdit) {
        return true;
      }

      return compareAddressesIsDifferent(address, this.addressToEdit);
    });
  };

  protected handleAddressSave = async () => {
    this.inProgress$.next(true);

    const addresses = normalizeAddresses(this.addressToEdit, this.addresses);

    try {
      await this.handleAddressesUpdate(addresses);

      this.addressesModalComponent.manageAddressModalComponent.forceCloseModal();
    } catch (error) {
      this.inProgress$.next(false);
    }
  };

  protected handleClosePopup = () => {
    return this.addressFormComponent.onCancelEdit();
  };

  private handleAddressesUpdate = async addresses => {
    this.inProgress$.next(true);
    if (this.updateAddress) {
      await this.updateAddress(addresses);
    }

    this.inProgress$.next(false);
  };
}
