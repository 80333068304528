import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ChangeLogsConfig,
  ChangeLogsDataType,
  ChangeLogsType,
  FlightBookingKeys,
  FlightInfoKeys,
} from '@ag-common-lib/public-api';
import { Delta } from 'jsondiffpatch/lib/types';
import {
  normilizeLogArrayWithEmptyFirstValue
} from '../../utils/normalize-log';

@Component({
  selector: 'ag-shr-change-log-detail-by-type',
  templateUrl: './change-log-detail-by-type.component.html',
  styleUrls: ['./change-log-detail-by-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogDetailByTypeComponent {
  @Input() changeLogs: Delta;
  @Input() keysConfig: ChangeLogsConfig[];
  @Input() showRemovedData = true;

  protected readonly ChangeLogsType = ChangeLogsType;
  protected readonly ChangeLogsDataType = ChangeLogsDataType;
  protected readonly FlightInfoKeys = FlightInfoKeys;
  protected readonly FlightBookingKeys = FlightBookingKeys;
  protected readonly normilizeLogArrayWithEmptyFirstValue = normilizeLogArrayWithEmptyFirstValue;

  trackByKeyValue(index: number, item: ChangeLogsConfig) {
    return item.value;
  }

  isLogArray = log => Array.isArray(log);
}
