import { BaseModel, BaseModelKeys } from '../base.model';
import { AGENT_STATUS } from '../../lists/agent-status.enum';
import { AGENT_TYPE } from '../../lists/agent-type.enum';
import { PROSPECT_DISPOSITION } from '../../lists/prospect-disposition.enum';
import { PROSPECT_PRIORITY } from '../../lists/prospect-priority.enum';
import { PROSPECT_STATUS } from '../../lists/prospect-status.enum';
import { Role } from '../../lists/roles.enum';
import { EmailAddress, EmailAddressKeyMapping } from '../utils/email-address.model';
import { Address, AddressKeyMapping } from '../utils/address.model';
import { Favorite } from '../utils/favorite.model';
import { Goal } from '../utils/goal.model';
import { Social } from '../utils/social.model';
import { Website } from '../utils/website.model';
import { PhoneNumber, PhoneNumberKeyMapping } from '../utils/phone-number.model';
import { AgentCarrier } from '../utils/agent-carrier.model';
import { StateLicense } from '../utils/state-license.model';
import { REGISTRATION_STATUS } from '../../lists/registration-status.enum';
import { ApproveDenyReason } from '../utils/approve-deny-reason.model';
import { Association } from '../utils/association.model';
import { AgentReviewLevel } from '../../lists/agent-review-level.enum';
import { MappingProperty } from '@elastic/elasticsearch/lib/api/types';
import { AgentCampaigns } from '../crm/agent-campaigns.model';
import { Headshot } from '../utils/headshot.model';
import { TShirtSizes } from '../utils/t-shirt-seizes.model';
import { DietaryConsideration } from '../utils/dietary-consideration.model';
import { LocalDateTimeString } from '../utils/local-date-time-string.model';
import { FlightPreference } from 'ag-common-lib/lib/models/utils/flight-preference.model';

export const AGENTS_COLLECTION_NAME = 'agents';

export enum AgentDocumentCollections {
  emailAddresses = 'email-addresses',
  associations = 'associations',
  approveDenyReason = 'approve-deny-reason',
  gridsConfigurations = 'grids-configurations',
  media = 'media',
  fileDirectories = 'fileDirectories',
  pushNotifications = 'push-notifications',
  notificationChannels = 'notification-channels',
}

export enum AgentKeys {
  uid = 'uid',
  p_agent_id = 'p_agent_id',
  p_external_agent_id = 'p_external_agent_id',
  p_email = 'p_email',
  p_agent_name = 'p_agent_name',
  p_agent_first_name = 'p_agent_first_name',
  p_agent_middle_name = 'p_agent_middle_name',
  p_agent_last_name = 'p_agent_last_name',
  p_passport_first_name = 'p_passport_first_name',
  p_passport_last_name = 'p_passport_last_name',
  p_agency_id = 'p_agency_id',
  p_mga_id = 'p_mga_id',
  title = 'title',
  p_prefix = 'p_prefix',
  p_suffix = 'p_suffix',
  npn = 'npn',
  p_strategic_agent = 'p_strategic_agent',
  alliance_group_employee = 'alliance_group_employee',
  upline = 'upline',
  emailVerified = 'emailVerified',
  registrationDate = 'registrationDate',
  approvalDate = 'approvalDate',
  approvedBy = 'approvedBy',
  role = 'role',
  roles = 'roles',
  agent_type = 'agent_type',
  billing_shipping_same = 'billing_shipping_same',
  agencyName = 'agencyName',
  personal_goals = 'personal_goals',
  conference_goals = 'conference_goals',
  manager_goals = 'manager_goals',
  p_registered_user = 'p_registered_user',
  registration_source = 'registration_source',
  first_login_date = 'first_login_date',
  last_login_date = 'last_login_date',
  login_count = 'login_count',
  pwa_login_count = 'pwa_login_count',
  logged_in = 'logged_in',
  is_manager = 'is_manager',
  is_rmd = 'is_rmd',
  is_credited = 'is_credited',
  manager_id = 'manager_id',
  approve_deny_reason = 'approve_deny_reason',
  approve_deny_reasons = 'approve_deny_reasons',
  agency_approve_deny_reason = 'agency_approve_deny_reason',
  showSplashScreen = 'showSplashScreen',
  favorites = 'favorites',
  is_acb_user = 'is_acb_user',
  is_awb_user = 'is_awb_user',
  is_arm_user = 'is_arm_user',
  awb_site_id = 'awb_site_id',
  certifications = 'certifications',
  agent_status = 'agent_status',
  agent_review_level = 'agent_review_level',
  registrant_review_level_update_date = 'registrant_review_level_update_date',
  registration_status = 'registration_status',
  prospect_status = 'prospect_status',
  prospect_priority = 'prospect_priority',
  prospect_disposition = 'prospect_disposition',
  prospect_referred_to = 'prospect_referred_to',
  prospect_referred_to_date = 'prospect_referred_to_date',
  prospect_wrap_up_notes = 'prospect_wrap_up_notes',
  prospect_status_update_date = 'prospect_status_update_date',
  addresses = 'addresses',
  socials = 'socials',
  websites = 'websites',
  email_addresses = 'email_addresses',
  phone_numbers = 'phone_numbers',
  isActiveCampaigns = 'isActiveCampaigns',
  campaigns = 'campaigns',
  race = 'race',
  ethnicity = 'ethnicity',
  gender = 'gender',
  primary_language = 'primary_language',
  secondary_language = 'secondary_language',
  hobbies = 'hobbies',
  favorite_destination = 'favorite_destination',
  shoe_size = 'shoe_size',
  agent_carriers = 'agent_carriers',
  state_licenses = 'state_licenses',
  christmas_card = 'christmas_card',
  conference_poster = 'conference_poster',
  dob = 'dob',
  headshot = 'head_shot',
  fcmTokens = 'fcm_tokens',
  tShirtSizes = 'tShirtSizes',
  dietaryConsideration = 'dietaryConsideration',
  flightPreference = 'flightPreference',
  notificationChannels = 'notificationChannels',
}

export class Agent extends BaseModel {
  /**
   * Id Assigned to Agent by Google Auth.
   */
  [AgentKeys.uid]?: string = '';

  /**
   * Id Assigned to Agent from Alliance Group
   */
  [AgentKeys.p_agent_id]?: string = '';

  /**
   * Id Assigned to Agent from Agent's Parent MGA or Agency
   */
  [AgentKeys.p_external_agent_id]?: string = '';

  /**
   * Field that contains the Login ID of the Agent logged into the Portal.
   * Used for Authentication purposes
   */
  [AgentKeys.p_email]?: string = '';

  /**
   * Agent's full name. USed for Display purposes.
   * Generated by system by concatonating individual name fields
   */
  [AgentKeys.p_agent_name]?: string = '';
  /**
   * Agent's First Name
   */
  [AgentKeys.p_agent_first_name]?: string = '';

  /**
   * Agent's Middle Name
   */
  [AgentKeys.p_agent_middle_name]?: string = '';

  /**
   * Agent's Last Name
   */
  [AgentKeys.p_agent_last_name]?: string = '';

  /**
   * Agent's Passport / Official First Name
   */
  [AgentKeys.p_passport_first_name]?: string = '';

  /**
   * Agent's Passport / Official Last Name
   */
  [AgentKeys.p_passport_last_name]?: string = '';

  /**
   * Id of Agency assigned to Agent
   */
  [AgentKeys.p_agency_id]?: string = '';

  /**
   * If of MGA Assigned to Agent
   */
  [AgentKeys.p_mga_id]?: string = '';

  [AgentKeys.title]?: string = '';
  [AgentKeys.p_prefix]?: string = '';

  [AgentKeys.p_suffix]?: string = '';
  [AgentKeys.npn]?: string = '';
  [AgentKeys.p_strategic_agent]?: boolean = false;
  [AgentKeys.alliance_group_employee]?: boolean = false;
  [AgentKeys.upline]?: string;
  [AgentKeys.emailVerified]?: boolean = false;
  [AgentKeys.registrationDate]?: Date | number | string;
  [AgentKeys.approvalDate]?: Date | number | string;
  [AgentKeys.approvedBy]?: string = '';
  [AgentKeys.role]?: Role[] = [Role.AGENT];
  [AgentKeys.roles]?: string[];
  [AgentKeys.agent_type]?: AGENT_TYPE = AGENT_TYPE.GENERAL_AGENT;
  [AgentKeys.billing_shipping_same]?: boolean = false;
  [AgentKeys.agencyName]?: string = '';
  [AgentKeys.personal_goals]?: Goal[] = [];
  [AgentKeys.conference_goals]?: Goal[] = [];
  [AgentKeys.manager_goals]?: Goal[] = [];
  [AgentKeys.p_registered_user]?: boolean = false;
  [AgentKeys.registration_source]?: string = '';
  [AgentKeys.first_login_date]?: Date | number | string;
  [AgentKeys.last_login_date]?: Date | number | string;
  [AgentKeys.pwa_login_count]?: number = 0;
  [AgentKeys.login_count]?: number = 0;
  [AgentKeys.logged_in]?: boolean = false;
  [AgentKeys.is_manager]?: boolean = false;
  [AgentKeys.is_rmd]?: boolean = false;
  [AgentKeys.is_credited]?: boolean = false;
  [AgentKeys.manager_id]?: string = '';
  [AgentKeys.approve_deny_reason]?: string = '';
  [AgentKeys.approve_deny_reasons]?: ApproveDenyReason[] = [];
  [AgentKeys.agency_approve_deny_reason]?: string = '';
  [AgentKeys.showSplashScreen]?: boolean = false;
  [AgentKeys.favorites]?: Favorite[] = [];
  [AgentKeys.is_acb_user]?: boolean = false;
  [AgentKeys.is_awb_user]?: boolean = false;
  [AgentKeys.is_arm_user]?: boolean = false;
  [AgentKeys.awb_site_id]?: string = '';
  [AgentKeys.certifications]?: string = '';
  [AgentKeys.agent_status]?: AGENT_STATUS = AGENT_STATUS.NEW_AGENT;
  [AgentKeys.agent_review_level]?: AgentReviewLevel;
  [AgentKeys.registrant_review_level_update_date]?: Date;
  [AgentKeys.registration_status]?: REGISTRATION_STATUS = REGISTRATION_STATUS.RECORD_CREATED;
  [AgentKeys.prospect_status]?: PROSPECT_STATUS;
  [AgentKeys.prospect_status_update_date]?: Date;
  [AgentKeys.prospect_priority]?: PROSPECT_PRIORITY;
  [AgentKeys.prospect_disposition]?: PROSPECT_DISPOSITION;
  [AgentKeys.prospect_referred_to]?: string = '';
  [AgentKeys.prospect_referred_to_date]?: Date | number | string;
  [AgentKeys.prospect_wrap_up_notes]?: string = '';
  [AgentKeys.addresses]?: Address[] = [];
  [AgentKeys.socials]?: Social[] = [];
  [AgentKeys.websites]?: Website[] = [];
  // [AgentKeys.email_addresses]?: EmailAddress[] = [];
  [AgentKeys.phone_numbers]?: PhoneNumber[] = [];
  [AgentKeys.isActiveCampaigns]?: boolean = false;
  [AgentKeys.campaigns]?: AgentCampaigns;
  [AgentKeys.race]?: string = '';
  [AgentKeys.ethnicity]?: string = '';
  [AgentKeys.gender]?: string = '';
  [AgentKeys.primary_language]?: string = '';
  [AgentKeys.secondary_language]?: string = '';
  [AgentKeys.hobbies]?: string = '';
  [AgentKeys.favorite_destination]?: string = '';
  [AgentKeys.shoe_size]?: string = '';
  [AgentKeys.agent_carriers]?: AgentCarrier[] = [];
  [AgentKeys.state_licenses]?: StateLicense[] = [];
  [AgentKeys.christmas_card]?: boolean = false;
  [AgentKeys.conference_poster]?: boolean = false;

  // TODO fro development purposes
  [AgentKeys.dob]?: LocalDateTimeString;
  [AgentKeys.headshot]?: Headshot;
  [AgentKeys.tShirtSizes]?: TShirtSizes;
  [AgentKeys.dietaryConsideration]?: DietaryConsideration = null;
  [AgentKeys.flightPreference]?: FlightPreference = null;
  [AgentKeys.fcmTokens]?: { [key: string]: string };
  [AgentKeys.notificationChannels]?: string[];
}

export const ElasticAgentIndexedFields = [
  AgentKeys.p_agent_id,
  AgentKeys.uid,
  AgentKeys.p_email,
  AgentKeys.p_agent_last_name,
  AgentKeys.p_agent_first_name,
  AgentKeys.agent_status,
  AgentKeys.p_mga_id,
  AgentKeys.approve_deny_reason,
  AgentKeys.p_agency_id,
  AgentKeys.role,
  AgentKeys.agent_type,
  AgentKeys.prospect_status,
  AgentKeys.addresses,
  AgentKeys.phone_numbers,
  AgentKeys.is_rmd,
  AgentKeys.is_manager,
  AgentKeys.christmas_card,
  AgentKeys.conference_poster,
  AgentKeys.first_login_date,
  AgentKeys.last_login_date,
  AgentKeys.login_count,
  AgentKeys.roles,
  AgentKeys.headshot,
] as const;

export type ElasticSearchAgent = Pick<Agent, (typeof ElasticAgentIndexedFields)[number]> & {
  [BaseModelKeys.dbId]: string;
  [AgentKeys.email_addresses]: EmailAddress[];
};

export const AgentKeyMapping: {
  [key: string]: MappingProperty;
} = {
  [BaseModelKeys.dbId]: { type: 'keyword' },
  [AgentKeys.uid]: { type: 'keyword' },
  [AgentKeys.p_agent_id]: { type: 'keyword' },
  [AgentKeys.p_email]: { type: 'keyword' },
  [AgentKeys.p_agent_first_name]: { type: 'keyword' },
  [AgentKeys.p_agent_last_name]: { type: 'keyword' },
  [AgentKeys.p_agency_id]: { type: 'keyword' },
  [AgentKeys.p_mga_id]: { type: 'keyword' },
  [AgentKeys.role]: { type: 'keyword' },
  [AgentKeys.agent_type]: { type: 'keyword' },
  [AgentKeys.approve_deny_reason]: { type: 'keyword' },
  [AgentKeys.agency_approve_deny_reason]: { type: 'keyword' },
  [AgentKeys.agent_status]: { type: 'keyword' },
  [AgentKeys.prospect_status]: { type: 'keyword' },
  [AgentKeys.first_login_date]: { type: 'date' },
  [AgentKeys.last_login_date]: { type: 'date' },
  [AgentKeys.login_count]: { type: 'integer' },
  [AgentKeys.is_rmd]: { type: 'boolean' },
  [AgentKeys.is_manager]: { type: 'boolean' },
  [AgentKeys.christmas_card]: { type: 'boolean' },
  [AgentKeys.conference_poster]: { type: 'boolean' },
  [AgentKeys.headshot]: { type: 'object' },
  [AgentKeys.roles]: { type: 'keyword' }, // object type used for arrays
  [AgentKeys.addresses]: {
    type: 'nested',
    properties: AddressKeyMapping,
  },
  [AgentKeys.phone_numbers]: {
    type: 'nested',
    properties: PhoneNumberKeyMapping,
  },
  [AgentKeys.email_addresses]: {
    type: 'nested',
    properties: EmailAddressKeyMapping,
  },
};

export interface LegacyAgent extends Agent {
  email_addresses?: EmailAddress[];
  p_headshot_link: string;
  associations?: Association[];
}

export const AGENTS_TITLE_LIST = {
  [AgentKeys.p_agent_id]: 'Agent Id',
  [AgentKeys.p_email]: 'Email',
  [AgentKeys.p_agent_first_name]: 'First Name',
  [AgentKeys.p_agent_middle_name]: 'Middle Name',
  [AgentKeys.p_agent_last_name]: 'Last Name',
  [AgentKeys.p_agent_name]: 'Agent Name',
  [AgentKeys.tShirtSizes]: 'T-Shirt Sizes',
  [AgentKeys.p_mga_id]: 'MGA',
  [AgentKeys.p_agency_id]: 'Agency',
  [AgentKeys.agent_type]: 'Agent Type',
  [AgentKeys.is_manager]: 'Is Manager',
  [AgentKeys.is_rmd]: 'Is RMD',
  [AgentKeys.title]: 'Title',
  [AgentKeys.p_suffix]: 'Suffix',
  [AgentKeys.p_prefix]: 'Prefix',
  [AgentKeys.p_strategic_agent]: 'Strategic Agent',
  [AgentKeys.npn]: 'NPN',
  [AgentKeys.alliance_group_employee]: 'Alliance Group Employee',
  [AgentKeys.is_credited]: 'Is Credited',
  [AgentKeys.upline]: 'Upline',
  [AgentKeys.emailVerified]: 'Email Verified',
  [AgentKeys.registrationDate]: 'Registration Date',
  [AgentKeys.approvalDate]: 'Approval Date',
  [AgentKeys.approvedBy]: 'Approved By',
  [AgentKeys.role]: 'Role',
  [AgentKeys.roles]: 'Roles',
  [AgentKeys.phone_numbers]: 'Phone Numbers',
  [AgentKeys.christmas_card]: 'Christmas Card',
  [AgentKeys.conference_poster]: 'Conference Poster',
  [AgentKeys.addresses]: 'Addresses',
  [AgentKeys.billing_shipping_same]: 'Is Billing and Shipping The Same',
  [AgentKeys.agencyName]: 'Agency Name',
  [AgentKeys.p_registered_user]: 'Is Registered User',
  [AgentKeys.registration_source]: 'Registration Source',
  [AgentKeys.is_acb_user]: 'Is ACB User',
  [AgentKeys.is_awb_user]: 'Is AWB User',
  [AgentKeys.is_arm_user]: 'Is ARM User',
  [AgentKeys.awb_site_id]: 'AWB Site ID',
  [AgentKeys.certifications]: 'Certifications',
  [AgentKeys.agent_status]: 'Agent Status',
  [AgentKeys.prospect_status]: 'Prospect Status',
  [AgentKeys.prospect_priority]: 'Prospect Priority',
  [AgentKeys.prospect_disposition]: 'Prospect Disposition',
  [AgentKeys.registration_status]: 'Registration Status',
  [AgentKeys.prospect_referred_to]: 'Prospect Referred To',
  [AgentKeys.prospect_wrap_up_notes]: 'Prospect Wrap Up Notes',
  [AgentKeys.prospect_referred_to_date]: 'Prospect Referred To Date',
  [AgentKeys.prospect_status_update_date]: 'Prospect Status Update Date',
  [AgentKeys.primary_language]: 'Primary Language',
  [AgentKeys.secondary_language]: 'Secondary Language',
  [AgentKeys.email_addresses]: 'Email Addresses',
  [AgentKeys.race]: 'Race',
  [AgentKeys.ethnicity]: 'Ethnicity',
  [AgentKeys.gender]: 'Gender',
  [AgentKeys.isActiveCampaigns]: 'Is Active Campaigns',
  [AgentKeys.hobbies]: 'Hobbies',
  [AgentKeys.favorite_destination]: 'Favorite Destination',
  [AgentKeys.shoe_size]: 'Shoe Size',
  [AgentKeys.dob]: 'Date of Birth',
  [AgentKeys.headshot]: 'Headshot',
  [AgentKeys.dietaryConsideration]: 'Dietary Considerations',
  [AgentKeys.showSplashScreen]: 'Show Splash Screen',
  [AgentKeys.personal_goals]: 'Personal Goals',
  [AgentKeys.conference_goals]: 'Conference Goals',
  [AgentKeys.manager_goals]: 'Manager Goals',
  [AgentKeys.approve_deny_reason]: 'Approve Deny Reason',
  [AgentKeys.agency_approve_deny_reason]: 'Agency Approve Deny Reason',
  [AgentKeys.manager_id]: 'Manager ID',
  [AgentKeys.favorites]: 'Favorites',
  [AgentKeys.socials]: 'Socials',
  [AgentKeys.websites]: 'Websites',
  [AgentKeys.campaigns]: 'Campaigns',
  [AgentKeys.state_licenses]: 'State Licenses',
  [AgentKeys.agent_carriers]: 'Agent Carriers',
  [AgentKeys.notificationChannels]: 'Notification Channels',
  [AgentKeys.agent_review_level]: 'Review Level',
  [AgentKeys.flightPreference]: 'Flight Preference',
};
