import { Headshot, LookupKeys } from '@ag-common-lib/public-api';
import { CaptionOptions, CaptionPosition } from './template-builder-caption.model';
import { AspectRatio } from './template-builder-aspect-ration.model';
import { ObjectFit } from './template-builder-object-fit.model';

export enum VideoWidgetMode {
  single = 'single',
  grid = 'grid',
}

export const VideoWidgetModeMap = new Map([
  [VideoWidgetMode.single, 'Single'],
  [VideoWidgetMode.grid, 'Grid'],
]);

export const VIDEO_WIDGET_MODE_LOOKUP = [VideoWidgetMode.single, VideoWidgetMode.grid].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: VideoWidgetModeMap.get(value) };
});

export enum VideoSource {
  list = 'list',
  folder = 'folder',
}

export const VideoSourceMap = new Map([
  [VideoSource.list, 'From List'],
  [VideoSource.folder, 'From Folder'],
]);

export const VIDEO_SOURCE_LOOKUP = [VideoSource.list, VideoSource.folder].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: VideoSourceMap.get(value) };
});

export enum VideoServiceType {
  vimeo = 'vimeo',
  youtube = 'youtube',
}

export const VideoServiceTypeMap = new Map([
  [VideoServiceType.vimeo, 'Vimeo'],
  [VideoServiceType.youtube, 'YouTube'],
]);

export const VIDEO_SERVICE_TYPE_LOOKUP = [VideoServiceType.vimeo /* , VideoServiceType.youtube */].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: VideoServiceTypeMap.get(value) };
});

export class VideoWidgetItem {
  videoId?: string;
  serviceType: VideoServiceType = VideoServiceType.vimeo;
  title?: string = '';
  description?: string = '';
  headshot?: Headshot;
  autoplay?: boolean = false;
}

export class VideoWidgetData {
  mode: VideoWidgetMode = VideoWidgetMode.single;
  videoSource: VideoSource = VideoSource.list;
  items: VideoWidgetItem[] = [];
  folder: string = null;
  gap: number = 8;
  width: number = 100;
  minWidth: number = 128;
  maxHeight: number;
  videosPerRow: number = 3;
  rowsPerPage: number = 3;
  objectFit: ObjectFit = ObjectFit.cover;
  aspectRation: AspectRatio = AspectRatio.None;
  titleMode: CaptionPosition = CaptionPosition.none;
  titleOptions: CaptionOptions = new CaptionOptions();
  descriptionMode: CaptionPosition = CaptionPosition.none;
  descriptionOptions: CaptionOptions = new CaptionOptions(15, '#444');
}
