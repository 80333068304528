import { Lookups } from '../../lists/lookups.enum';
import { LookupBase } from '../utils/lookup-base.model';

export enum PersonalFieldsKeysEnum {
  rsvp = 'rsvp',
  personalInfo = 'personalInfo',
}

export enum ChangeLogsType {
  added = 'added',
  removed = 'removed',
  changed = 'changed',
  object = 'object',
}

export enum ChangeLogsDataType {
  default = 'default',
  localDateTimeString = 'LocalDateTimeString',
  timeStamp = 'timeStamp', // with __time__
  currency = 'currency',
  boolean = 'boolean',
  emailAddress = 'emailAddress',
  address = 'address',
  dietaryConsideration = 'DietaryConsideration',
  phoneNumber = 'phoneNumber',
  tShirtSizes = 'tShirtSizes',
  headshot = 'headshot',
  association = 'association',
  requestOutcome = 'requestOutcome',
  // hotel
  bedPreference = 'bedPreference',
  aidsRequired = 'aidsRequired',

  flightInformation = 'flightInformation',
  // agent
  approveDenyReason = 'approveDenyReason',
  goals = 'goals',
  favorites = 'favorites',
  agentCarrier = 'agentCarrier',
  agentStateLicense = 'agentStateLicense',
  agentCampaigns = 'agentCampaigns',
  agency = 'agency',
  manager = 'manager',
  flightPreference = 'flightPreference',
}

export enum ChangeLogsConfigKey {
  value = 'value',
  description = 'description',
  dataType = 'dataType',
  activeLookupName = 'activeLookupName',
  valueLookup = 'valueLookup',
  category = 'category',
}

export interface ChangeLogsConfig {
  value: string;
  description: string;
  dataType?: ChangeLogsDataType;
  activeLookupName?: Lookups;
  valueLookup?: LookupBase[];
  category?: string;
}
