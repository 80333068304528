import { AGENT_STATUS } from './agent-status.enum';

export const AGENT_STATUSES_LOOKUP = [
  { value: AGENT_STATUS.APPROVED, description: AGENT_STATUS.APPROVED },
  { value: AGENT_STATUS.CLOSED_PROSPECT, description: AGENT_STATUS.CLOSED_PROSPECT },
  { value: AGENT_STATUS.DENIED, description: AGENT_STATUS.DENIED },
  { value: AGENT_STATUS.IN_REVIEW, description: AGENT_STATUS.IN_REVIEW },
  { value: AGENT_STATUS.INACTIVE, description: AGENT_STATUS.INACTIVE },
  { value: AGENT_STATUS.NEW_AGENT, description: AGENT_STATUS.NEW_AGENT },
  { value: AGENT_STATUS.NEW_PROSPECT, description: AGENT_STATUS.NEW_PROSPECT },
  { value: AGENT_STATUS.NEW_REGISTRATION, description: AGENT_STATUS.NEW_REGISTRATION },
];
