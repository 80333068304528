import { Component, Input, ViewChild } from '@angular/core';
import { validateDxGroups } from '../../utils/validation';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { InitNewRowEvent } from 'devextreme/ui/data_grid';
import {
  AgStripeSubtotalKeys,
  DX_USD_CURRENCY_FORMAT,
  LookupKeys,
  ProcessRefundPayload,
  RefundReasonKeys,
  StripeTransaction,
  StripeTransactionKeys,
  TransactionsStatuses,
  TransactionType,
} from 'ag-common-lib/public-api';
import { AttendeeManualRefundPaymentModalService } from '../attendee-manual-refund-payment-modal/attendee-manual-refund-payment-modal.service';
import { confirm } from 'devextreme/ui/dialog';
import { CurrencyPipe } from '@angular/common';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ag-crm-attendee-manual-refund-modal',
  templateUrl: './attendee-manual-refund-payment-modal.component.html',
  styleUrls: ['./attendee-manual-refund-payment-modal.component.scss'],
})
export class AttendeeManualRefundPaymentModalComponent {
  @ViewChild('attendeeManualRefundModalRef', { static: true }) attendeeManualRefundModalComponent: ModalWindowComponent;
  @Input() handleSaveTransaction: (transaction: Partial<StripeTransaction>) => Promise<void>;

  protected formData: ProcessRefundPayload;
  inProgress$ = new BehaviorSubject<boolean>(false);
  protected readonly validationGroup = 'manualRefundValidationGroup';
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;
  protected readonly StripeTransactionKeys = StripeTransactionKeys;
  protected readonly AgStripeSubtotalKeys = AgStripeSubtotalKeys;
  protected readonly LookupKeys = LookupKeys;

  constructor(
    private manualRefundPaymentModalService: AttendeeManualRefundPaymentModalService,
    private currencyPipe: CurrencyPipe,
    private cloudFunctions: CloudFunctionsService,
    private toastrService: ToastrService,
  ) {}

  showModal = async (data?: Partial<StripeTransaction>): Promise<void> => {
    this.formData = this.manualRefundPaymentModalService.getFormData(data);
    this.attendeeManualRefundModalComponent?.showModal();
  };

  protected onInitNewRefundDetailRow = (e: InitNewRowEvent): void => {
    delete e.data.__KEY__;
  };

  protected onDetailsChanged = event => {
    this.formData[StripeTransactionKeys.details] = event;
  };

  protected onSaveRefunds = async e => {
    const isValid = await validateDxGroups(this.validationGroup);
    if (!isValid) {
      return;
    }
    const amount = this.formData[StripeTransactionKeys.details]?.reduce((n, { amount }) => n + amount, 0);
    const amountString = this.currencyPipe.transform(amount);
    const paymentIntent = this.formData[StripeTransactionKeys.parentStripeId].trim();
    const isConfirmed = await confirm(
      `<i>Are you sure you wish to process this refund ${amountString}? This action cannot be undone.</i>`,
      'Confirm',
    );
    if (!isConfirmed) {
      return;
    }

    this.inProgress$.next(true);
    const response = await this.cloudFunctions
      .refundPayment({
        amount: amount * 100, // to cents
        payment_intent: paymentIntent,
        reason: RefundReasonKeys.requestedByCustomer,
      })
      .catch(e => {
        this.toastrService.error('Failed to Refund');
        console.log('Error: ', e);
      });

    if (response) {
      this.toastrService.success('Refund Successfully Created');
      await this.saveRefundTransaction(response.data).finally(() => e?.component?.instance?.hide());
    }

    this.attendeeManualRefundModalComponent.hideModal();
    this.inProgress$.next(false);
  };

  private saveRefundTransaction = async refundData => {
    await this.handleSaveTransaction(
      Object.assign({}, this.formData, {
        [StripeTransactionKeys.stripeId]: refundData.id,
        [StripeTransactionKeys.type]: TransactionType.refund,
        [StripeTransactionKeys.status]: TransactionsStatuses.inProgress,
        [StripeTransactionKeys.description]: this.formData[StripeTransactionKeys.description],
        [StripeTransactionKeys.parentStripeId]: refundData.payment_intent,
      }),
    );
  };
}
