import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  administrationMenuEntityList,
  campaignMenuEntityList,
  Entity,
  notificationsHubMenuEntityList,
} from 'ag-common-lib/public-api';
import { AdminCrmRoutes } from 'src/app/admin-crm/admin-crm.model';
import { AppRoutes } from 'src/app/app.model';
import { map } from 'rxjs';
import { CampaignsRoutes } from 'src/app/ag-campaigns/ag-campaigns.model';
import { ARMAuthService } from 'src/app/shared/services/util/arm-auth.service';
import { NotificationsHubRoutes } from 'src/app/ag-notifications-hub/ag-notifications-hub.model';
import { InitializedEvent } from 'devextreme/ui/toolbar';
import { hasPermissionGuard } from 'ag-common-svc/shared/utils/has-permission-guard';
import { ItemClickEvent } from 'devextreme/ui/menu';

const dropDownWidth = 160;
const dropDownHeight = '10px';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() menuToggle = new EventEmitter<boolean>();

  @Input() menuToggleEnabled = false;
  @Input() title!: string;

  toolbarItems$ = this.authService.armPermissions$.pipe(
    map(permissions => {
      return [
        {
          location: 'before',
          widget: 'dxMenu',
          locateInMenu: 'auto',
          visible: hasPermissionGuard(
            [Entity.agentAdminister, Entity.agencyAdminister, Entity.carrierCrm, Entity.prospect, Entity.registrants],
            permissions,
          ),
          options: {
            orientation: 'horizontal',
            displayExpr: 'description',
            cssClass: 'ag-header__menu',
            items: [
              {
                description: 'Contacts',
                items: [
                  {
                    value: AppRoutes.Agents,
                    description: 'Agents',
                    visible: hasPermissionGuard([Entity.agentAdminister], permissions),
                  },
                  {
                    value: AppRoutes.Agencies,
                    description: 'Agencies',
                    visible: hasPermissionGuard([Entity.agencyAdminister], permissions),
                  },
                  {
                    value: AppRoutes.Carriers,
                    description: 'Carriers',
                    visible: hasPermissionGuard([Entity.carrierCrm], permissions),
                  },
                  {
                    value: AppRoutes.Prospects,
                    description: 'Prospects',
                    visible: hasPermissionGuard([Entity.prospect], permissions),
                  },
                  {
                    value: AppRoutes.Registrants,
                    description: 'Registrants',
                    visible: hasPermissionGuard([Entity.registrants], permissions),
                  },
                ],
              },
            ],
            onItemClick: ({ itemData }: any) => {
              if (itemData?.value) {
                itemData.routes
                  ? this.router.navigate([itemData.routes, itemData.value])
                  : this.router.navigate([itemData.value]);
              }
            },
          },
        },
        {
          location: 'before',
          widget: 'dxMenu',
          locateInMenu: 'auto',
          visible: hasPermissionGuard(
            [Entity.conference, Entity.conferenceRegistrants, Entity.conferenceListAdmin, Entity.conferenceList],
            permissions,
          ),
          options: {
            orientation: 'horizontal',
            displayExpr: 'description',
            cssClass: 'ag-header__menu',
            items: [
              {
                description: 'Registrations',
                items: [
                  {
                    value: AppRoutes.Events,
                    description: 'Events',
                    visible: hasPermissionGuard(
                      [Entity.conference, Entity.conferenceListAdmin, Entity.conferenceList],
                      permissions,
                    ),
                  },
                  {
                    value: AppRoutes.ConferenceRegistrations,
                    description: 'Event Registrations',
                    visible: hasPermissionGuard([Entity.conferenceRegistrants], permissions),
                  },
                ],
              },
            ],
            onItemClick: ({ itemData }: any) => {
              if (itemData?.value) {
                itemData.routes
                  ? this.router.navigate([itemData.routes, itemData.value])
                  : this.router.navigate([itemData.value]);
              }
            },
          },
        },
        {
          location: 'before',
          widget: 'dxMenu',
          locateInMenu: 'auto',
          visible: hasPermissionGuard(
            [
              Entity.notificationsChannels,
              Entity.notificationsScheduler,
              Entity.notificationsTemplates,
              Entity.dynamicListGroups,
              Entity.dynamicSegmentsGroups,
            ],
            permissions,
          ),
          options: {
            orientation: 'horizontal',
            displayExpr: 'description',
            cssClass: 'ag-header__menu',
            items: [
              {
                description: 'Notifications Hub',
                items: [
                  {
                    value: NotificationsHubRoutes.NotificationsTemplates,
                    description: 'Notifications Templates',
                    visible: hasPermissionGuard([Entity.notificationsTemplates], permissions),
                    routes: AppRoutes.NotificationsHub,
                  },
                  {
                    value: NotificationsHubRoutes.NotificationsChannels,
                    description: 'Notifications Channels',
                    visible: hasPermissionGuard([Entity.notificationsChannels], permissions),
                    routes: AppRoutes.NotificationsHub,
                  },
                  {
                    value: NotificationsHubRoutes.NotificationsScheduler,
                    description: 'Notifications Scheduler',
                    visible: hasPermissionGuard([Entity.notificationsScheduler], permissions),
                    routes: AppRoutes.NotificationsHub,
                  },
                  {
                    description: 'Lists and Segments',
                    visible: hasPermissionGuard([Entity.dynamicSegmentsGroups, Entity.dynamicListGroups], permissions),
                    items: [
                      {
                        value: NotificationsHubRoutes.DynamicLists,
                        description: 'Lists',
                        visible: hasPermissionGuard([Entity.dynamicListGroups], permissions),
                        routes: AppRoutes.NotificationsHub,
                      },
                      {
                        value: NotificationsHubRoutes.DynamicSegments,
                        description: 'Segments',
                        visible: hasPermissionGuard([Entity.dynamicSegmentsGroups], permissions),
                        routes: AppRoutes.NotificationsHub,
                      },
                    ],
                  },
                ],
              },
            ],
            onItemClick: ({ itemData }: any) => {
              if (itemData?.value) {
                itemData.routes
                  ? this.router.navigate([itemData.routes, itemData.value])
                  : this.router.navigate([itemData.value]);
              }
            },
          },
        },
        {
          location: 'before',
          widget: 'dxMenu',
          locateInMenu: 'auto',
          visible: hasPermissionGuard(campaignMenuEntityList, permissions),
          options: {
            orientation: 'horizontal',
            displayExpr: 'description',
            cssClass: 'ag-header__menu',
            items: [
              {
                description: 'Campaigns',
                items: [
                  {
                    value: CampaignsRoutes.CampaignServices,
                    description: 'Campaign Services',
                    visible: hasPermissionGuard([Entity.campaignServices], permissions),
                    routes: AppRoutes.Campaign,
                  },
                  {
                    value: CampaignsRoutes.CampaignManagementTaskBoard,
                    description: 'Task Board',
                    visible: hasPermissionGuard([Entity.campaignTaskBoard], permissions),
                    routes: AppRoutes.Campaign,
                  },
                  {
                    value: CampaignsRoutes.CampaignManagementTaskGrid,
                    description: 'Task Grid',
                    visible: hasPermissionGuard([Entity.campaignTaskGrid], permissions),
                    routes: AppRoutes.Campaign,
                  },
                ],
              },
            ],
            onItemClick: ({ itemData }: any) => {
              if (itemData?.value) {
                itemData.routes
                  ? this.router.navigate([itemData.routes, itemData.value])
                  : this.router.navigate([itemData.value]);
              }
            },
          },
        },
        {
          location: 'before',
          widget: 'dxMenu',
          locateInMenu: 'auto',
          visible: hasPermissionGuard([Entity.reportChristmasCardList], permissions),
          options: {
            orientation: 'horizontal',
            displayExpr: 'description',
            cssClass: 'ag-header__menu',
            items: [
              {
                description: 'Reports',
                items: [
                  {
                    value: AppRoutes.ChristmasCardList,
                    description: 'Christmas Card List',
                    visible: hasPermissionGuard([Entity.reportChristmasCardList], permissions),
                  },
                ],
              },
            ],
            onItemClick: ({ itemData }: any) => {
              if (itemData?.value) {
                itemData.routes
                  ? this.router.navigate([itemData.routes, itemData.value])
                  : this.router.navigate([itemData.value]);
              }
            },
          },
        },
        {
          location: 'before',
          widget: 'dxMenu',
          locateInMenu: 'auto',
          visible: hasPermissionGuard(administrationMenuEntityList, permissions),
          options: {
            orientation: 'horizontal',
            displayExpr: 'description',
            cssClass: 'ag-header__menu',
            items: [
              {
                description: 'Administration',
                items: [
                  {
                    description: 'User Roles',
                    items: [
                      {
                        value: AdminCrmRoutes.UserRolesManager,
                        description: 'Assignment',
                        visible: hasPermissionGuard([Entity.administrationUserManager], permissions),
                        routes: AppRoutes.Administration,
                      },
                      {
                        value: AdminCrmRoutes.RoleManager,
                        description: 'Management',
                        visible: hasPermissionGuard([Entity.administrationRoleManager], permissions),
                        routes: AppRoutes.Administration,
                      },
                    ],
                  },
                  {
                    value: AdminCrmRoutes.TaskListManager,
                    description: 'Task Lists',
                    visible: hasPermissionGuard([Entity.administrationTaskListManager], permissions),
                    routes: AppRoutes.Administration,
                  },
                  {
                    value: AdminCrmRoutes.ImportRulesManager,
                    description: 'Import Rules',
                    visible: hasPermissionGuard([Entity.administrationImportRulesManager], permissions),
                    routes: AppRoutes.Administration,
                  },
                  {
                    value: AdminCrmRoutes.LookupsManager,
                    description: 'Lookups Manager',
                    visible: hasPermissionGuard([Entity.administrationLookupsManager], permissions),
                    routes: AppRoutes.Administration,
                  },
                  {
                    value: AdminCrmRoutes.ErrorsManager,
                    description: 'Errors Manager',
                    visible: hasPermissionGuard([Entity.administrationErrorsManager], permissions),
                    routes: AppRoutes.Administration,
                  },
                  {
                    value: AdminCrmRoutes.LogsManager,
                    description: 'Logs Manager',
                    visible: hasPermissionGuard([Entity.administrationLogsManager], permissions),
                    routes: AppRoutes.Administration,
                  },

                  {
                    value: AdminCrmRoutes.ReportsManager,
                    description: 'Reports Manager',
                    visible: hasPermissionGuard([Entity.administrationReportsManager], permissions),
                    routes: AppRoutes.Administration,
                  },
                  {
                    value: AdminCrmRoutes.SalesGoals,
                    description: 'Sales Goals',
                    visible: hasPermissionGuard([Entity.agentSalesGoal], permissions),
                    routes: AppRoutes.Administration,
                  },

                  {
                    value: AdminCrmRoutes.GoogleAuthManagement,
                    description: 'Google Auth Management',
                    visible: hasPermissionGuard([Entity.administrationGoogleAuthManager], permissions),
                    routes: AppRoutes.Administration,
                  },
                  {
                    value: AdminCrmRoutes.AuthStats,
                    description: 'Auth Stats',
                    visible: hasPermissionGuard([Entity.administrationAuthStats], permissions),
                    routes: AppRoutes.Administration,
                  },
                  {
                    value: AdminCrmRoutes.AgentMergeTool,
                    description: 'Agent Merge Tool',
                    visible: hasPermissionGuard([Entity.administrationMergeTool], permissions),
                    routes: AppRoutes.Administration,
                  },
                  {
                    value: AdminCrmRoutes.AddressesManagement,
                    description: 'Addresses Management',
                    visible: false, //hasPermissionGuard([Entity.administrationMergeTool], permissions),
                    routes: AppRoutes.Administration,
                  },
                ],
              },
            ],
            onItemClick: ({ itemData }: any) => {
              if (itemData?.value) {
                itemData.routes
                  ? this.router.navigate([itemData.routes, itemData.value])
                  : this.router.navigate([itemData.value]);
              }
            },
          },
        },
      ];
    }),
  );

  userBoxdropDownOptions$ = this.authService.loggedInAgent$.pipe(
    map(({ p_email }) => {
      return {
        title: p_email,
        showTitle: true,
        width: 300,
        wrapperAttr: { class: 'ag-header__user-menu ' },
      };
    }),
  );

  protected readonly menuHeight = dropDownHeight;
  protected readonly AppRoutes = AppRoutes;

  constructor(
    private authService: ARMAuthService,
    private router: Router,
  ) {}

  protected onToolbarInitialized = (e: InitializedEvent) => {
    setTimeout(() => {
      e.component.repaint();
    }, 700);
  };

  toggleMenu = () => {
    this.menuToggle.emit();
  };

  navigateToPath(location: AppRoutes) {
    switch (location) {
      case AppRoutes.Home:
      case AppRoutes.Profile:
        this.router.navigate([location]);
        break;

      case AppRoutes.Logout:
        this.authService.logOut();
        break;
    }
  }

  protected onUserMenuItemClick = ({ itemData }: any) => {
    if (itemData?.value) {
      this.navigateToPath(itemData.value);
    }
  };
}
