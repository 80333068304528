<ag-shr-modal-window
  #imageCropModalRef
  [title]="imageCropperTitle"
  [width]="'80%'"
  [height]="'80%'"
  [isFullScreen]="true"
  [showFullScreenButton]="false"
  (onSaveClick)="handleApplyCrop()"
  [actionTitle]="'CROP'"
  [useScrollView]="false"
  [customClass]="'image-cropper-modal__popup'"
  (onShown)="isShown = true"
>
  <div *ngIf="imageCropModalRef?.popupComponent?.visible ?? false as isVisible" class="image-cropper-modal__content">
    <div class="image-cropper-modal__toolbar" *ngIf="false">
      <dx-button type="default" stylingMode="outlined" icon="minus" hint="Zoom out" (onClick)="zoomOut()"></dx-button>

      <dx-button type="default" stylingMode="outlined" icon="add" hint="Zoom in" (onClick)="zoomIn()"></dx-button>

      <dx-button
        *ngIf="false"
        type="default"
        [text]="containWithinAspectRatio ? 'Fill Aspect Ratio' : 'Contain Within Aspect Ratio'"
        (onClick)="toggleContainWithinAspectRatio()"
      ></dx-button>

      <dx-button type="normal" text="Reset image" (onClick)="resetImage()"></dx-button>
    </div>

    <div #cropperContainerRef *ngIf="isShown" class="image-cropper-modal__crop-popup-content">
      <image-cropper
        class="image-cropper-modal__cropper"
        [imageURL]="pictureUrl"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="imageLoaded('loadImageFailed')"
        (startCropImage)="imageLoaded('startCropImage')"
        [maintainAspectRatio]="maintainAspectRatio"
        [aspectRatio]="ratio?.w / ratio?.h"
        [containWithinAspectRatio]="containWithinAspectRatio"
        format="png"
        [onlyScaleDown]="true"
        [roundCropper]="roundCropper"
        [(transform)]="transform"
      ></image-cropper>

      <dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: cropperContainerRef }"
        [visible]="isVisible && !isCropperReady"
        [wrapperAttr]="{ class: 'image-cropper-modal__cropper' }"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [delay]="301"
        [animation]="{
          hide: {
            delay: 1000,
          },
        }"
      >
      </dx-load-panel>
    </div>
  </div>
</ag-shr-modal-window>
