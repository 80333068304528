import { Pipe, PipeTransform } from '@angular/core';
import * as jsondiffpatch from 'jsondiffpatch';
import { Delta } from 'jsondiffpatch/lib/types';
import { getJsonFromWasabiFile } from 'ag-common-svc/lib/utils/json-from-wasabifile.util';

@Pipe({
  name: 'changeLogsDiff',
})
export class ChangeLogsDiffPipe implements PipeTransform {
  async transform(path: string, isGuest = false): Promise<Delta> {
    const json = await getJsonFromWasabiFile(path);
    const delta = jsondiffpatch.diff(json?.oldData, json?.newData);
    return { diff: isGuest ? delta : delta?.['data'], oldData: json?.oldData };
  }
}
