import { FileDirectory, SystemFileDirectories } from 'ag-common-lib/lib/models/utils/file-directory.model';

export enum AgencyFileDirectoryCollections {
  files = 'files',
}

export enum AgencyFileDirectoryKeys {
  agencyDbId = 'agencyDbId',
}

export class AgencyFileDirectory extends FileDirectory {
  [AgencyFileDirectoryKeys.agencyDbId]: string;
}

export const AGENCY_SYSTEM_DIRECTORY_NAMES: string[] = [SystemFileDirectories.Images, SystemFileDirectories.Documents];
