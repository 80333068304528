import { Delta } from 'jsondiffpatch/lib/types';
import { isNil } from 'lodash';
import { LookupBase } from '@ag-common-lib/public-api';

export const normalizeLog = (key, value: Delta) => ({ [key]: value[key] });
export const hasLog = (key, value: Delta) => !!key && Object.keys(value)?.some(id => id === key);
export const logHasKey = (key, value) => !!key && value?.hasOwnProperty(key);

export const getChangedLog = log => {
  if (!Array.isArray(log)) {
    return log;
  }
  return log.length == 2 && isNil(log[0]) ? log[1] : log[0];
};

export const getDateTimeLog = dateTimeValue => {
  if (!Array.isArray(dateTimeValue)) {
    return getDateTimeValue(dateTimeValue);
  }
  const fromDateTimeValue = getDateTimeValue(dateTimeValue[0]);
  const toDateTimeValue = getDateTimeValue(dateTimeValue[1]);

  return !toDateTimeValue ? [fromDateTimeValue] : [fromDateTimeValue, toDateTimeValue];
};

const getDateTimeValue = value => {
  if (value && typeof value === 'object' && '__time__' in value) {
    return value.__time__;
  }

  if (value && typeof value === 'string') {
    return value;
  }

  return null;
};

export const getLogIndexes = logs => {
  return logs?.filter(item => item[0] !== '_t').map((item, index) => item[0] ?? index);
};

export const isRemovedLog = ind => ind.toString().includes('_');

export const isLogArray = log => (Array.isArray(log) ? log[0] : log);

export const normilizeLogArrayWithEmptyFirstValue = log => {
  // === 3 -=> remove status
  return log?.length === 3 || log[0]?.length ? log : log.filter(Boolean);
};

export const normalizeToValue = (data, valueLookup?: LookupBase[]) =>
  valueLookup ? valueLookup.find(({ value }) => value === data)?.description : data;

export const getLogDiffValues = (data: Delta, valueLookup?: LookupBase[]) => {
  if (!data) {
    return null;
  }
  const registrationData = isLogArray(data);
  let fromValue = null;
  let toValue = null;
  Object.entries(registrationData).forEach(([key, value]) => {
    fromValue = getFromValue(value, valueLookup);
    toValue = getToValue(value, valueLookup);
  });
  return { fromValue, toValue };
};

export const getToValue = (registrationData, valueLookup: LookupBase[] = null): string => {
  const toValue = Array.isArray(registrationData)
    ? registrationData.length > 1
      ? registrationData[1]
      : registrationData[0]
    : registrationData;
  const normilizedToValue = normalizeToValue(toValue, valueLookup);
  return !!normilizedToValue ? normilizedToValue : null;
};

export const getFromValue = (registrationData, valueLookup: LookupBase[] = null): string => {
  if (Array.isArray(registrationData) && registrationData?.length > 1) {
    return isNil(registrationData[0]) ? null : normalizeToValue(registrationData[0], valueLookup);
  }

  return null;
};
