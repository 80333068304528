import { TemplateBuilderWidget } from './template-builder-widget.model';
import {
  generateShortUuid
} from '../utils/generate-item-uuid';

export class TemplateBuilderColumn {
  // padding: Padding;
  id: string;
  items: TemplateBuilderWidget[] = [];

  constructor(public ratio: number) {
    this.id = generateShortUuid();
  }
}
