import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import videojs from 'video.js';
import videojsQualitySelector from '@silvermine/videojs-quality-selector';
import { WasabiImgPipe } from 'ag-common-svc/shared/pipes/wasabi-img.pipe';
import { Headshot, HeadshotKeys } from '@ag-common-lib/lib/models/utils/headshot.model';
import ShareButton from './custom-plugins/share-button.component';
import { VimeoVideoShareModalComponent } from './components/vimeo-video-share-modal/vimeo-video-share-modal.component';

videojsQualitySelector(videojs);

@UntilDestroy()
@Component({
  selector: 'ag-shr-vimeo-video',
  templateUrl: './vimeo-video.component.html',
  styleUrls: ['./vimeo-video.component.scss'],
})
export class VimeoVideoComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class') className = 'vimeo-video';
  @ViewChild('vimeoVideoRef', { static: false }) videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('shareMediaModalRef', { static: false }) shareMediaModalComponent: VimeoVideoShareModalComponent;

  @Input() shareVideo = false;
  @Input() autoplay = false;
  @Input() muted = false;
  @Input() showControls = true;
  @Input() preloadMode: 'none' | 'auto' | 'metadata' = 'none';
  @Input() set headshot(headshot: Headshot) {
    this.poster = this.wasabiImgPipe.transform(headshot[HeadshotKeys.wasabiPath], {
      cropperPayload: headshot[HeadshotKeys.imageCropperPayload],
    });
  }

  @Input() set vimeoVideo(response: any) {
    this.sources = Array.isArray(response?.files)
      ? response?.files
          .filter(source => source?.rendition !== 'adaptive')
          .sort((a, b) =>
            `${a.public_name}`?.localeCompare(b.public_name, 'en', {
              numeric: true,
              sensitivity: 'base',
              ignorePunctuation: true,
            }),
          )
      : [];

    this.poster = this.headshot
      ? this.wasabiImgPipe.transform(this.headshot[HeadshotKeys.wasabiPath], {
          cropperPayload: this.headshot[HeadshotKeys.imageCropperPayload],
        })
      : response?.pictures.base_link;

    this._videoLink = response.link;
    this._videoTitle = response.name;
  }

  protected sources: any[];
  protected poster: string;

  private _player: any;
  private _videoLink: string;
  private _videoTitle: string;

  constructor(private wasabiImgPipe: WasabiImgPipe) {}

  ngAfterViewInit(): void {
    // to fix error: No player element found
    setTimeout(() => {
      this.initializePlayer();
    }, 2000);
  }

  ngOnDestroy(): void {
    if (this._player) {
      this._player.dispose();
    }
  }

  private initializePlayer() {
    const videoElement = this.videoElement.nativeElement;
    const options = {
      controls: this.showControls,
      autoplay: this.autoplay,
      preload: this.preloadMode,
      muted: this.muted,
    };

    this._player = videojs(videoElement, options, this.onPlayerReady.bind(this));
  }

  private onPlayerReady() {
    this._player.controlBar.addChild('QualitySelector');

    if (this.shareVideo) {
      videojs.registerComponent('ShareButton', ShareButton);
      this._player.controlBar.addChild('ShareButton', { videoLink: this._videoLink, videoTitle: this._videoTitle });
      this._player.on('share', () => {
        this.shareMediaModalComponent.showModal(this._videoLink, this._videoTitle)
      });
    }
  }
}
