export enum ShareMediaButtonActionType {
  facebook = 'facebook',
  twitter = 'twitter',
  mailTo = 'mailTo',
  tumblr = 'tumblr',
  linkedin = 'linkedin',
}

export const ShareMediaButtonActionMap = new Map([
  [ShareMediaButtonActionType.facebook, 'facebook'],
  [ShareMediaButtonActionType.mailTo, 'email'],
  [ShareMediaButtonActionType.twitter, 'twitter'],
  [ShareMediaButtonActionType.tumblr, 'tumblr'],
  [ShareMediaButtonActionType.linkedin, 'linkedin'],
]);

export interface ShareMediaButtonAction {
  text: string;
  icon: string;
  action: ShareMediaButtonActionType;
}

export const SHARE_MEDIA_BUTTON_ACTION: ShareMediaButtonAction[] = [
  {
    text: ShareMediaButtonActionMap.get(ShareMediaButtonActionType.facebook),
    icon: 'agi-facebook-f',
    action: ShareMediaButtonActionType.facebook,
  },
  {
    text: ShareMediaButtonActionMap.get(ShareMediaButtonActionType.twitter),
    icon: 'agi-twitter-x',
    action: ShareMediaButtonActionType.twitter,
  },
  {
    text: ShareMediaButtonActionMap.get(ShareMediaButtonActionType.tumblr),
    icon: 'agi-tumblr',
    action: ShareMediaButtonActionType.tumblr,
  },
  {
    text: ShareMediaButtonActionMap.get(ShareMediaButtonActionType.linkedin),
    icon: 'agi-linkedin-in',
    action: ShareMediaButtonActionType.linkedin,
  },
  {
    text: ShareMediaButtonActionMap.get(ShareMediaButtonActionType.mailTo),
    icon: 'email',
    action: ShareMediaButtonActionType.mailTo,
  },
];
