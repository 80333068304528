import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AgMediaUploadModel } from '../ag-media-uploader-modal.models';
import { AgMediaUploaderModalService, FileData } from '../ag-media-uploader-modal.service';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { GetImageContentByUrlResponse } from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../../../services/cloud-functions.service';
import { buildUIBase64 } from '../ag-media-uploader-modal.utils';

@Component({
  selector: 'ag-shr-upload-from-url-tab',
  templateUrl: './upload-from-url-tab.component.html',
  styleUrls: ['./upload-from-url-tab.component.scss'],
})
export class UploadFromUrlTabComponent {
  @HostBinding('class') className = 'upload-from-disk-tab';
  imageUrlBlob: string | null = null;

  protected imagePreviewUrl: string;

  private _response: GetImageContentByUrlResponse;

  @Output() mediaUrlChange = new EventEmitter<AgMediaUploadModel | null>();

  _isImageValid$ = new BehaviorSubject(true);

  constructor(
    private agMediaUploaderService: AgMediaUploaderModalService,

    private cloudFunctionsService: CloudFunctionsService,
  ) {}

  handleIsImageValid = async isValid => {
    this._isImageValid$.next(isValid);

    if (isValid) {
      const imageBase64FileSize = this.getFileSizeFromBase64InBytes(this._response?.base64);
      const imageData = Object.assign({}, this._response as Partial<FileData>, { fileName: '', fileSize: imageBase64FileSize }) as FileData;
      this.agMediaUploaderService.setFile(imageData);

      return;
    }
  };

  imageUrlToBase64 = async (event: ValueChangedEvent) => {
    if (!event.value) {
      return;
    }
    const response = await this.cloudFunctionsService.getImageContentByUrl({ url: event.value }).catch(() => {
      this._isImageValid$.next(false);
      return null;
    });

    if (!response) {
      this.agMediaUploaderService.setFile(null);
      this.imagePreviewUrl = null;
      return;
    }

    this._response = response;
    this.imagePreviewUrl = buildUIBase64(response?.base64, response?.contentType);
  };

  clear() {
    this.imagePreviewUrl = null;
    this._response = null;
  }

  private getFileSizeFromBase64InBytes = (base64String: string): number => {
    if (!base64String?.length) {
      return 0;
    }

    return base64String.length * (3 / 4);
  };
}
