import { LookupKeys } from '@ag-common-lib/public-api';
import { Item } from 'devextreme/ui/button_group';

export enum AlignItems {
  flexStart = 'flex-start',
  center = 'center',
  flexEnd = 'flex-end',
  normal = 'normal',
}

export const AlignItemsMap = new Map([
  [AlignItems.flexStart, 'Left'],
  [AlignItems.center, 'Center'],
  [AlignItems.flexEnd, 'Right'],
  [AlignItems.normal, 'Stretch'],
]);

export const AlignItemsIconsMap = new Map([
  [AlignItems.flexStart, 'alignleft'],
  [AlignItems.center, 'aligncenter'],
  [AlignItems.normal, 'alignjustify'],
  [AlignItems.flexEnd, 'alignright'],
]);

export const ALIGN_ITEMS_LOOKUP = [AlignItems.flexStart, AlignItems.center, AlignItems.normal, AlignItems.flexEnd].map(
  value => {
    return { value: value, icon: AlignItemsIconsMap.get(value), hint: AlignItemsMap.get(value) };
  },
);
