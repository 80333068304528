import { Component, HostBinding, ViewChild } from '@angular/core';
import { PushNotificationCampaign, PushNotificationCampaignKeys } from '@ag-common-lib/lib';
import { AgentKeys } from '@ag-common-lib/public-api';
import { PushNotificationsCampaignService } from 'ag-common-svc/public-api';
import { NotificationCampaignModalComponent } from '../notification-campaign-modal/notification-campaign-modal.component';
import { NotificationAddRecipientsModalComponent } from '../notification-add-recipients-modal/notification-add-recipients-modal.component';
import { CellClickEvent } from 'devextreme/ui/data_grid';
import { omit } from 'lodash';
import { CloudFunctionsService } from 'ag-common-svc/lib/services/cloud-functions.service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { NotificationSchedulerEditModalComponent } from '../notifications-scheduler/notification-scheduler-edit-modal/notification-scheduler-edit-modal.component';

@Component({
  selector: 'ag-shr-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent {
  @HostBinding('class') className = 'notifications-list';
  @ViewChild('notificationHubModalRef', { static: false })
  protected notificationCampaignModalComponent: NotificationCampaignModalComponent;

  @ViewChild('addRecipientsModalRef', { static: false })
  protected notificationAddRecipientsModalComponent: NotificationAddRecipientsModalComponent;

  @ViewChild('notificationSchedulerEditModalRef', { static: false })
  protected notificationSchedulerEditModalComponent: NotificationSchedulerEditModalComponent;

  protected AgentKeys = AgentKeys;
  protected PushNotificationCampaignKeys = PushNotificationCampaignKeys;
  protected dataSource$ = this.pushNotificationsCampaignService.getList();

  private _notificationToSend: PushNotificationCampaign;

  constructor(
    private pushNotificationsCampaignService: PushNotificationsCampaignService,
    private cloudFunctionsService: CloudFunctionsService,
  ) {}

  protected createNotificationCampaign = () => {
    this.notificationCampaignModalComponent.showModal();
  };

  protected sendNotifications = async ({ row: { data } }: CellClickEvent<PushNotificationCampaign>) => {
    this._notificationToSend = data;

    this.notificationSchedulerEditModalComponent.showModal(data);

    // this.notificationAddRecipientsModalComponent.showModal();
  };

  protected editNotificationCampaign = ({ row: { data } }: CellClickEvent<PushNotificationCampaign>) => {
    this.notificationCampaignModalComponent.showModal(data);
  };

  protected copyNotificationCampaign = ({ row: { data } }: CellClickEvent<PushNotificationCampaign>) => {
    const dataToCopy = omit(data, Object.values(BaseModelKeys));

    this.notificationCampaignModalComponent.showModal(dataToCopy);
  };

  protected onAddRecipients = async recipients => {
    const payload = Object.assign(this._notificationToSend, {
      [PushNotificationCampaignKeys.recipients]: recipients,
    });

    await this.cloudFunctionsService.sendPushNotification(payload);
  };
}
