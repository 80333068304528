export class Heading1TextWidgetData {
  innerHtml?: string =
    '<h1 style="font-family: \'Open Sans\', sans-serif; font-size: 54px; letter-spacing: normal; line-height: 1.4; color: #091D45; text-align: center;">Heading 1</h1>\n';
}

export class Heading2TextWidgetData {
  innerHtml?: string =
    '<h2 style="font-family: \'Open Sans\', sans-serif; font-size: 36px; letter-spacing: normal; line-height: 1.4; color: #091D45; text-align: center;">Heading 2</h2>\n';
}

export class Heading3TextWidgetData {
  innerHtml?: string =
    '<h3 style="font-family: \'Open Sans\', sans-serif; font-size: 24px; letter-spacing: normal; line-height: 1.4; color: #091D45; text-align: center;">Heading 3</h3>\n';
}

export class Heading4TextWidgetData {
  innerHtml?: string =
    '<h4 style="font-family: \'Open Sans\', sans-serif; font-size: 18px; letter-spacing: normal; line-height: 1.4; color: #091D45; text-align: center;">Heading 4</h4>\n';
}
