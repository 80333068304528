<dx-form
  #agentHeaderFormRef
  class="agent-header__form"
  [formData]="agentFormData"
  [labelMode]="'floating'"
  [screenByWidth]="formSize$ | async | formScreenByWidth: { md: 980 }"
  (onInitialized)="onFormInitialized($event)"
>
  <dxi-item itemType="group" [colCountByScreen]="tripleColCountByScreen">
    <dxi-item itemType="group" [colCountByScreen]="nameItemColCountByScreen">
      <dxi-item [dataField]="AgentKeys.p_prefix">
        <dxo-label [text]="'Prefix'"></dxo-label>
        <div *dxTemplate>
          <ag-shr-prefix-select-box
            [value]="agentFormData?.p_prefix"
            (valueChange)="agentFormData.p_prefix = $event"
            (selectedItemChange)="handlePrefixSelect($event)"
            [showDropDownButton]="false"
          ></ag-shr-prefix-select-box>
        </div>
      </dxi-item>

      <dxi-item [dataField]="AgentKeys.p_agent_first_name" [colSpan]="2">
        <dxo-label [text]="'First Name'"></dxo-label>
      </dxi-item>
    </dxi-item>

    <dxi-item [dataField]="AgentKeys.p_agent_middle_name">
      <dxo-label [text]="'Middle Name'"></dxo-label>
    </dxi-item>

    <dxi-item itemType="group" [colCountByScreen]="nameItemColCountByScreen">
      <dxi-item [dataField]="AgentKeys.p_agent_last_name" [colSpan]="2">
        <dxo-label [text]="'Last Name'"></dxo-label>
      </dxi-item>

      <dxi-item [dataField]="AgentKeys.p_suffix">
        <dxo-label [text]="'Suffix'"></dxo-label>
        <div *dxTemplate>
          <ag-shr-suffix-select-box
            [value]="agentFormData?.p_suffix"
            (valueChange)="agentFormData.p_suffix = $event"
            (selectedItemChange)="handleSuffixSelect($event)"
            [showDropDownButton]="false"
          ></ag-shr-suffix-select-box>
        </div>
      </dxi-item>
    </dxi-item>
  </dxi-item>

  <dxi-item itemType="group" [colCountByScreen]="tripleColCountByScreen">
    <dxi-item>
      <div *dxTemplate>
        <dx-text-box
          label="MGA"
          labelMode="static"
          [value]="agentFormData?.p_mga_id | agency | async"
          [readOnly]="true"
        ></dx-text-box>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-text-box
          label="Agency"
          labelMode="static"
          [value]="agentFormData?.p_agency_id | agency | async"
          [readOnly]="true"
        ></dx-text-box>
      </div>
    </dxi-item>

    <dxi-item [dataField]="AgentKeys.title">
      <dxo-label [text]="'Title'"></dxo-label>
    </dxi-item>
  </dxi-item>

  <!-- <dxi-item itemType="group" [colCountByScreen]="dualColCountByScreen">
    <dxi-item
      [dataField]="AgentKeys.p_email"
      [editorOptions]="{
        readOnly: true,
      }"
    >
      <dxo-label [text]="'Primary Email Address'"></dxo-label>
    </dxi-item>

    <dxi-item
      editorType="dxSelectBox"
      [dataField]="AgentHeaderKeys.primaryPhoneNumber"
      [editorOptions]="{
        items: agentFormData?.phone_numbers,
        displayExpr: phoneNumberDisplayExpr,
        searchEnabled: true,
        placeholder: '',
      }"
    >
      <dxo-label [text]="'Primary Phone Number'"></dxo-label>
    </dxi-item>
  </dxi-item> -->

  <!-- <dxi-item itemType="group" [colCountByScreen]="dualColCountByScreen">
    <dxi-item
      editorType="dxSelectBox"
      [dataField]="AgentHeaderKeys.primaryBillingAddress"
      [editorOptions]="{
        items: agentFormData?.addresses,
        displayExpr: fullAddressPipe.transform,
        searchEnabled: true,
        placeholder: '',
      }"
    >
      <dxo-label [text]="'Primary Billing Address'"></dxo-label>
    </dxi-item>

    <dxi-item
      editorType="dxSelectBox"
      [dataField]="AgentHeaderKeys.primaryShippingAddress"
      [editorOptions]="{
        items: agentFormData?.addresses,
        displayExpr: fullAddressPipe.transform,
        searchEnabled: true,
        placeholder: '',
      }"
    >
      <dxo-label [text]="'Primary Shipping Address'"></dxo-label>
    </dxi-item>
  </dxi-item> -->
</dx-form>
