import { ResponsiveOptions } from './template-builder-responsive-options';
import { TemplateEditorItems } from './template-builder-item.model';
import { TemplateBuilderContainerProperties } from './template-builder-container-properties';

export class TemplateBuilderModule {
  type: TemplateEditorItems.module = TemplateEditorItems.module;
  desktopContainerProperties: TemplateBuilderContainerProperties = new TemplateBuilderContainerProperties();
  mobileContainerProperties?: Partial<TemplateBuilderContainerProperties> = {};
  responsiveOptions: ResponsiveOptions = new ResponsiveOptions();

  constructor(public templateDbId: string) {}
}
