export class Padding {
  paddingTop: number;
  paddingRight: number;
  paddingBottom: number;
  paddingLeft: number;

  constructor(padding = 0) {
    this.paddingTop = padding;
    this.paddingRight = padding;
    this.paddingBottom = padding;
    this.paddingLeft = padding;
  }
}
