import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';
import { ChangeLogAddressGridComponent } from './change-log-address-grid.component';
import { DxiColumnModule } from 'devextreme-angular/ui/nested';

@NgModule({
  declarations: [ChangeLogAddressGridComponent],
  imports: [ CommonModule, SharedModule, ChangeLogPipesModule, DxiColumnModule ],
  exports: [ChangeLogAddressGridComponent],
})
export class ChangeLogAddressGridModule {}
