export enum TemplateEditorTools {
  common = 'common',
  blocks = 'blocks',
  widgets = 'widgets',
}

export const TOOLS_DATA_SOURCE = [
  {
    icon: 'preferences',
    title: 'Common',
    toolType: TemplateEditorTools.common,
  },
  {
    icon: 'tableproperties',
    title: 'Rows',
    toolType: TemplateEditorTools.blocks,
  },
  {
    icon: 'dx-icon dx-icon-agi-widgets',
    title: 'Widgets',
    toolType: TemplateEditorTools.widgets,
  },
];
