<dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
  <dx-form
    [(formData)]="conference"
    [colCount]="2"
    labelMode="floating"
    [readOnly]="isReadonlyMode"
    [validationGroup]="validationGroup"
  >
    <dxi-item [dataField]="ConferenceKeys.eventId" [label]="{ text: titleList[ConferenceKeys.eventId] }"></dxi-item>

    <dxi-item
      [dataField]="ConferenceKeys.eventName"
      [label]="{ text: titleList[ConferenceKeys.eventName] }"
      [editorOptions]="{ valueChangeEvent: 'input' }"
      [isRequired]="true"
    >
      <dxi-validation-rule type="required" [message]="requiredMessage"></dxi-validation-rule>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <dx-date-range-box
          [startDate]="conference[ConferenceKeys.startDate]"
          [endDate]="conference[ConferenceKeys.endDate]"
          (startDateChange)="conference[ConferenceKeys.startDate] = $any($event)"
          (endDateChange)="conference[ConferenceKeys.endDate] = $any($event)"
          [startDateLabel]="titleList[ConferenceKeys.startDate]"
          [endDateLabel]="titleList[ConferenceKeys.endDate]"
          labelMode="floating"
          [displayFormat]="dateFormat"
          [deferRendering]="false"
          [showClearButton]="true"
          [openOnFieldClick]="true"
          [useMaskBehavior]="true"
          [dateSerializationFormat]="LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT"
          [elementAttr]="{ id: ConferenceKeys.startDate }"
          [disabled]="isReadonlyMode"
        >
          <dx-validator [validationGroup]="validationGroup">
            <dxi-validation-rule type="required" [message]="requiredMessage"> </dxi-validation-rule>
          </dx-validator>
        </dx-date-range-box>
      </div>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <dx-date-range-box
          [startDate]="conference[ConferenceKeys.registrationStartDate]"
          [endDate]="conference[ConferenceKeys.registrationEndDate]"
          (startDateChange)="conference[ConferenceKeys.registrationStartDate] = $any($event)"
          (endDateChange)="conference[ConferenceKeys.registrationEndDate] = $any($event)"
          [startDateLabel]="titleList[ConferenceKeys.registrationStartDate]"
          [endDateLabel]="titleList[ConferenceKeys.registrationEndDate]"
          labelMode="floating"
          [displayFormat]="dateFormat"
          [deferRendering]="false"
          [showClearButton]="true"
          [openOnFieldClick]="true"
          [readOnly]="!conference[ConferenceKeys.startDate]"
          [useMaskBehavior]="true"
          [dateSerializationFormat]="LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT"
          [elementAttr]="{ id: ConferenceKeys.registrationStartDate }"
          [disabled]="isReadonlyMode"
        >
          <dx-validator [validationGroup]="validationGroup">
            <dxi-validation-rule type="required" [message]="requiredMessage"> </dxi-validation-rule>
          </dx-validator>
        </dx-date-range-box>
      </div>
    </dxi-item>

    <dxi-item
      [dataField]="ConferenceKeys.facilityName"
      [label]="{ text: titleList[ConferenceKeys.facilityName] }"
    ></dxi-item>

    <dxi-item
      [dataField]="ConferenceKeys.detailsPage"
      [label]="{ text: titleList[ConferenceKeys.detailsPage] }"
      editorType="dxSelectBox"
      [editorOptions]="{
        dataSource: groupedPageList$ | async,
        valueExpr: 'route',
        displayExpr: 'name',
        placeholder: '',
        showClearButton: true,
        searchEnabled: true,
        grouped: true,
      }"
    >
    </dxi-item>

    <dxi-item itemType="group" caption="Event Coordinator" [colSpan]="2" [colCount]="2">
      <dxi-item
        [dataField]="ConferenceKeys.eventCoordinatorFirstName"
        [label]="{ text: titleList[ConferenceKeys.eventCoordinatorFirstName] }"
      ></dxi-item>

      <dxi-item
        [dataField]="ConferenceKeys.eventCoordinatorLastName"
        [label]="{ text: titleList[ConferenceKeys.eventCoordinatorLastName] }"
      ></dxi-item>

      <dxi-item
        [dataField]="ConferenceKeys.eventCoordinatorPhone"
        [editorOptions]="phoneNumberOptions"
        [label]="{ text: titleList[ConferenceKeys.eventCoordinatorPhone] }"
      >
        <dxi-validation-rule
          type="stringLength"
          [min]="totalPhoneDigits"
          [max]="totalPhoneDigits"
          [ignoreEmptyValue]="true"
          [trim]="true"
          [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
        >
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item
        [dataField]="ConferenceKeys.eventCoordinatorEmail"
        [label]="{ text: titleList[ConferenceKeys.eventCoordinatorEmail] }"
      >
        <dxi-validation-rule type="email" [message]="Messages.INVALID_EMAIL_FORMAT_TEXT"></dxi-validation-rule>
      </dxi-item>
    </dxi-item>
  </dx-form>
</dx-scroll-view>
