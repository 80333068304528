<ng-container *ngIf="!!log; else emptyLog">
  <ng-container *ngFor="let key of addressKeys">
    <ng-container *ngIf="hasLog(key, log)">
      @switch (key) {
        @case (AddressModelKeys.geoPoint) {
          <p><u>Geopoint</u> was changed</p>
          <ng-container *ngFor="let geoPointKey of geoPointKeys">
            <div
              class="ps-3"
              *ngIf="hasLog(geoPointKey, log[key])"
              [innerHTML]="normalizeLog(geoPointKey, log[key]) | changeLogsDiffHTML: geoPointLookup : null"
            ></div>
          </ng-container>
        }
        @default {
          <div
            [innerHTML]="normalizeLog(key, log) | changeLogsDiffHTML: addressLookup : (getLookup(key) | async)"
          ></div>
        }
      }
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #emptyLog><span>&mdash;</span></ng-template>
