import { Pipe, PipeTransform } from '@angular/core';
import { VimeoService } from './vimeo.service';

@Pipe({
  name: 'vimeoByUri',
})
export class VimeoByUriPipe implements PipeTransform {
  constructor(private vimeoService: VimeoService) {}

  transform(uri: string) {
    return this.vimeoService.getVideoById(uri).then(data => {
      return data;
    });
  }
}
