import { Component, HostBinding, Input } from '@angular/core';
import {
    Agent,
    AgentKeys, ChangeSourceType,
    CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP,
    ConferenceKeys,
    ConferenceRegistrationCommonTaskStatus,
    Entity,
    EntityPermissionActivityKeys,
    Role,
    SelectedExcursions,
} from 'ag-common-lib/public-api';
import { ExcursionInfoTableData } from 'ag-common-svc/lib/components/excursions-info-table/excursions-info-table.models';
import { map, Observable } from 'rxjs';
import { AttendeeExcursionsService } from './attendee-excursions.service';
import {
  AssignedOwnerDetails,
  AssignedOwnerDetailsKeys,
} from 'ag-common-svc/lib/components/ag-assign-owner-viewer/config/ag-assign-owner.config';
import { LoadOptions } from 'devextreme/data';
import { ValueChangedEvent } from 'devextreme/ui/select_box';

@Component({
  selector: 'ag-crm-attendee-excursions',
  templateUrl: './attendee-excursions.component.html',
  styleUrls: ['./attendee-excursions.component.scss'],
  providers: [AttendeeExcursionsService],
})
export class AttendeeExcursionsComponent {
  @HostBinding('class') className = 'attendee-excursions';
  @Input() selectedExcursions: SelectedExcursions;

  selectedExcursionsDataSource$: Observable<ExcursionInfoTableData[]> =
    this.attendeeExcursionsService.selectedExcursionsDataSource$;
  excursionsBudget$ = this.attendeeExcursionsService.conference$.pipe(
    map(conference => conference?.[ConferenceKeys.excursionsBudget]),
  );
  excursions$ = this.attendeeExcursionsService.conference$.pipe(
    map(conference => conference?.[ConferenceKeys.excursions]),
  );
  protected excursionStatisticsMap$ = this.attendeeExcursionsService.excursionStatisticsMap$;
  protected excursionsTaskStatus$ = this.attendeeExcursionsService.excursionsTaskStatus$;
  protected assignOwnerList$ = this.attendeeExcursionsService.assignOwnerList$;
  protected readonly taskStatus = CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly sourceType = ChangeSourceType.regularArm;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly agentsLoadOptions: LoadOptions<Agent> = {
    filter: [AgentKeys.role, '=', Role.CONFERENCE_MANAGER],
  };

  constructor(private attendeeExcursionsService: AttendeeExcursionsService) {}

  protected onOwnerChange = (data: AssignedOwnerDetails) => {
    return this.attendeeExcursionsService.updateExcursionsTask({
      assignedTo: data?.[AssignedOwnerDetailsKeys.assignedTo] ?? null,
      assignedPersonNote: data?.[AssignedOwnerDetailsKeys.assignedToNote] ?? null,
    });
  };

  protected onTaskStatusChanged = (flyTaskStatus: ConferenceRegistrationCommonTaskStatus, e: ValueChangedEvent) => {
    if (flyTaskStatus === e?.value) {
      return;
    }

    this.attendeeExcursionsService.updateExcursionsTask({
      status: e?.value ?? null,
    });
  };
}
