import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { FLIGHT_INFO_KEYS_CONFIG, FlightInfoKeys, LookupBase, LookupKeys } from '@ag-common-lib/public-api';
import { FlightPreferenceKeys } from '@ag-common-lib/lib/models/utils/flight-preference.model';

@Component({
  selector: 'ag-shr-flight-info-change-log',
  templateUrl: './attendee-flight-info-log-details.component.html',
  styleUrls: ['./attendee-flight-info-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendeeFlightInfoLogDetailsComponent {
  @Input() changeLogs: Delta;
  @Input() attendeeName: string;
  @Input() agentsLookup: LookupBase[];
  @Input() caption = 'Flight Information';

  protected readonly flightInfoKeysConfig = [...FLIGHT_INFO_KEYS_CONFIG, {
    [LookupKeys.value]: FlightPreferenceKeys.airlinePreferences,
    [LookupKeys.description]: 'Airline Preferences',
  },];
  protected readonly FlightInfoKeys = FlightInfoKeys;
  protected readonly FlightPreferenceKeys = FlightPreferenceKeys;
}
