import { Injectable } from '@angular/core';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import {
  AGMedia,
  AGMediaKeys,
  Conference,
  ConferenceKeys,
  EmailTemplateConfiguration,
  EmailTemplateConfigurationKeys,
  EmailTemplatesDetailsMap,
  MediaContentDisposition,
  MediaSize,
} from 'ag-common-lib/public-api';
import { BaseFormService, ConferenceService } from 'ag-common-svc/public-api';
import { RowRemovingEvent } from 'devextreme/ui/data_grid';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class EmailTemplateConfigurationFormService extends BaseFormService<EmailTemplateConfiguration> {
  private _conference: Conference;

  private _editedEmailConfiguration: EmailTemplateConfiguration;

  constructor(private conferenceService: ConferenceService, private toastrService: ToastrService,) {
    super();
  }

  getFormData = (
    conference: Conference,
    emailTemplateConfiguration?: EmailTemplateConfiguration,
  ): EmailTemplateConfiguration => {
    this._conference = conference;
    this._editedEmailConfiguration = emailTemplateConfiguration;

    const initialData = Object.assign({}, new EmailTemplateConfiguration(), emailTemplateConfiguration);

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        switch (prop) {
          case EmailTemplateConfigurationKeys.templateName:
            const eventName = conference?.[ConferenceKeys.eventName];
            const templateData = EmailTemplatesDetailsMap.get(value);
            const subject = [eventName, templateData?.title].filter(Boolean).join(' - ');

            Reflect.set(target, EmailTemplateConfigurationKeys.subject, subject, receiver);

            break;

          default:
            break;
        }

        return true;
      },
    });

    return this.formData;
  };

  handleSaveEmailTemplateConfiguration = () => {
    this.startProgress();
    const templateConfiguration: EmailTemplateConfiguration = Object.assign({}, this.formData);
    const trigger = templateConfiguration?.trigger;
    const attachmentsFileSizeSumInBytes = templateConfiguration.attachments
      .map(({ fileSize }) => fileSize)
      .reduce((a, b) => a + b, 0);
    const allowedSizeMb = 20;
    const maxAllowedSize = allowedSizeMb * 1024 * 1024; // MB => bytes
    if (attachmentsFileSizeSumInBytes > maxAllowedSize) {
      this.toastrService.error('Total attachments size is more than 20Mb');
      this.stopProgress();
      return true;
    }

    const templateConfigurationAttachments = templateConfiguration?.[EmailTemplateConfigurationKeys.attachments];
    templateConfigurationAttachments?.map(attachment => {
      const contentType = attachment?.[AGMediaKeys.contentType];
      const wasabiPath = attachment?.[AGMediaKeys.wasabiPath];
      const mediaSize = MediaSize.origin;
      const hasMediaSize = wasabiPath.split('/').includes(mediaSize);
      return contentType.startsWith('image') && !hasMediaSize
          ? Object.assign(attachment, {
            [AGMediaKeys.wasabiPath]: `${wasabiPath}/${mediaSize}`,
          })
          : attachment;
    });

    const templateConfigurations = [];

    this._conference[ConferenceKeys.emailTemplates]?.forEach(item => {
      const itemTrigger = item?.trigger;
      const template = Object.assign({}, item);

      if (this._editedEmailConfiguration === item) {
        Object.assign(template, this.formData);
        templateConfigurations.push(template);
        return;
      }

      if (itemTrigger === trigger) {
        template.trigger = null;
      }
      templateConfigurations.push(template);
    });

    if (!this._editedEmailConfiguration) {
      templateConfigurations.push(Object.assign({}, templateConfiguration));
    }

    return this.saveChanges(templateConfigurations)
      .then(() => false)
      .finally(() => {
        this.stopProgress();
      })
      .catch(() => true);
  };

  onRowRemoving = (conference: Conference, e: RowRemovingEvent) => {
    this._conference = conference;
    const templateConfigurations = [];

    conference[ConferenceKeys.emailTemplates]?.forEach(range => {
      if (e.key !== range) {
        const template = Object.assign({}, range);
        templateConfigurations.push(template);
      }
    });

    e.cancel = this.saveChanges(templateConfigurations)
      .then(() => {
        return false;
      })
      .catch(() => {
        return true;
      });
  };

  addAttachment = (media: AGMedia) => {
    const templateAttachments = this.formData?.[EmailTemplateConfigurationKeys.attachments] ?? [];
    const attachment = [...templateAttachments, media];

    this.formData[EmailTemplateConfigurationKeys.attachments] = attachment;
  };

  private saveChanges = async (templateConfigurations: EmailTemplateConfiguration[]) => {
    const conferenceDbId = this._conference?.[BaseModelKeys.dbId];

    if (!conferenceDbId) {
      Object.assign(this._conference, { [ConferenceKeys.emailTemplates]: templateConfigurations });
      return false;
    }

    return this.conferenceService
      .updateFields(conferenceDbId, { [ConferenceKeys.emailTemplates]: templateConfigurations })
      .then(() => {
        Object.assign(this._conference, { [ConferenceKeys.emailTemplates]: templateConfigurations });
      });
  };
}
