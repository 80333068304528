import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { AGMedia, AGMediaKeys, Headshot, IAspectRatio, MediaSize } from '@ag-common-lib/public-api';
import { AgMediaUploaderModalComponent } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader-modal.component';
import { MediaUploaderTabs } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader-modal.models';
import { ImageCropperModalComponent } from 'ag-common-svc/lib/components/image-cropper-modal/image-cropper-modal.component';
import { WasabiImgPipe } from 'ag-common-svc/shared/pipes/wasabi-img.pipe';

@Component({
  selector: 'ag-shr-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
  providers: [WasabiImgPipe],
})
export class ProfilePictureComponent {
  @HostBinding('class') className = 'profile-picture';
  @ViewChild(AgMediaUploaderModalComponent, { static: false }) mediaUploaderComponent: AgMediaUploaderModalComponent; // ViewChild for DxTooltipComponent
  @ViewChild(ImageCropperModalComponent, { static: false }) imageCropperModalComponent: ImageCropperModalComponent; // ViewChild for DxTooltipComponent

  @Input() mediaSources: MediaUploaderTabs[];
  @Input() isRound: boolean = true;
  @Input() maintainAspectRatio: boolean = true;
  @Input() ratio: IAspectRatio = { w: 3, h: 4 };
  @Input() isReadOnly: boolean = false;
  @Input() canEdit: boolean = false;
  @Input() canAdd: boolean = false;
  @Input() showDropDownMenu: boolean = true;
  @Input() headshot: Headshot;
  @Input() mediaPathPrefix: string;
  @Input() mediaItems: AGMedia[];
  @Input() showImageCropperAfterSave = true;
  @Input() onProfileImageChanged: (mediaUrl: AGMedia) => Promise<any>;
  @Input() onImageCropped: (payload: any) => Promise<void>;

  readonly MediaSize = MediaSize;

  constructor(private wasabiImgPipe: WasabiImgPipe) {}

  protected onUploadClicked = (): void => {
    this.mediaUploaderComponent.showModal();
  };

  protected onMediaChanged = async (mediaUrl: AGMedia) => {
    await this.onProfileImageChanged(mediaUrl);

    if (this.showImageCropperAfterSave) {
      this.imageCropperModalComponent.showModal(this.wasabiImgPipe.transform(mediaUrl[AGMediaKeys.wasabiPath]));
    }
  };

  protected handleCropImage = async () => {
    this.imageCropperModalComponent.showModal(
      this.wasabiImgPipe.transform(this?.headshot?.wasabiPath),
      this?.headshot?.imageCropperPayload,
    );
  };
}
