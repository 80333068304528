import { LookupKeys } from '@ag-common-lib/public-api';

export enum BackgroundImageContainer {
  content = 'content',
  fullWidth = 'fullWidth',
}

export const BackgroundImageContainerMap = new Map([
  [BackgroundImageContainer.content, 'Content'],
  [BackgroundImageContainer.fullWidth, 'Full Width'],
]);

export const BACKGROUND_IMAGE_CONTAINER_LOOKUP = [
  BackgroundImageContainer.content,
  BackgroundImageContainer.fullWidth,
].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: BackgroundImageContainerMap.get(value) };
});

export enum BackgroundPosition {
  center = 'center',
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
  leftTop = 'left top',
  rightTop = 'right top',
  leftBottom = 'left bottom',
  rightBottom = 'right bottom',
}

export const BackgroundPositionMap = new Map([
  [BackgroundPosition.center, 'Center'],
  [BackgroundPosition.top, 'Top'],
  [BackgroundPosition.right, 'Right'],
  [BackgroundPosition.bottom, 'Bottom'],
  [BackgroundPosition.left, 'Left'],
  [BackgroundPosition.leftTop, 'Left Top'],
  [BackgroundPosition.rightTop, 'Right Top'],
  [BackgroundPosition.leftBottom, 'Left Bottom'],
  [BackgroundPosition.rightBottom, 'Right Bottom'],
]);

export const BACKGROUND_POSITION_LOOKUP = [
  BackgroundPosition.center,
  BackgroundPosition.top,
  BackgroundPosition.right,
  BackgroundPosition.bottom,
  BackgroundPosition.left,
  BackgroundPosition.leftTop,
  BackgroundPosition.rightTop,
  BackgroundPosition.leftBottom,
  BackgroundPosition.rightBottom,
].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: BackgroundPositionMap.get(value) };
});

export enum BackgroundSize {
  contain = 'contain',
  cover = 'cover',
  percentage = 'percentage',
}

export const BackgroundSizeMap = new Map([
  [BackgroundSize.contain, 'Full Image'],
  [BackgroundSize.cover, 'Stretch if needed'],
  [BackgroundSize.percentage, 'Percentage'],
]);

export const BACKGROUND_SIZE_LOOKUP = [BackgroundSize.contain, BackgroundSize.cover, BackgroundSize.percentage].map(
  value => {
    return { [LookupKeys.value]: value, [LookupKeys.description]: BackgroundSizeMap.get(value) };
  },
);

export enum BackgroundRepeat {
  noRepeat = 'no-repeat',
  repeat = 'repeat',
  repeatX = 'repeat-x',
  repeatY = 'repeat-y',
}

export const BackgroundRepeatMap = new Map([
  [BackgroundRepeat.noRepeat, 'No Repeat'],
  [BackgroundRepeat.repeat, 'Repeat'],
  [BackgroundRepeat.repeatX, 'Repeat Horizontal'],
  [BackgroundRepeat.repeatY, 'Repeat Vertical'],
]);

export const BACKGROUND_REPEAT_LOOKUP = [
  BackgroundRepeat.noRepeat,
  BackgroundRepeat.repeat,
  BackgroundRepeat.repeatX,
  BackgroundRepeat.repeatY,
].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: BackgroundRepeatMap.get(value) };
});

export class BackgroundImageOptions {
  backgroundPosition: BackgroundPosition = BackgroundPosition.center;
  backgroundRepeat: BackgroundRepeat = BackgroundRepeat.noRepeat;
  backgroundSize: BackgroundSize = BackgroundSize.cover;
  backgroundSizeXPresent: number = 100;
  backgroundSizeXAuto: boolean = false;
  backgroundSizeYPresent: number = 100;
  backgroundSizeYAuto: boolean = false;
}
