<dx-data-grid
  class="invited-grid ag-grid__component"
  #conferenceRegistrationsGrid
  [dataSource]="participationRequests$ | async"
  [showBorders]="true"
  [columnAutoWidth]="false"
  [scrollLeft]="false"
  [allowColumnReordering]="true"
  [allowColumnResizing]="false"
  [noDataText]="emptyMessage"
  [syncLookupFilterValues]="true"
  [wordWrapEnabled]="true"
  width="100%"
  height="100%"
  (onRowRemoving)="onParticipationRequestRemoving($event)"
  (onExporting)="onExporting($event)"
>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-scrolling mode="virtual" [useNative]="true" [showScrollbar]="'always'"></dxo-scrolling>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>

  <dxo-editing mode="row" [allowDeleting]="true" [useIcons]="true"></dxo-editing>

  <dxo-toolbar>
    <dxi-item location="before" name="groupPanel"></dxi-item>

    <dxi-item location="after" name="exportButton" cssClass="ag-grid__toolbar-control"></dxi-item>
    <dxi-item location="after" name="columnChooserButton" cssClass="ag-grid__toolbar-control"></dxi-item>
  </dxo-toolbar>

  <dxi-column [dataField]="EventInquiryRequestKeys.status" [caption]="'Request status'" [groupIndex]="0">
    <dxo-lookup
      [dataSource]="eventInquiryRequestStatusLookup"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="EventInquiryRequestKeys.agentDbId"
    [caption]="'Agent'"
    [cellTemplate]="'agentCellTemplate'"
    cssClass="test"
    [minWidth]="240"
  >
    <dxo-lookup
      [dataSource]="agentsDataSourceConfiguration"
      [valueExpr]="BaseModelKeys.dbId"
      [displayExpr]="AgentKeys.p_agent_name"
    >
    </dxo-lookup>

    <div *dxTemplate="let cell of 'agentCellTemplate'">
      <div
        *var="cell.data?.[EventInquiryRequestKeys.agentDbId] as agentDbId"
        class="participation-requests-grid__agent-cell"
      >
        {{ cell.displayValue ?? cell.data?.[EventInquiryRequestKeys.email] }}

        <div class="participation-requests-grid__agent-cell-icons-container">
          <i
            *ngIf="!agentDbId || agentDbId | isShowAgentNotWelcomedWarning | async"
            #severalAgentsRef
            class="participation-requests-grid__agent-cell-icon participation-requests-grid__agent-cell-icon--error dx-icon dx-icon-warning"
          >
            <dx-tooltip
              [target]="severalAgentsRef"
              showEvent="mouseenter"
              hideEvent="mouseleave"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data = data of 'content'">
                <ng-container *ngIf="agentsMap$ | async as agentsMap">
                  <ng-container *ngIf="!agentDbId || !agentsMap?.[agentDbId]; else agentNotWelcomedTmp">
                    No Agent record assigned to the provided email.
                  </ng-container>

                  <ng-template #agentNotWelcomedTmp>
                    Agent status is {{ agentsMap?.[agentDbId]?.[AgentKeys.agent_status] }}
                  </ng-template>
                </ng-container>
              </div>
            </dx-tooltip>
          </i>

          <ng-container *ngIf="!!cell.data?.[EventInquiryRequestKeys.relatedAgentsDbIds]?.length">
            <i
              *var="cell.data?.[EventInquiryRequestKeys.allRelatedAgentsReviewed] as allRelatedAgentsReviewed"
              #severalAgentsRef
              class="participation-requests-grid__agent-cell-icon dx-icon"
              [ngClass]="
                allRelatedAgentsReviewed
                  ? 'dx-icon-agi-circle-check'
                  : 'dx-icon-warning participation-requests-grid__agent-cell-icon--warning'
              "
              [title]="
                !allRelatedAgentsReviewed
                  ? ''
                  : 'Tied Agents records are reviewed by ' +
                    (cell.data?.[EventInquiryRequestKeys.reviewedByAgentDbId] | agentNameByDbId | async) +
                    ' on ' +
                    (cell.data?.[EventInquiryRequestKeys.reviewedDate] | date: dateFormat)
              "
            >
              <dx-popover
                [target]="severalAgentsRef"
                showEvent="click"
                [width]="'80%'"
                [height]="'30%'"
                [shading]="true"
                [showTitle]="true"
                [title]="'Several Agents records tied to the provided email.'"
                titleTemplate="titleTemplate"
                shadingColor="rgba(0, 0, 0, 0.5)"
              >
                <div class="participation-requests-popover__title row" *dxTemplate="let data of 'titleTemplate'">
                  <h4 class="col px-2">Several Agents records tied to the provided email.</h4>
                  <div class="col-auto participation-requests-popover__title-control">
                    <ng-container
                      *ngIf="!cell.data?.[EventInquiryRequestKeys.allRelatedAgentsReviewed]; else reviewedTemp"
                    >
                      Mark reviewed:
                      <i class="dx-icon dx-icon-agi-circle-check" (click)="markAllReviewed(cell.data)"></i>
                    </ng-container>
                    <ng-template #reviewedTemp>
                      Reviewed by
                      {{ cell.data?.[EventInquiryRequestKeys.reviewedByAgentDbId] | agentNameByDbId | async }} on
                      {{ cell.data?.[EventInquiryRequestKeys.reviewedDate] | date: dateFormat }}
                    </ng-template>
                  </div>
                </div>

                <div *dxTemplate="let data = data of 'content'">
                  <dx-data-grid
                    [dataSource]="cell.data?.[EventInquiryRequestKeys.relatedAgentsDbIds] | relatedAgentsDatasource"
                    [showBorders]="true"
                    [rowAlternationEnabled]="true"
                    (onRowRemoving)="onRelatedAgentRemoving(cell.data, $event)"
                    (onRowDblClick)="showAgentDetails($event)"
                  >
                    <dxo-editing
                      mode="row"
                      [allowDeleting]="!cell.data?.[EventInquiryRequestKeys.allRelatedAgentsReviewed]"
                      [useIcons]="true"
                    ></dxo-editing>

                    <dxi-column
                      [dataField]="AgentKeys.email_addresses"
                      dataType="string"
                      [caption]="'Email Addresses'"
                      [minWidth]="250"
                      cellTemplate="emailAddressesCellTemplate"
                    >
                      <div *dxTemplate="let cell of 'emailAddressesCellTemplate'">
                        <ng-container *ngFor="let item of cell.value">
                          <p class="agent-grid__grid-row">
                            <span [innerHTML]="item.address"></span>

                            <i *ngIf="item?.is_login" class="dx-icon-agi-key agent-grid__grid-icon"></i>
                          </p>
                        </ng-container>
                      </div>
                    </dxi-column>

                    <dxi-column [dataField]="AgentKeys.p_agent_first_name" [caption]="'First Name'" [minWidth]="150">
                    </dxi-column>

                    <dxi-column [dataField]="AgentKeys.p_agent_last_name" [caption]="'Last Name'" [minWidth]="150">
                    </dxi-column>

                    <dxi-column
                      [dataField]="AgentKeys.phone_numbers"
                      dataType="string"
                      [caption]="'Phone Numbers'"
                      [width]="150"
                      cellTemplate="phoneNumbersCellTemplate"
                    >
                      <div *dxTemplate="let cell of 'phoneNumbersCellTemplate'">
                        <ng-container *ngFor="let item of cell.value">
                          <p class="agent-grid__grid-row" *ngIf="item | phoneNumberMask as phoneNumber">
                            <span [innerHTML]="phoneNumber"></span>

                            <i *ngIf="item?.is_primary" class="dx-icon-agi-key agent-grid__grid-icon"></i>
                          </p>
                        </ng-container>
                      </div>
                    </dxi-column>
                  </dx-data-grid>
                </div>
              </dx-popover>
            </i>
          </ng-container>
        </div>
      </div>
    </div>
  </dxi-column>

  <dxi-column [dataField]="EventInquiryRequestKeys.email" [caption]="'Registration Email'" [width]="220"></dxi-column>

  <dxi-column [dataField]="EventInquiryRequestKeys.mgaOrUpline" [caption]="'MGA / Upline'"></dxi-column>

  <dxi-column
    [dataField]="EventInquiryRequestKeys.phoneNumber"
    [caption]="'Phone'"
    [calculateDisplayValue]="calculatePhoneNumberDisplayValue"
    [width]="140"
  >
  </dxi-column>
  <dxi-column [dataField]="EventInquiryRequestKeys.firstName" [caption]="'First Name'"></dxi-column>
  <dxi-column [dataField]="EventInquiryRequestKeys.lastName" [caption]="'Last Name'"></dxi-column>

  <dxi-column [dataField]="EventInquiryRequestKeys.cameFrom" [caption]="'Learned From'"></dxi-column>
  <dxi-column [dataField]="EventInquiryRequestKeys.eventGoals" [caption]="'Event Goal'" width="240"></dxi-column>

  <dxi-column type="buttons">
    <dxi-button
      name="approveApplicationPortalUser"
      icon="agi-circle-check"
      hint="Approve Participation"
      [visible]="
        (Entity.conferenceRegistrants | hasPermission: EntityPermissionActivityKeys.create | async) &&
        (agentsMap$ | async | isAllowToApproveRequest)
      "
      [onClick]="approveParticipation"
    ></dxi-button>

    <dxi-button
      name="reject"
      icon="agi-ban"
      hint="Reject Participation"
      [visible]="
        (Entity.conferenceRegistrants | hasPermission: EntityPermissionActivityKeys.write | async) &&
        canRejectParticipation
      "
      [onClick]="rejectParticipation"
    ></dxi-button>

    <dxi-button
      name="noContact"
      icon="agi-circle-question"
      hint="Request for Info"
      [visible]="
        (Entity.conferenceRegistrants | hasPermission: EntityPermissionActivityKeys.write | async) && canRequestMoreInfo
      "
      [onClick]="requestMoreInfo"
    ></dxi-button>

    <dxi-button
      name="changeAgent"
      icon="agi-user-gear"
      hint="Change related Agent"
      [visible]="
        (Entity.conferenceRegistrants | hasPermission: EntityPermissionActivityKeys.write | async) && canAssignAgent
      "
      [onClick]="changeAgent"
    ></dxi-button>

    <dxi-button
      name="delete"
      hint="Delete Application"
      [visible]="Entity.conferenceRegistrants | hasPermission: EntityPermissionActivityKeys.delete | async"
    ></dxi-button>
  </dxi-column>
</dx-data-grid>

<ag-shr-modal-window
  #assignAgentModalRef
  [width]="450"
  [height]="'auto'"
  title="Assign Agent to request"
  [inProgress]="assignmentInProgress"
  (onSaveClick)="handleAssignAgentClick()"
>
  <dx-form #assignAgentFormRef class="ag-assign-owner__form" [(formData)]="inquiryRequestFormData" labelMode="floating">
    <dxi-item
      [dataField]="EventInquiryRequestKeys.agentDbId"
      [template]="'assignedToTemplate'"
      [isRequired]="true"
    ></dxi-item>
    <div *dxTemplate="let data of 'assignedToTemplate'">
      <dx-select-box
        label="Assigned Agent"
        [(value)]="inquiryRequestFormData.agentDbId"
        [dataSource]="agentsDataSourceConfiguration"
        [valueExpr]="'dbId'"
        [displayExpr]="AgentKeys.p_agent_name"
        [searchEnabled]="true"
        [searchExpr]="[AgentKeys.p_agent_first_name, AgentKeys.p_agent_last_name, AgentKeys.p_email]"
        [showClearButton]="true"
      >
        <div *dxTemplate="let data of 'item'">
          {{ data.p_agent_name }}
          <ng-container *ngIf="data?.[AgentKeys.email_addresses] | AgentPrimaryEmailAddress as email">
            (<i>{{ email | empty }}</i
            >)
          </ng-container>
        </div>
      </dx-select-box>

      <dx-data-grid
        *ngIf="!!inquiryRequestFormData?.[EventInquiryRequestKeys.relatedAgentsDbIds]?.length"
        [dataSource]="inquiryRequestFormData?.[EventInquiryRequestKeys.relatedAgentsDbIds]"
        [showBorders]="true"
        [wordWrapEnabled]="true"
        [showColumnHeaders]="false"
        [showRowLines]="true"
        [rowAlternationEnabled]="true"
        width="100%"
        class="mt-4"
      >
        <dxo-toolbar>
          <dxi-item location="before">
            <div *dxTemplate>Agents tied to provided email and phone number.</div>
          </dxi-item>
        </dxo-toolbar>

        <dxi-column
          [name]="'agent'"
          [calculateCellValue]="calculateRelatedAgentCellValue"
          [caption]="'Agent'"
          [cellTemplate]="'agentCellTemplate'"
        >
          <dxo-lookup [dataSource]="agentsDataSourceConfiguration" [valueExpr]="BaseModelKeys.dbId"> </dxo-lookup>

          <div *dxTemplate="let cell of 'agentCellTemplate'">
            <div class="participation-requests-grid__agent-cell">
              {{ cell?.displayValue?.[AgentKeys.p_agent_name] }}
            </div>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
  </dx-form>
</ag-shr-modal-window>

<ag-shr-modal-window
  #addAttendeeModalRef
  [width]="450"
  [height]="'auto'"
  title="Add Agent to event"
  [actionTitle]="'ADD'"
  [inProgress]="assignmentInProgress || (isAddingInProgress$ | async)"
  (onSaveClick)="handleAddAgentToEvent()"
>
  <dx-form #addAttendeeFormRef class="ag-assign-owner__form" [(formData)]="attendeeFormData" labelMode="floating">
    <dxi-item
      [dataField]="BaseModelKeys.dbId"
      editorType="dxSelectBox"
      [label]="{ text: 'Agent' }"
      [editorOptions]="{
        dataSource: agentsDataSourceConfiguration,
        valueExpr: BaseModelKeys.dbId,
        displayExpr: AgentKeys.p_agent_name,
        searchEnabled: true,
        placeholder: '',
        readOnly: true,
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="RegistrantKeys.registrationType"
      editorType="dxSelectBox"
      [label]="{ text: 'Registration Type' }"
      [editorOptions]="{
        items: registrationTypeLookup,
        valueExpr: LookupKeys.value,
        displayExpr: LookupKeys.description,
        searchEnabled: true,
        placeholder: '',
      }"
    >
    </dxi-item>

    <dxi-item
      [dataField]="RegistrantKeys.qualifiedAs"
      editorType="dxSelectBox"
      [label]="{ text: 'Qualified As' }"
      [editorOptions]="{
        items: qualifiedAsLookup,
        valueExpr: LookupKeys.value,
        displayExpr: LookupKeys.description,
        searchEnabled: true,
        placeholder: '',
      }"
    >
    </dxi-item>
  </dx-form>
</ag-shr-modal-window>
