import * as uuid from 'uuid';
import { LookupKeys } from '../crm/lookup.model';
import { LookupBase } from '../utils/lookup-base.model';

export enum ExcursionType {
  generic = 'generic',
  spa = 'spa',
  golf = 'golf',
  genTime = 'genTime',
}

export const excursionTypeLookup = [
  { [LookupKeys.value]: ExcursionType.generic, [LookupKeys.description]: 'Generic' },
  { [LookupKeys.value]: ExcursionType.spa, [LookupKeys.description]: 'Spa' },
  { [LookupKeys.value]: ExcursionType.golf, [LookupKeys.description]: 'Golf' },
  { [LookupKeys.value]: ExcursionType.genTime, [LookupKeys.description]: 'Gen Time' },
];

export enum ExcursionTimeOfDay {
  morning = 'morning',
  afternoon = 'afternoon',
}

export const excursionTimeOfDayLookup = [
  { [LookupKeys.value]: ExcursionTimeOfDay.morning, [LookupKeys.description]: 'Morning' },
  { [LookupKeys.value]: ExcursionTimeOfDay.afternoon, [LookupKeys.description]: 'Afternoon' },
];

export enum GolfRightLeft {
  right = 'right',
  left = 'left',
  noClubs = 'noClubs',
}

export enum Gender {
  male = 'male',
  female = 'female',
  noPreference = 'noPreference',
}

export const RIGHT_LEFT_LOOKUP: LookupBase[] = [
  { [LookupKeys.value]: GolfRightLeft.right, [LookupKeys.description]: 'Right Handed Clubs' },
  { [LookupKeys.value]: GolfRightLeft.left, [LookupKeys.description]: 'Left Handed Clubs' },
  { [LookupKeys.value]: GolfRightLeft.noClubs, [LookupKeys.description]: 'No Clubs' },
];

export const GENDER_LOOKUP: LookupBase[] = [
  { [LookupKeys.value]: Gender.male, [LookupKeys.description]: 'Male' },
  { [LookupKeys.value]: Gender.female, [LookupKeys.description]: 'Female' },
  { [LookupKeys.value]: Gender.noPreference, [LookupKeys.description]: 'No Preference' },
];

export const AT_LEISURE_EXCURSION_SUFFIX = '__AT_LEISURE' as const;
export const AT_LEISURE_ITEM_TEXT = 'At Leisure' as const;

export enum ExcursionKeys {
  id = 'id',
  name = 'name',
  type = 'type',
  cost = 'cost',
  description = 'description',
  shortDescription = 'shortDescription',
  date = 'date',
  isExactTime = 'isExactTime',
  departureTime = 'departureTime',
  returnTime = 'returnTime',
  minAge = 'minAge',
  restrictions = 'restrictions',

  //
  golfNeedsClubs = 'golfNeedsClubs',
  genGenderPref = 'genGenderPref',
  spaGenderPref = 'spaGenderPref',
  spaTimePref = 'spaTimePref',
  capacity = 'capacity',
  stuffCharge = 'stuffCharge',
  hasPreferences = 'hasPreferences',
  preferences = 'preferences',
  configuration = 'configuration',
}

export enum TimePreferenceFormat {
  exact = 'exact',
  timeOfDay = 'timeOfDay',
}

export const timePreferenceFormatLookup = [
  { [LookupKeys.value]: TimePreferenceFormat.exact, [LookupKeys.description]: 'Exact Time' },
  { [LookupKeys.value]: TimePreferenceFormat.timeOfDay, [LookupKeys.description]: 'Time of Day' },
];

export interface ExcursionRestrictions {
  age: number;
  other: string; // Weight, height
}

export enum ExcursionOptionKey {
  name = 'name',
  cost = 'cost',
  description = 'description',
}

export class ExcursionOption {
  [ExcursionOptionKey.name]: string = '';
  [ExcursionOptionKey.cost]?: number = null;
  [ExcursionOptionKey.description]?: string = '';
}

export enum ExcursionPreferenceKeys {
  label = 'label',
  instructions = 'instructions',
  selectionType = 'selectionType',
  items = 'items',
}

export enum ExcursionOptionsType {
  single = 'single',
  multiple = 'multiple',
}

export enum ExcursionDataType {
  default = 'default',
  time = 'time',
  string = 'string',
}

export class ExcursionPreference {
  [ExcursionPreferenceKeys.label]?: string;
  [ExcursionPreferenceKeys.instructions]?: string;
  [ExcursionPreferenceKeys.selectionType]: ExcursionOptionsType = ExcursionOptionsType.single;
  [ExcursionPreferenceKeys.items]?: ExcursionOption[] = [];
}

export enum ExcursionConfigurationKeys {
  label = 'label',
  instructions = 'instructions',
  dataType = 'dataType',
  items = 'items',
}

export const ExcursionDataTypeMap = new Map([
  [ExcursionDataType.time, 'time'],
  [ExcursionDataType.string, 'string'],
]);

export const EXCURSION_DATA_TYPE_LOOKUP = [
  ExcursionDataType.default,
  ExcursionDataType.time,
  ExcursionDataType.string,
].map(value => ({
  [LookupKeys.value]: value,
  [LookupKeys.description]: ExcursionDataTypeMap.get(value),
}));

export class ExcursionConfiguration {
  [ExcursionConfigurationKeys.label]: string;
  [ExcursionConfigurationKeys.instructions]?: string;
  [ExcursionConfigurationKeys.dataType]: ExcursionDataType = ExcursionDataType.string;
}

export class Excursion {
  [ExcursionKeys.id]: string = uuid.v4();
  [ExcursionKeys.name]: string;
  [ExcursionKeys.type]: ExcursionType = ExcursionType.generic;
  [ExcursionKeys.description]: string;
  [ExcursionKeys.cost]?: number;
  [ExcursionKeys.stuffCharge]?: number;
  [ExcursionKeys.date]: string;
  [ExcursionKeys.isExactTime]?: boolean = false;
  [ExcursionKeys.departureTime]?: string;
  [ExcursionKeys.returnTime]?: string;
  [ExcursionKeys.minAge]?: number;
  [ExcursionKeys.restrictions]?: string;
  [ExcursionKeys.hasPreferences]?: boolean = false;
  [ExcursionKeys.preferences]?: ExcursionPreference[] = [];
  [ExcursionKeys.configuration]?: ExcursionConfiguration[] = [];
}

export enum ExcursionGroupKeys {
  groupTitle = 'groupTitle',
  groupId = 'groupId',
  excursions = 'excursions',
  groupDate = 'groupDate',
  validationGroup = 'validationGroup',
  selectedExcursionId = 'selectedExcursionId',
}
export interface ExcursionGroup {
  [ExcursionGroupKeys.groupTitle]: string;
  [ExcursionGroupKeys.groupId]: string;
  [ExcursionGroupKeys.groupDate]?: Date;
  [ExcursionGroupKeys.validationGroup]?: string;
  [ExcursionGroupKeys.excursions]: Partial<Excursion>[];
}

export enum SelectedExcursionsKeys {
  bookingDate = 'bookingDate',
  preferences = 'preferences',
  configurations = 'configurations',
  isPaid = 'isPaid',
  isAdminSelected = 'isAdminSelected',
}

export class SelectedExcursionOptions {
  [key: string]: ExcursionOption;
}

export class SelectedExcursionConfiguration {
  [key: string]: {
    value: string;
    dataType: ExcursionDataType;
  };
}

export class ExcursionPreferences {
  [SelectedExcursionsKeys.bookingDate]?: Date = new Date();
  [SelectedExcursionsKeys.preferences]?: SelectedExcursionOptions = {};
  [SelectedExcursionsKeys.configurations]?: SelectedExcursionConfiguration = null;
  [SelectedExcursionsKeys.isPaid]?: boolean = false;
  [SelectedExcursionsKeys.isAdminSelected]?: boolean = false;
}
export class SelectedExcursions {
  [key: string]: ExcursionPreferences;
}
