<div class="agent-header__container">
  <ng-container *var="agent$ | async as agent">
    <ag-shr-profile-picture
      [headshot]="agent?.head_shot"
      [mediaPathPrefix]="mediaPathPrefix"
      [mediaItems]="agentMediaDataSource$ | async"
      [onProfileImageChanged]="onMediaChanged"
      [onImageCropped]="onImageCropped"
      [canEdit]="Entity.agentEditPicture | hasPermission: EntityPermissionActivityKeys.write | async"
      [canAdd]="Entity.agentEditPicture | hasPermission: EntityPermissionActivityKeys.create | async"
    ></ag-shr-profile-picture>

    <div class="col">
      <div class="row">
        <div class="col-12 agent-header__title" style="font-size: 18px">
          {{
            [
              agent?.p_prefix | prefix | async,
              agent?.p_agent_first_name,
              agent?.p_agent_last_name,
              agent?.p_suffix | suffix | async,
            ] | fullName
          }}
          <dx-button
            *ngIf="Entity.agentInformation | hasPermission: EntityPermissionActivityKeys.write | async"
            class="mr-2"
            icon="edit"
            stylingMode="text"
            (onClick)="showEditorModal()"
          ></dx-button>
        </div>
      </div>
      <div class="row pb-1">
        <div class="col-4">{{ agent?.title ?? "" }}</div>
      </div>
      <div class="row pb-1">
        <div class="col-3">
          <div class="pb-1" style="text-decoration: underline">Primary Email Address</div>
          <div>{{ agent?.p_email }}</div>
        </div>
        <div class="col-3">
          <div class="pb-1" style="text-decoration: underline">MGA</div>
          <div>{{ agent?.p_mga_id | agency | async }}</div>
        </div>
        <div class="col-3">
          <div class="pb-1" style="text-decoration: underline">Primary Shipping Address</div>
          <div>
            {{ agent?.addresses | primaryShippingAddress | fullAddress }}
          </div>
        </div>
      </div>
      <div class="row pb-1">
        <div class="col-3">
          <div class="pb-1" style="text-decoration: underline">Primary Phone Number</div>
          <div>
            {{ agent?.phone_numbers | primaryPhoneNumber | phoneNumberMask }}
          </div>
        </div>
        <div class="col-3">
          <div class="pb-1" style="text-decoration: underline">Agency</div>
          <div>
            {{ agent?.p_agency_id | agency | async }}
          </div>
        </div>
        <div class="col-3">
          <div class="pb-1" style="text-decoration: underline">Primary Billing Address</div>
          <div>
            {{ agent?.addresses | primaryBillingAddress | fullAddress }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ag-shr-modal-window
  [width]="'680px'"
  [height]="'auto'"
  #agentHeaderModalRef
  [title]="'Agent Information'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="saveAgentHeaderInfoUpdates()"
  (onPopupClose)="handleClosePopup($event)"
>
  <ag-shr-agent-profile-data [agentFormData]="agentHeaderFormDetails"></ag-shr-agent-profile-data>
  <!-- <dx-form
    #agentHeaderFormRef
    class="agent-header__form"
    [formData]="agentHeaderFormDetails"
    [labelMode]="'floating'"
    [screenByWidth]="formSize$ | async | formScreenByWidth: { md: 980 }"
    (onInitialized)="onFormInitialized($event)"
  >
    <dxi-item itemType="group" [colCountByScreen]="tripleColCountByScreen">
      <dxi-item itemType="group" [colCountByScreen]="nameItemColCountByScreen">
        <dxi-item [dataField]="AgentKeys.p_prefix">
          <dxo-label [text]="'Prefix'"></dxo-label>
          <div *dxTemplate>
            <ag-shr-prefix-select-box
              [value]="agentHeaderFormDetails?.p_prefix"
              (valueChange)="agentHeaderFormDetails.p_prefix = $event"
              (selectedItemChange)="handlePrefixSelect($event)"
              [showDropDownButton]="false"
            ></ag-shr-prefix-select-box>
          </div>
        </dxi-item>

        <dxi-item [dataField]="AgentKeys.p_agent_first_name" [colSpan]="2">
          <dxo-label [text]="'First Name'"></dxo-label>
        </dxi-item>
      </dxi-item>

      <dxi-item [dataField]="AgentKeys.p_agent_middle_name">
        <dxo-label [text]="'Middle Name'"></dxo-label>
      </dxi-item>

      <dxi-item itemType="group" [colCountByScreen]="nameItemColCountByScreen">
        <dxi-item [dataField]="AgentKeys.p_agent_last_name" [colSpan]="2">
          <dxo-label [text]="'Last Name'"></dxo-label>
        </dxi-item>

        <dxi-item [dataField]="AgentKeys.p_suffix">
          <dxo-label [text]="'Suffix'"></dxo-label>
          <div *dxTemplate>
            <ag-shr-suffix-select-box
              [value]="agentHeaderFormDetails?.p_suffix"
              (valueChange)="agentHeaderFormDetails.p_suffix = $event"
              (selectedItemChange)="handleSuffixSelect($event)"
              [showDropDownButton]="false"
            ></ag-shr-suffix-select-box>
          </div>
        </dxi-item>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colCountByScreen]="tripleColCountByScreen">
      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            label="MGA"
            labelMode="static"
            [value]="agentHeaderFormDetails?.p_mga_id | agency | async"
            [readOnly]="true"
          ></dx-text-box>
        </div>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            label="Agency"
            labelMode="static"
            [value]="agentHeaderFormDetails?.p_agency_id | agency | async"
            [readOnly]="true"
          ></dx-text-box>
        </div>
      </dxi-item>

      <dxi-item [dataField]="AgentKeys.title">
        <dxo-label [text]="'Title'"></dxo-label>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colCountByScreen]="dualColCountByScreen">
      <dxi-item
        [dataField]="AgentKeys.p_email"
        [editorOptions]="{
          readOnly: true,
        }"
      >
        <dxo-label [text]="'Primary Email Address'"></dxo-label>
      </dxi-item>

      <dxi-item
        editorType="dxSelectBox"
        [dataField]="AgentHeaderKeys.primaryPhoneNumber"
        [editorOptions]="{
          items: agentHeaderFormDetails?.phone_numbers,
          displayExpr: phoneNumberDisplayExpr,
          searchEnabled: true,
          placeholder: '',
        }"
      >
        <dxo-label [text]="'Primary Phone Number'"></dxo-label>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colCountByScreen]="dualColCountByScreen">
      <dxi-item
        editorType="dxSelectBox"
        [dataField]="AgentHeaderKeys.primaryBillingAddress"
        [editorOptions]="{
          items: agentHeaderFormDetails?.addresses,
          displayExpr: fullAddressPipe.transform,
          searchEnabled: true,
          placeholder: '',
        }"
      >
        <dxo-label [text]="'Primary Billing Address'"></dxo-label>
      </dxi-item>

      <dxi-item
        editorType="dxSelectBox"
        [dataField]="AgentHeaderKeys.primaryShippingAddress"
        [editorOptions]="{
          items: agentHeaderFormDetails?.addresses,
          displayExpr: fullAddressPipe.transform,
          searchEnabled: true,
          placeholder: '',
        }"
      >
        <dxo-label [text]="'Primary Shipping Address'"></dxo-label>
      </dxi-item>
    </dxi-item>
  </dx-form> -->
</ag-shr-modal-window>
