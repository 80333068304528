<div class="change-log_airline-grid">
  <dx-data-grid
    [dataSource]="dataSource"
    [showBorders]="true"
    [showRowLines]="true"
    [columnAutoWidth]="true"
    [noDataText]="'No Airline Preference Selected'"
    height="100%"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <div class="toolbar-label">
            <b>Airline Preferences</b>
          </div>
        </div>
      </dxi-item>
    </dxo-toolbar>

    <ng-container *ngFor="let key of airlinePreferencesKeys">
      <dxi-column
        [dataField]="key"
        [caption]="key | lookupDescriptionText: airlinePreferenceLookup"
        cellTemplate="logCellTmp"
        [width]="'auto'"
      >
      </dxi-column>
    </ng-container>

    <div *dxTemplate="let cell of 'logCellTmp'">
      <ng-container
        *ngTemplateOutlet="
          logViewTemplate;
          context: {
            oldValue: cell?.value?.oldValue,
            fromValue: cell?.value?.fromValue,
            toValue: cell?.value?.toValue,
          }
        "
      ></ng-container>
    </div>
  </dx-data-grid>
</div>

<ng-template #logViewTemplate let-oldValue="oldValue" let-fromValue="fromValue" let-toValue="toValue">
  <span class="change-log-detail--value me-3" *ngIf="!!oldValue">{{ oldValue }}</span>
  <span class="change-log-detail--old-value me-3" *ngIf="!!fromValue">{{ fromValue }}</span>
  <span class="change-log-detail--new-value">{{ toValue }}</span>
</ng-template>
