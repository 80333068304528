<ng-container *ngIf="!!log">
  <!--  <p class="small text-success">{{ log | json }}</p>-->
  <div class="change-log__flight-grid">
    <dx-data-grid
      [dataSource]="dataSource"
      [showBorders]="true"
      [showRowLines]="true"
      [columnAutoWidth]="true"
      [noDataText]="'No Flight Preference Selected'"
      height="100%"
    >
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <ng-container *ngFor="let key of flightPreferenceKeys">
        <dxi-column
          [dataField]="key"
          [caption]="key | lookupDescriptionText: flightInfoKeysConfig"
          cellTemplate="logCellTmp"
          [width]="'auto'"
        >
        </dxi-column>
      </ng-container>

      <div *dxTemplate="let cell of 'logCellTmp'">
        <ng-container
          *ngTemplateOutlet="
            logViewTemplate;
            context: {
              oldValue: cell?.value?.oldValue,
              fromValue: cell?.value?.fromValue,
              toValue: cell?.value?.toValue,
            }
          "
        ></ng-container>
      </div>
    </dx-data-grid>
  </div>

  <div class="change-log__flight-grid" *ngIf="!!airlinePreferencesDataSource?.length">
    <ag-shr-change-log-airline-preferences-grid-detail
      [dataSource]="airlinePreferencesDataSource"
    ></ag-shr-change-log-airline-preferences-grid-detail>
  </div>
</ng-container>

<ng-template #logViewTemplate let-oldValue="oldValue" let-fromValue="fromValue" let-toValue="toValue">
  <span class="change-log-detail--value me-3" *ngIf="!!oldValue">{{ oldValue }}</span>
  <span class="change-log-detail--old-value me-3" *ngIf="!!fromValue">{{ fromValue }}</span>
  <span class="change-log-detail--new-value">{{ toValue }}</span>
</ng-template>
