import { LookupKeys } from '@ag-common-lib/public-api';
import { Padding } from './template-builder-padding.model';
import { AlignItems } from './template-builder-align-items.model';

export enum ButtonStylingMode {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

export const ButtonStylingModeMap = new Map([
  [ButtonStylingMode.contained, 'Contained'],
  [ButtonStylingMode.outlined, 'Outlined'],
  [ButtonStylingMode.text, 'Text'],
]);

export const BUTTON_STYLING_MODE_LOOKUP = [
  ButtonStylingMode.contained,
  ButtonStylingMode.outlined,
  ButtonStylingMode.text,
].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: ButtonStylingModeMap.get(value) };
});

export enum ButtonType {
  normal = 'normal',
  default = 'default',
  success = 'success',
  danger = 'danger',
}

export const ButtonTypeMap = new Map([
  [ButtonType.normal, 'Normal'],
  [ButtonType.default, 'Default'],
  [ButtonType.success, 'Success'],
  [ButtonType.danger, 'Danger'],
]);

export const BUTTON_TYPE_LOOKUP = [ButtonType.normal, ButtonType.default, ButtonType.success, ButtonType.danger].map(
  value => {
    return { [LookupKeys.value]: value, [LookupKeys.description]: ButtonTypeMap.get(value) };
  },
);

export enum ButtonActionType {
  link = 'link',
  mailTo = 'mailTo',
  phone = 'phone',
}

export const ButtonActionMap = new Map([
  [ButtonActionType.link, 'Open Page'],
  [ButtonActionType.mailTo, 'Send Email'],
  [ButtonActionType.phone, 'Call Phone Number'],
]);

export const BUTTON_ACTION_TYPE_LOOKUP = [ButtonActionType.link, ButtonActionType.mailTo, ButtonActionType.phone].map(
  value => {
    return { [LookupKeys.value]: value, [LookupKeys.description]: ButtonActionMap.get(value) };
  },
);

export enum HrefTarget {
  blank = '_blank',
  self = '_self',
}

export const HrefTargetMap = new Map([
  [HrefTarget.blank, 'New Tab'],
  [HrefTarget.self, 'Same Tab'],
]);

export const HREF_TARGET_LOOKUP = [HrefTarget.blank, HrefTarget.self].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: HrefTargetMap.get(value) };
});

export class LinkButtonActionPayload {
  href: string = '';
  target: HrefTarget = HrefTarget.blank;
}

export class MailToButtonActionPayload {
  to: string = '';
  subject: string = '';
  body: string = '';
}

export class PhoneButtonActionPayload {
  number?: number = null;
}

type TemplateBuilderActionPayloadTypeMap = {
  [ButtonActionType.link]: LinkButtonActionPayload;
  [ButtonActionType.mailTo]: MailToButtonActionPayload;
  [ButtonActionType.phone]: PhoneButtonActionPayload;
};

type TemplateBuilderActionPayloadType<T extends ButtonActionType> = TemplateBuilderActionPayloadTypeMap[T];

export class ButtonWidgetData<T extends ButtonActionType = ButtonActionType> {
  text?: string = 'Button Text';
  icon?: string = null;
  stylingMode: ButtonStylingMode = ButtonStylingMode.contained;
  type: ButtonType = ButtonType.default;
  alignItems: AlignItems = AlignItems.flexStart;

  constructor(
    public actionType: T,
    public actionPayload?: TemplateBuilderActionPayloadType<T>,
  ) {}
}
