<ag-shr-modal-window
  #shareMediaModalRef
  [width]="'365px'"
  [height]="'auto'"
  [title]="'Share Media'"
  [showSaveButton]="false"
>
  <div class="pwa-media-item__modal">
    <div class="pwa-media-item__modal-controls">
      @for (btnAction of shareMediaButtonAction; track btnAction.action) {
        <dx-button
          class="pwa-media-item__share-btn"
          [icon]="btnAction.icon"
          [type]="'normal'"
          [stylingMode]="'contained'"
          (onClick)="shareVimeo(btnAction.action)"
        ></dx-button>
      }
    </div>

    <hr class="pwa-media-item__modal-delimiter" />

    <div class="pwa-media-item__modal-controls">
      <dx-button
        class=""
        [icon]="'agi-copy'"
        [text]="'Copy Link'"
        [type]="'normal'"
        [stylingMode]="'contained'"
        [elementAttr]="{ id: 'popoverTarget' }"
        (onClick)="copyVimeoLink()"
      ></dx-button>

      <dx-popover
        #copyPopoverTarget
        [target]="'#popoverTarget'"
        position="top"
        [width]="'auto'"
        [height]="'auto'"
        [showTitle]="false"
        [hideOnOutsideClick]="true"
      >
        <div *dxTemplate="let data = model of 'content'">Link copied!</div>
      </dx-popover>
    </div>
  </div>
</ag-shr-modal-window>
