import { LookupKeys } from '@ag-common-lib/public-api';
import { TemplateBuilderBlock } from './template-builder-block.model';
import { TemplateBuilderModule } from './template-builder-module.model';
import { BaseModel } from '@ag-common-lib/lib/models/base.model';

export class TemplateCommonSettings {
  contentBackgroundColor: string;
  backgroundColor: string;
  textColor: string;
  contentMaxWidth: number;
  contentAlignment: 'left' | 'center';
  fontFamily: string;
  fontWeight: 'regular' | 'bold';

  mainColor: string;
  secondaryColor: string;
  accentColor: string;
  highlightColor: string;
  mutedColor: string;
  linkColor: string;

  constructor(settings?: Partial<TemplateCommonSettings>) {
    this.contentBackgroundColor = settings?.contentBackgroundColor ?? '#fff';
    this.backgroundColor = settings?.backgroundColor ?? '#f2f2f2';
    this.textColor = settings?.textColor ?? '#333';
    this.contentMaxWidth = settings?.contentMaxWidth ?? 760;
    this.contentAlignment = settings?.contentAlignment ?? 'center';
    this.fontFamily = settings?.fontFamily ?? TemplateFontFamilies.helvetica;
    this.fontWeight = settings?.fontWeight ?? 'regular';
    this.mainColor = settings?.mainColor ?? '#337ab7';
    this.secondaryColor = settings?.secondaryColor ?? '#092445';
    this.accentColor = settings?.accentColor ?? '#2196f3';
    this.highlightColor = settings?.highlightColor ?? '#f0f2fc';
    this.mutedColor = settings?.mutedColor ?? '#9e9e9e';
    this.linkColor = settings?.linkColor ?? '#00bcd4';
  }
}

export enum TemplateFontFamilies {
  arial = 'arial',
  courierNew = 'courier new',
  georgia = 'georgia',
  impact = 'impact',
  lucidaConsole = 'lucida console',
  tahoma = 'tahoma',
  timesNewRoman = 'times new roman',
  verdana = 'verdana',
  helvetica = 'helvetica',
}

export const TemplateFontFamiliesLookup = [
  { value: TemplateFontFamilies.arial, description: 'Arial' },
  { value: TemplateFontFamilies.courierNew, description: 'Courier New' },
  { value: TemplateFontFamilies.georgia, description: 'Georgia' },
  { value: TemplateFontFamilies.impact, description: 'Impact' },
  { value: TemplateFontFamilies.lucidaConsole, description: 'Lucida Console' },
  { value: TemplateFontFamilies.tahoma, description: 'Tahoma' },
  { value: TemplateFontFamilies.timesNewRoman, description: 'Times New Roman' },
  { value: TemplateFontFamilies.verdana, description: 'Verdana' },
  { value: TemplateFontFamilies.helvetica, description: 'Helvetica' },
];

export enum TemplateKeys {
  type = 'type',
  subtype = 'subtype',
  previewDataUrl = 'previewDataUrl',
  name = 'name',
  rows = 'rows',
  commonSettings = 'commonSettings',
}

export enum TemplateTypes {
  page = 'page',
  email = 'email',
}

export const TemplateTypesMap = new Map([
  [TemplateTypes.page, 'Page'],
  [TemplateTypes.email, 'Email'],
]);

export const TEMPLATE_TYPES_LOOKUP = [TemplateTypes.page, TemplateTypes.email].map(value => {
  return {
    [LookupKeys.value]: value,
    [LookupKeys.description]: TemplateTypesMap.get(value),
  };
});

export enum TemplateSubTypes {
  page = 'page',
  block = 'block',
  module = 'module',
}

export const TemplateSubTypesMap = new Map([
  [TemplateSubTypes.page, 'Page'],
  [TemplateSubTypes.block, 'Block Template'],
  [TemplateSubTypes.module, 'Global Block'],
]);

export const PAGE_TEMPLATE_SUBTYPES_LOOKUP = [
  TemplateSubTypes.page,
  TemplateSubTypes.block,
  TemplateSubTypes.module,
].map(value => {
  return {
    [LookupKeys.value]: value,
    [LookupKeys.description]: TemplateSubTypesMap.get(value),
  };
});

export class Template extends BaseModel {
  [TemplateKeys.type]: TemplateTypes;
  [TemplateKeys.subtype]: TemplateSubTypes;
  [TemplateKeys.name]: string;
  [TemplateKeys.previewDataUrl]?: string;
  [TemplateKeys.rows]: Array<TemplateBuilderBlock | TemplateBuilderModule> = [];
  [TemplateKeys.commonSettings] = Object.assign({}, new TemplateCommonSettings());
}
