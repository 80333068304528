import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  DX_USD_CURRENCY_FORMAT,
  EditorOptions,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  LookupKeys,
  Messages,
  Patterns,
  MILITARY_TIME_FORMAT,
  HtmlEditorTypeKeys,
} from 'ag-common-lib/public-api';
import {
  Excursion,
  ExcursionKeys,
  ExcursionOptionKey,
  ExcursionPreference,
  ExcursionPreferenceKeys,
  excursionTimeOfDayLookup,
  excursionTypeLookup,
  timePreferenceFormatLookup,
} from 'ag-common-lib/lib/models/registration/excursion.model';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { ValidationCallbackData } from 'devextreme/common';
import { ExcursionModalService } from './excursion-modal.service';
import { InitializedEvent } from 'devextreme/ui/calendar';
import {
  InitNewRowEvent,
  MasterDetailTemplateData,
  RowInsertingEvent,
  RowRemovingEvent,
  RowUpdatingEvent,
} from 'devextreme/ui/data_grid';
import { ConferenceService } from 'ag-common-svc/public-api';
import { DxoEditingComponent } from 'devextreme-angular/ui/nested';
import { isValid } from 'date-fns/isValid';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';

@UntilDestroy()
@Component({
  selector: 'ag-crm-excursion-modal',
  templateUrl: './excursion-modal.component.html',
  styleUrls: ['./excursion-modal.component.scss'],
})
export class ExcursionModalComponent {
  @HostBinding('class') className = 'excursion-modal';
  @ViewChild('excursionModalRef', { static: true }) excursionModalComponent: ModalWindowComponent;

  @Input() calendarDisplayDate: string;

  protected formData: Excursion;
  isReadOnly = false;

  protected readonly inProgress = false;
  protected readonly ExcursionKeys = ExcursionKeys;
  protected readonly ExcursionOptionKey = ExcursionOptionKey;
  protected readonly ExcursionPreferenceKeys = ExcursionPreferenceKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly HtmlEditorTypeKeys = HtmlEditorTypeKeys;
  protected readonly validationGroup = 'excursionValidationGroup';
  protected readonly excursionDescriptionValidationGroup = 'excursionDescriptionValidationGroup';
  protected readonly dateEditorOptions;
  protected readonly timeEditorOptions = Object.assign(
    {},
    {
      type: 'time',
      interval: 15,
      useMaskBehavior: true,
      displayFormat: 'hh:mm a',
      dateSerializationFormat: MILITARY_TIME_FORMAT,
    },
  );
  protected readonly timePreferenceFormatLookup = timePreferenceFormatLookup;
  protected readonly excursionTimeOfDayLookup = excursionTimeOfDayLookup;
  protected readonly excursionTypeLookup = excursionTypeLookup;
  protected readonly Messages = Messages;
  protected readonly safeFirebaseFieldNameRegExp = Patterns.SAFE_FIREBASE_FIELD_NAME_REG_EXP;
  protected readonly shortTimeFormat = MILITARY_TIME_FORMAT;
  protected readonly tabsDataSource = [
    { title: 'Details', template: 'detailsItemTmp' },
    { title: 'Short Description', template: 'shortDescriptionItemTmp' },
    { title: 'Preferences', template: 'optionsItemTmp' },
    { title: 'Configuration', template: 'configurationItemTmp' },
  ];
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;

  private conferenceDbId: string;
  private onSave: (excursion: Partial<Excursion>) => Promise<boolean>;

  constructor(
    private excursionModalService: ExcursionModalService,
    private conferenceService: ConferenceService,
  ) {
    this.dateEditorOptions = Object.assign({}, EditorOptions.DATE, {
      dateSerializationFormat: LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
      calendarOptions: {
        onInitialized: this.navigateToDefaultCalendarDate,
      },
    });
  }

  showModal(data: Excursion = null, conferenceDbId: string, onSave: (excursion: Excursion) => Promise<boolean>): void {
    this.isReadOnly = !onSave;
    this.onSave = onSave;
    this.conferenceDbId = conferenceDbId;
    this.formData = this.excursionModalService.getFormData(data);
    this.excursionModalComponent?.showModal();
  }

  protected handleSaveExcursion = async () => {
    const isValid = await validateDxGroups([this.validationGroup, this.excursionDescriptionValidationGroup]);

    if (!isValid) {
      return;
    }

    const isCancelled = await this.onSave(this.formData);

    if (isCancelled) {
      return;
    }
    this.excursionModalService.revertAllChanges();
    this.excursionModalComponent.hideModal();
  };

  protected costValidator = (e: ValidationCallbackData) => {
    return !!e?.value;
  };

  protected navigateToDefaultCalendarDate = (e: InitializedEvent) => {
    const value = e.component.option('value');

    if (value) {
      return;
    }

    const date = this.calendarDisplayDate;

    if (!isValid(date)) {
      return;
    }

    e.component.option('currentDate', date);
  };

  protected handleClosePopup = this.excursionModalService.onCancelEdit;

  protected onInitNewExcursionPreference = (e: InitNewRowEvent) => {
    Object.assign(e.data, new ExcursionPreference());
  };

  protected onExcursionInsertingPreference = (e: RowInsertingEvent) => {
    const { __KEY__, ...preference } = e.data;
    const preferences = [preference];

    this.formData?.[ExcursionKeys.preferences]?.forEach(preference => {
      const activity = Object.assign({}, preference);

      preferences.push(activity);
    });

    e.cancel = this.savePreferencesChanges(preferences);
  };

  protected onExcursionUpdatingPreference = (e: RowUpdatingEvent) => {
    const preferences = [];

    this.formData?.[ExcursionKeys.preferences]?.forEach(preference => {
      const activity = Object.assign({}, preference);
      if (e.key === preference) {
        Object.assign(activity, e.newData);
      }
      preferences.push(activity);
    });

    e.cancel = this.savePreferencesChanges(preferences);
  };

  protected onExcursionRemovingPreference = (e: RowRemovingEvent) => {
    const preferences = [];

    this.formData?.[ExcursionKeys.preferences]?.forEach(preference => {
      if (e.key !== preference) {
        const activity = Object.assign({}, preference);
        preferences.push(activity);
      }
    });

    e.cancel = this.savePreferencesChanges(preferences);
  };

  protected onExcursionInsertingPreferenceOption = (
    masterDetailsData: MasterDetailTemplateData,
    e: RowInsertingEvent,
  ) => {
    const { __KEY__, ...preferenceOption } = e.data;
    const { data } = masterDetailsData;
    const options = data?.[ExcursionPreferenceKeys.items];

    e.cancel = this.updatePreferenceOptions(masterDetailsData.key, [...options, preferenceOption]);
  };

  protected onExcursionUpdatingPreferenceOption = (
    masterDetailsData: MasterDetailTemplateData,
    e: RowUpdatingEvent,
  ) => {
    const { data } = masterDetailsData;
    const options = data?.[ExcursionPreferenceKeys.items];
    e.cancel = this.updatePreferenceOptions(
      masterDetailsData.key,
      options.map(option => {
        const item = Object.assign({}, option);
        if (option === e.key) {
          Object.assign(item, e.newData);
        }
        return item;
      }),
    )
      .then(() => {
        return true;
      })
      .catch(() => {
        return true;
      });
  };

  protected onExcursionRemovingPreferenceOption = (
    masterDetailsData: MasterDetailTemplateData,
    e: RowRemovingEvent,
  ) => {
    const { data } = masterDetailsData;
    const options = data?.[ExcursionPreferenceKeys.items];

    e.cancel = this.updatePreferenceOptions(
      masterDetailsData.key,
      options.filter(option => {
        return option !== e.key;
      }),
    );
  };

  private updatePreferenceOptions = async (key, options) => {
    const preferences = [];

    this.formData?.[ExcursionKeys.preferences]?.forEach(preference => {
      const item = Object.assign({}, preference);
      if (preference === key) {
        item[ExcursionPreferenceKeys.items] = options;
      }

      preferences.push(item);
    });

    return this.savePreferencesChanges(preferences);
  };

  private savePreferencesChanges = async (preferences: ExcursionPreference[]) => {
    if (!this.conferenceDbId) {
      Object.assign(this.formData, { [ExcursionKeys.preferences]: preferences });
      return false;
    }
    await this.onSave(Object.assign({}, this.formData, { [ExcursionKeys.preferences]: preferences })).then(() => {
      Object.assign(this.formData, { [ExcursionKeys.preferences]: preferences });
    });

    return false;
  };

  protected checkExcursionPreferenceLabelFormat = (e: ValidationCallbackData) => {
    return false;
  };

  protected checkIsExcursionPreferenceUniq = (
    editingComponent: DxoEditingComponent,
    { data }: ValidationCallbackData,
  ) => {
    const label = data?.[ExcursionPreferenceKeys.label];
    const isValid = this.formData?.[ExcursionKeys.preferences].every(preference => {
      if (preference === editingComponent.editRowKey) {
        return true;
      }

      return preference?.[ExcursionPreferenceKeys.label]?.toLocaleLowerCase() !== label?.toLocaleLowerCase();
    });

    return isValid;
  };

  protected checkIsExcursionPreferenceOptionUniq = (
    editingComponent: DxoEditingComponent,
    options,
    { data }: ValidationCallbackData,
  ) => {
    const name = data?.name;
    const isValid = options.every(option => {
      if (option === editingComponent.editRowKey) {
        return true;
      }

      return option?.name?.toLocaleLowerCase() !== name?.toLocaleLowerCase();
    });

    return isValid;
  };

  async onSaveConfiguration(newData: Excursion): Promise<boolean> {
    if (!this.conferenceDbId) {
      Object.assign({}, this.formData, newData);
      return false;
    }
    await this.onSave(Object.assign({}, newData)).then(() => {
      Object.assign({}, this.formData, newData);
    });
    return false;
  }
}
