import { Pipe, PipeTransform } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { LookupBase } from '@ag-common-lib/lib/models/utils/lookup-base.model';
import { isNil } from 'lodash';

@Pipe({
  name: 'changeLogsDiffHTML',
})
export class ChangeLogsDiffHtmlPipe implements PipeTransform {
  transform(data: Delta, lookup?: LookupBase[], valueLookup?: LookupBase[]): string {
    if (!data) {
      return null;
    }
    const registrationData = Array.isArray(data) ? data[0] : data;

    const logs: string[] = Object.entries(registrationData).map(([key, value]): string => {
      const item = lookup?.find(item => item.value === key)?.description ?? key;
      const fromValue = this.getFromValue(value, valueLookup);
      const toValue = `${!fromValue ? '' : 'to'} ${this.getToValue(value, valueLookup)}`;
      const stringByValue = !fromValue ? 'added as' : 'changed';
      return `<u>${item}</u> value ${stringByValue} ${fromValue} ${toValue}`;
    });
    return logs.filter(Boolean).join('<br>');
  }

  private getToValue(registrationData, valueLookup: LookupBase[] = null): string {
    const toValue = Array.isArray(registrationData)
      ? registrationData.length > 1
        ? registrationData[1]
        : registrationData[0]
      : registrationData;
    return ` <span class="change-log-detail--new-value">${isNil(toValue) ? '&mdash;' : this.normalizeToValue(toValue, valueLookup)}</span>`;
  }

  private getFromValue(registrationData, valueLookup: LookupBase[] = null): string {
    let fromValueString = '';
    if (Array.isArray(registrationData) && registrationData?.length > 1) {
      const fromValue = isNil(registrationData[0])
        ? '&mdash;'
        : this.normalizeToValue(registrationData[0], valueLookup);
      fromValueString = `from <span class="change-log-detail--old-value">${fromValue}</span>`;
    }

    return fromValueString;
  }

  private normalizeToValue = (data, valueLookup?: LookupBase[]) =>
    valueLookup ? valueLookup.find(({ value }) => value === data)?.description : data;
}
