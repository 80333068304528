import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogDetailModule } from '../../change-log-detail/change-log-detail.module';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeModule } from '../../change-log-detail-by-type/change-log-detail-by-type.module';
import { ChangeLogAirlinePreferencesComponent } from './change-log-airline-preferences.component';

@NgModule({
  declarations: [ChangeLogAirlinePreferencesComponent],
  imports: [CommonModule, SharedModule, ChangeLogDetailModule, ChangeLogPipesModule, ChangeLogDetailByTypeModule],
  exports: [ChangeLogAirlinePreferencesComponent],
})
export class ChangeLogAirlinePreferencesModule {}
