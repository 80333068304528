<video
  #vimeoVideoRef
  class="vimeo-video__video video-js"
  [poster]="poster"
  [preload]="preloadMode"
  [attr.autoplay]="autoplay ? '' : null"
  [attr.muted]="muted ? '' : null"
  [attr.controls]="showControls ? '' : null"
>
  <source
    *ngFor="let source of sources"
    [src]="source.link"
    [type]="source.type"
    [attr.label]="source.public_name"
    [attr.selected]="source.quality === 'hd' ? true : null"
  />
  <ng-content class="vjs-no-js" select="[not-supported-source]"></ng-content>
</video>

<ag-vimeo-video-share-modal #shareMediaModalRef></ag-vimeo-video-share-modal>
