import { ResponsiveOptions } from './template-builder-responsive-options';
import { TemplateEditorItems } from './template-builder-item.model';
import { TemplateBuilderBlock } from './template-builder-block.model';
import { TemplateBuilderContainerProperties } from './template-builder-container-properties';
import { TemplateBuilderModule } from './template-builder-module.model';

export class TemplateBuilderBlockTemplate {
  type: TemplateEditorItems.blockTemplate = TemplateEditorItems.blockTemplate;
  desktopContainerProperties: TemplateBuilderContainerProperties = new TemplateBuilderContainerProperties();
  mobileContainerProperties?: Partial<TemplateBuilderContainerProperties> = {};
  responsiveOptions: ResponsiveOptions = new ResponsiveOptions();

  constructor(
    public templateDbId: string,
    public blocks: Array<TemplateBuilderBlock | TemplateBuilderModule>,
  ) {}
}
