import { Component, HostBinding, Input } from '@angular/core';
import {
  Conference,
  ConferenceDetailsPageType,
  ConferenceKeys,
  Constants,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  Messages,
  STATIC_CONFERENCE_DETAILS_PAGES,
} from 'ag-common-lib/public-api';
import { CONFERENCES_TITLE_LIST, PHONE_NUMBER_OPTIONS } from '../../../config/conferences.config';
import {
  TemplateKeys,
  TemplateTypes,
} from 'ag-common-svc/lib/components/administration/template-builder/template-editor-page/models';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';

import { shareReplay, combineLatest, Observable, map } from 'rxjs';
import { TemplatesService } from 'ag-common-svc/lib/services/templates.service';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-crm-conference-primary-info-form',
  templateUrl: './conference-primary-info-form.component.html',
  styleUrls: ['./conference-primary-info-form.component.scss'],
})
export class ConferencePrimaryInfoFormComponent {
  @HostBinding('class') className = 'conference-primary-info-form';
  @Input() conference: Partial<Conference>;
  @Input() validationGroup: string;
  @Input() isReadonlyMode: boolean = false;

  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT = LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT;
  protected readonly requiredMessage = Messages.REQUIRED_TEXT;
  protected readonly phoneNumberOptions = PHONE_NUMBER_OPTIONS;
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly Messages = Messages;

  private dynamicPages$ = this.templatesService
    .getList([new QueryParam(TemplateKeys.type, WhereFilterOperandKeys.equal, TemplateTypes.page)], {
      sortField: TemplateKeys.name,
    })
    .pipe(
      map(pages =>
        pages.map(page => ({ name: page.name, route: `/${ConferenceDetailsPageType}/${page?.[BaseModelKeys.dbId]}` })),
      ),
      shareReplay(1),
    );

  groupedPageList$: Observable<any[]> = combineLatest({
    dynamicPages: this.dynamicPages$,
  }).pipe(
    map(({ dynamicPages }) => {
      const staticPages = {
        key: 'Static Pages',
        items: STATIC_CONFERENCE_DETAILS_PAGES,
      };

      const dynamicPagesGroup = {
        key: 'Dynamic Pages',
        items: dynamicPages,
      };

      return [staticPages, dynamicPagesGroup];
    }),
  );

  constructor(private templatesService: TemplatesService) {}
}
