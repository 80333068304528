import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AirlinePreferenceKeys, AIRLINE_PREFERENCE_LOOKUP } from '@ag-common-lib/lib/models/utils/flight-preference.model';
import { TRUE_FALSE_LOOKUP, AIRLINES_LOOKUP } from '@ag-common-lib/public-api';
import { hasLog, normalizeLog } from '../../../../utils/normalize-log';

@Component({
  selector: 'ag-shr-change-log-airline-preferences-grid-detail',
  templateUrl: './change-log-airline-preferences-grid.component.html',
  styleUrls: ['./change-log-airline-preferences-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogAirlinePreferencesGridComponent {
  @Input() dataSource = [];

  protected readonly airlinePreferencesKeys = Object.keys(AirlinePreferenceKeys);
  protected readonly airlinePreferenceLookup = AIRLINE_PREFERENCE_LOOKUP;
  protected readonly airlinesLookup = AIRLINES_LOOKUP;
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
}
