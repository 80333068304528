export enum TemplateEditorItems {
  widget = 'widget',
  block = 'block',
  module = 'module',
  blockTemplate = 'blockTemplate',
}

export const TemplateEditorItemsMap = new Map([
  [TemplateEditorItems.block, 'Block Template Settings'],
  [TemplateEditorItems.module, 'Global Block Settings'],
  [TemplateEditorItems.widget, 'Widget Settings'],
]);
