import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangeLogsType, ChangeLogsConfigKey, ChangeLogsDataType } from '@ag-common-lib/public-api';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import { AirlinePreferenceKeys } from '@ag-common-lib/lib/models/utils/flight-preference.model';
import { getLogIndexes } from '../flight-info-log.utils';

@Component({
  selector: 'ag-shr-change-log-airline-preferences',
  templateUrl: './change-log-airline-preferences.component.html',
  styleUrls: ['./change-log-airline-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogAirlinePreferencesComponent {
  @Input()
  set logs(delta: Delta) {
    // Delta for changed object is object of objects
    if (!Array.isArray(delta)) {
      this._logs = delta;
      this.airlinePreferencesLogIndexes = getLogIndexes(Object.entries(this._logs));
      return;
    }
    // Delta for new value is array with array of objects
    this._logs = delta[0] as Delta;
    this.airlinePreferencesLogIndexes = getLogIndexes(this._logs);
  }
  get logs(): Delta {
    return this._logs;
  }
  airlinePreferencesLogIndexes: number[] = [];

  protected readonly hasLog = hasLog;
  protected readonly normalizeLog = normalizeLog;
  protected readonly airlinePreferencesLookup = [
    {
      [ChangeLogsConfigKey.value]: AirlinePreferenceKeys.airline,
      [ChangeLogsConfigKey.description]: 'Airline',
    },
    {
      [ChangeLogsConfigKey.value]: AirlinePreferenceKeys.loyaltyNumber,
      [ChangeLogsConfigKey.description]: 'Loyalty Number',
    },
    {
      [ChangeLogsConfigKey.value]: AirlinePreferenceKeys.isFirstChoiceAirline,
      [ChangeLogsConfigKey.description]: 'Is First Choice Airline',
      [ChangeLogsConfigKey.dataType]: ChangeLogsDataType.boolean,
    },
    {
      [ChangeLogsConfigKey.value]: AirlinePreferenceKeys.isSecondChoiceAirline,
      [ChangeLogsConfigKey.description]: 'Is Second Choice Airline',
      [ChangeLogsConfigKey.dataType]: ChangeLogsDataType.boolean,
    },
    {
      [ChangeLogsConfigKey.value]: AirlinePreferenceKeys.otherPreferredAirline,
      [ChangeLogsConfigKey.description]: 'Other Preferred Airline',
    },
  ];
  protected readonly ChangeLogsType = ChangeLogsType;
  protected readonly Array = Array;
  private _logs: Delta;
}
