import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { VimeoVideoComponent } from './vimeo-video.component';
import { DxButtonModule, DxPopoverModule, DxTemplateModule } from 'devextreme-angular';
import { ModalWindowModule } from 'ag-common-svc/lib/components';
import { VimeoVideoShareModalComponent } from './components/vimeo-video-share-modal/vimeo-video-share-modal.component';

@NgModule({
  declarations: [VimeoVideoComponent, VimeoVideoShareModalComponent],
  exports: [VimeoVideoComponent],
  imports: [CommonModule, SharedModule, DxButtonModule, DxPopoverModule, DxTemplateModule, ModalWindowModule],
})
export class VimeoVideoModule {}
