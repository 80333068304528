import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ActiveLookup, Address, AddressModelKeys, COUNTRIES } from '@ag-common-lib/public-api';
import { Observable } from 'rxjs';
import { LookupsService } from 'ag-common-svc/public-api';
import { FullAddressPipe } from 'ag-common-svc/shared/pipes/full-address.pipe';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { AddressFormComponent } from 'ag-common-svc/lib/components/address-form/address-form.component';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { GoogleMapsService } from 'ag-common-svc/lib/services';

@Component({
  selector: 'ag-shr-addresses-modal',
  templateUrl: './addresses-modal.component.html',
  styleUrls: ['./addresses-modal.component.scss'],
  providers: [FullAddressPipe],
})
export class AddressesModalComponent {
  @HostBinding('class') className = 'addresses-modal';
  @ViewChild('manageAddressModalRef') manageAddressModalComponent: ModalWindowComponent;
  @ViewChild('addressFormRef', { static: false }) addressFormComponent: AddressFormComponent;

  @Input() addressToEdit: Address;
  @Input() addressFormTitle: string = '';
  @Input() hasPermissionsToAdd: boolean = true;
  @Input() hasPermissionsToEdit: boolean = true;
  @Input() hasPermissionsToDelete: boolean = true;

  @Input() showPhysicalLocation = true;
  @Input() showAddressType = true;
  @Input() showBillingAddress = true;
  @Input() showShippingAddress = true;

  @Input() inProgress;

  @Input() typesLookup: Partial<ActiveLookup>[];
  @Output() onAddressSave = new EventEmitter();
  @Input() checkIsAddressUniq;

  protected isValidationInProgress = false;
  protected readonly countries = COUNTRIES;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly statesLookup$: Observable<ActiveLookup[]> = this.lookupsService.statesLookup$;

  protected readonly addressValidationGroup = 'addressValidationGroup';
  protected readonly addressUniqValidationGroup = 'addressUniqValidationGroup';

  protected readonly addressUniqAdapter = {
    getValue: e => {
      return this.addressToEdit;
    },
    bypass: () => {
      return !this.addressToEdit?.[AddressModelKeys.address1];
    },
    reset: e => {},
  };

  constructor(
    private readonly lookupsService: LookupsService,
    private googleMapsService: GoogleMapsService,
  ) {}

  protected handleAddressSave = async e => {
    this.isValidationInProgress = true;
    const isValid = await validateDxGroups([this.addressValidationGroup, this.addressUniqValidationGroup]);

    if (!isValid) {
      this.isValidationInProgress = false;
      return;
    }

    this.onAddressSave.emit();
  };

  protected handleClosePopup = () => {
    return this.addressFormComponent.onCancelEdit();
  };

  protected validateAddress = async () => {
    if (!this.addressToEdit?.[AddressModelKeys.address1]) {
      return;
    }
    const validationResponse = await this.googleMapsService.validateAddress(this.addressToEdit);
    this.addressToEdit[AddressModelKeys.validationResponse] = validationResponse;
  };
}
