<dx-form
  #formRef
  [formData]="phoneNumber"
  labelMode="floating"
  [validationGroup]="validationGroup"
  [colCountByScreen]="colCountByScreen"
  [screenByWidth]="formSize$ | async | formScreenByWidth"
  (onInitialized)="onFormInitialized($event)"
  (onFieldDataChanged)="formRef?.instance?.validate()"
>
  <dxi-item itemType="group" [colSpan]="6" [colCount]="6">
    <dxi-item
      [dataField]="PhoneNumberKeys.number"
      [label]="{ text: 'Number' }"
      [colSpan]="4"
      [editorOptions]="{
        name: [pathPrefix, PhoneNumberKeys.number] | path,
        mask: phoneMask,
        readOnly: isNumberReadonly,
        inputAttr: { autocomplete: 'disabled' },
      }"
    >
      <dxi-validation-rule
        type="pattern"
        [pattern]="phonePattern"
        [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
      ></dxi-validation-rule>

      <dxi-validation-rule
        type="custom"
        [message]="'Number field are required for Phone Number'"
        [reevaluate]="true"
        [validationCallback]="numberValidationCallback"
      ></dxi-validation-rule>

      <dxi-validation-rule
        type="required"
        [message]="Messages.REQUIRED_PHONE_TEXT"
        *ngIf="isNumberFieldRequired"
      ></dxi-validation-rule>
    </dxi-item>

    <dxi-item
      [dataField]="PhoneNumberKeys.extension"
      [label]="{ text: 'Extension' }"
      [colSpan]="2"
      [editorOptions]="{ name: [pathPrefix, PhoneNumberKeys.extension] | path, readOnly: isExtensionReadonly }"
    ></dxi-item>
  </dxi-item>

  <dxi-item
    [dataField]="PhoneNumberKeys.phone_type"
    [label]="{ text: 'Type' }"
    editorType="dxSelectBox"
    [editorOptions]="{
      name: [pathPrefix, PhoneNumberKeys.phone_type] | path,
      acceptCustomValue: true,
      items: PHONE_NUMBER_TYPE_LOOKUP,
      valueExpr: 'value',
      displayExpr: 'description',
      searchEnabled: true,
      placeholder: '',
      readOnly: isTypeReadonly,
    }"
    [colSpan]="2"
  >
    <dxi-validation-rule
      *ngIf="!!phoneNumber?.number && isNumberFieldRequired"
      type="required"
      [message]="'Phone Number Type is required'"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    *ngIf="isPrimaryVisible"
    [dataField]="PhoneNumberKeys.is_primary"
    [alignItemLabels]="true"
    [colSpan]="2"
    [label]="{ text: 'Is Primary' }"
    editorType="dxSwitch"
    cssClass="ag-switch-with-label"
    [disabled]="phoneNumber?.[PhoneNumberKeys.is_primary]"
    [editorOptions]="{
      name: [pathPrefix, PhoneNumberKeys.is_primary] | path,
      minWidth: '200',
      switchedOffText: 'No',
      switchedOnText: 'Yes',
      readOnly: isPrimaryReadonly,
    }"
  ></dxi-item>

  <dxi-item
    [dataField]="PhoneNumberKeys.is_whatsapp"
    [alignItemLabels]="true"
    [colSpan]="2"
    [label]="{ text: 'WhatsApp' }"
    editorType="dxSwitch"
    cssClass="ag-switch-with-label"
    [editorOptions]="{
      name: [pathPrefix, PhoneNumberKeys.is_whatsapp] | path,
      minWidth: '200',
      switchedOffText: 'No',
      switchedOnText: 'Yes',
      readOnly: isWhatsappReadonly,
    }"
  ></dxi-item>
</dx-form>
