<div class="media-uploader__container" *var="(selectedTab$ | async)?.itemData as selectedTabData">
  <ag-shr-tabs
    *ngIf="tabConfig?.length > 1"
    class="media-uploader__header"
    [dataSource]="tabConfig"
    [selectedIndex]="selectedTabIndex$ | async"
    (onItemClick)="onSelectedIndexChange($event)"
  >
  </ag-shr-tabs>

  <ag-shr-upload-from-disk-tab
    class="media-uploader__content"
    [placeholder]="placeholder"
    [accept]="accept"
    *ngIf="selectedTabData?.id === MediaUploaderTabs.FromFile"
  >
    <ng-container *ngTemplateOutlet="fileNameInputTmp; context: { fileData: fileData$ | async }"></ng-container>
    <ng-content select="[from-disk-tab-extra-content]"></ng-content>
  </ag-shr-upload-from-disk-tab>

  <ag-shr-upload-from-url-tab class="media-uploader__content" *ngIf="selectedTabData?.id === MediaUploaderTabs.ByURL">
    <ng-container *ngTemplateOutlet="fileNameInputTmp; context: { fileData: fileData$ | async }"></ng-container>
  </ag-shr-upload-from-url-tab>

  <ag-shr-select-from-gallery
    *ngIf="selectedTabData?.id === MediaUploaderTabs.Gallery && !useCustomMediaGallery"
    class="media-uploader__content"
    [mediaItems]="mediaItems"
  >
  </ag-shr-select-from-gallery>

  <ng-content
    select="[custom-media-gallery]"
    *ngIf="selectedTabData?.id === MediaUploaderTabs.Gallery && !!useCustomMediaGallery"
    class="media-uploader__content"
  >
  </ng-content>
</div>

<ng-template #fileNameInputTmp let-fileData="fileData">
  <dx-text-box
    [width]="'100%'"
    [value]="fileData?.fileName"
    (valueChange)="fileData.fileName = $event"
    [label]="'File name'"
    [labelMode]="'floating'"
    [showClearButton]="false"
    class="upload-from-disk-tab__text-input"
    [disabled]="!fileData?.base64"
  >
    <dx-validator [validationGroup]="FILE_NAME_VALIDATION_GROUP">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dx-validator>
  </dx-text-box>
</ng-template>
