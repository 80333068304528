import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import {
    getChangedLog,
    getLogIndexes,
    hasLog,
    isLogArray,
    isRemovedLog,
    normalizeLog
} from '../../../utils/normalize-log';
import { ChangeLogsConfig, ChangeLogsDataType, ChangeLogsHistoryType } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-change-log-details-by-type',
  templateUrl: './agent-change-log-details-by-type.component.html',
  styleUrls: ['./agent-change-log-details-by-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentChangeLogDetailsByTypeComponent {
  @Input() keysConfig: ChangeLogsConfig[];
  @Input() caption = 'Item';
  @Input() showCaption = true;
  @Input() dataType: ChangeLogsDataType;
  @Input() oldData: any;
  @Input()
  set logs(delta: Delta) {
    // Delta for changed object is object of objects
    if (!Array.isArray(delta)) {
      this._logs = delta;
      this.logIndexes = getLogIndexes(Object.entries(this._logs));
      return;
    }
    // Delta for new value is array with array of objects
    this._logs = delta as Delta;
    this.logIndexes = getLogIndexes(this._logs);
  }
  get logs(): Delta {
    return this._logs;
  }

  private _logs: Delta;
  protected logIndexes: number[] = [];
  protected readonly ChangeLogsDataType = ChangeLogsDataType;
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly isRemovedLog = isRemovedLog;
  protected readonly isLogArray = isLogArray;
  protected readonly getChangedLog = getChangedLog;
  protected readonly ChangeLogsHistoryType = ChangeLogsHistoryType;
}
