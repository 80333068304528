import { LookupKeys } from '@ag-common-lib/public-api';

export enum ObjectFit {
  contain = 'contain',
  cover = 'cover',
}

export const ObjectFitMap = new Map([
  [ObjectFit.contain, 'Full Image'],
  [ObjectFit.cover, 'Stretch if needed'],
]);

export const OBJECT_FIT_LOOKUP = [ObjectFit.contain, ObjectFit.cover].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: ObjectFitMap.get(value) };
});

export enum ObjectPosition {
  center = 'center',
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
}

export const ObjectPositionMap = new Map([
  [ObjectPosition.center, 'Center'],
  [ObjectPosition.top, 'Top'],
  [ObjectPosition.right, 'Right'],
  [ObjectPosition.bottom, 'Bottom'],
  [ObjectPosition.left, 'Left'],
]);

export const OBJECT_POSITION_LOOKUP = [
  ObjectPosition.center,
  ObjectPosition.top,
  ObjectPosition.right,
  ObjectPosition.bottom,
  ObjectPosition.left,
].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: ObjectPositionMap.get(value) };
});
