import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import {
  DxoEditingModule,
  DxoFilterRowModule,
  DxoLoadPanelModule,
  DxoLookupModule,
  DxoScrollingModule,
  DxoSearchPanelModule,
} from 'devextreme-angular/ui/nested';
import { DxDataGridModule, DxTemplateModule } from 'devextreme-angular';
import { SegmentsPipesModule } from '../pipes/segments-pipes.module';
import { PipesModule } from 'ag-common-svc/shared/pipes/pipes.module';
import { NotificationAddSegmentsModalComponent } from './notification-add-segments-modal.component';
import { ModalWindowModule } from '../../../../modal-window/modal-window.module';
import { DynamicSegmentsGroupService } from '../../../../../services/dynamic-segments-group.service';

@NgModule({
  declarations: [NotificationAddSegmentsModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,

    DxoEditingModule,
    DxTemplateModule,
    DxoLookupModule,
    DxoFilterRowModule,
    DxoLoadPanelModule,
    DxoSearchPanelModule,
    DxoScrollingModule,
    DxDataGridModule,
    SegmentsPipesModule,
    ModalWindowModule,
  ],
  exports: [NotificationAddSegmentsModalComponent],
  providers: [DynamicSegmentsGroupService],
})
export class NotificationAddSegmentsModalModule {}
