export enum AdminCrmRoutes {
  TaskListManager = 'task-list-manager',
  ImportRulesManager = 'import-rules-manager',
  LookupsManager = 'lookups-manager',
  ErrorsManager = 'errors-manager',
  LogsManager = 'logs-manager',
  UserManager = 'user-manager',
  UserRolesManager = 'user-roles-manager',
  ReportsManager = 'reports-manager',
  AgentMergeTool = 'agent-merge-tool',
  SalesGoals = 'sales-goals',
  RoleManager = 'role-manager',
  GoogleAuthManagement = 'google-auth-management',
  AuthStats = 'auth-stats',
  AddressesManagement = 'addresses-management',
}
