import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from '../../../../pipes/change-log-pipes.module';
import { DxiColumnModule } from 'devextreme-angular/ui/nested';
import { ChangeLogAirlinePreferencesGridComponent } from './change-log-airline-preferences-grid.component';

@NgModule({
  declarations: [ChangeLogAirlinePreferencesGridComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule, DxiColumnModule],
  exports: [ChangeLogAirlinePreferencesGridComponent],
})
export class ChangeLogAirlinePreferencesGridModule {}
