<ag-shr-modal-window
  #excursionModalRef
  [title]="'Excursion Details'"
  [width]="'50vw'"
  [height]="'590px'"
  [inProgress]="inProgress"
  [useScrollView]="false"
  (onSaveClick)="handleSaveExcursion()"
  [onCloseModal]="handleClosePopup"
  [showSaveButton]="!isReadOnly"
>
  <div *ngIf="excursionModalRef?.popupComponent?.visible ?? false" class="excursion-modal__container">
    <dx-scroll-view
      class="excursion-modal__form-container"
      height="100%"
      direction="vertical"
      [useNative]="false"
      [showScrollbar]="'always'"
    >
      <dx-form [formData]="formData" labelMode="floating" [validationGroup]="validationGroup" [readOnly]="isReadOnly">
        <dxi-item [dataField]="ExcursionKeys.name" [label]="{ text: 'Activity Name' }" [isRequired]="true"></dxi-item>

        <dxi-item
          [dataField]="ExcursionKeys.type"
          [label]="{ text: 'Activity Type' }"
          [isRequired]="true"
          editorType="dxSelectBox"
          [editorOptions]="{
            items: excursionTypeLookup,
            valueExpr: LookupKeys.value,
            displayExpr: LookupKeys.description,
            searchEnabled: true,
            placeholder: '',
          }"
        ></dxi-item>

        <dxi-item
          [dataField]="ExcursionKeys.date"
          [label]="{ text: 'Available Date' }"
          [isRequired]="true"
          editorType="dxDateBox"
          [editorOptions]="dateEditorOptions"
        ></dxi-item>

        <dxi-item
          [dataField]="ExcursionKeys.cost"
          [label]="{ text: 'Cost' }"
          [isRequired]="true"
          editorType="dxNumberBox"
          [editorOptions]="{ format: { type: 'currency', currency: 'USD', precision: 2 }, min: 0 }"
        >
          <dxi-validation-rule
            type="custom"
            [message]="Messages.REQUIRED_TEXT"
            [validationCallback]="costValidator"
          ></dxi-validation-rule>
        </dxi-item>

        <dxi-item
          [dataField]="ExcursionKeys.minAge"
          [label]="{ text: 'Minimum Age' }"
          editorType="dxNumberBox"
          [formatName]=""
          [editorOptions]="{
            min: 1,
            format: '#',
          }"
        ></dxi-item>

        <dxi-item [dataField]="ExcursionKeys.restrictions" [label]="{ text: 'Restrictions Info' }"></dxi-item>

        <dxi-item [dataField]="ExcursionKeys.capacity" [label]="{ text: 'Activity Capacity' }"></dxi-item>

        <dxi-item
          [dataField]="ExcursionKeys.isExactTime"
          editorType="dxCheckBox"
          [label]="{ text: 'Exact time?' }"
          cssClass="form-items__checkbox--label-right"
        ></dxi-item>

        <dxi-item itemType="group" [visible]="formData?.[ExcursionKeys.isExactTime]">
          <dxi-item
            [dataField]="ExcursionKeys.departureTime"
            [label]="{ text: 'Departure Time' }"
            [isRequired]="true"
            editorType="dxDateBox"
            [editorOptions]="timeEditorOptions"
          >
          </dxi-item>

          <dxi-item
            [dataField]="ExcursionKeys.returnTime"
            [label]="{ text: 'Return Time' }"
            [isRequired]="true"
            editorType="dxDateBox"
            [editorOptions]="timeEditorOptions"
          >
          </dxi-item>
        </dxi-item>
      </dx-form>
    </dx-scroll-view>

    <div class="excursion-modal__aside-container">
      <dx-validation-summary [validationGroup]="excursionDescriptionValidationGroup"></dx-validation-summary>

      <dx-tab-panel [dataSource]="tabsDataSource" width="100%" height="100%" [showNavButtons]="true">
        <div *dxTemplate="let details of 'detailsItemTmp'" class="p-2">
          <shr-html-editor
            [name]="ExcursionKeys.description"
            class="excursion-modal__html-editor"
            [isRequired]="true"
            [validationGroup]="excursionDescriptionValidationGroup"
            [value]="formData?.[ExcursionKeys.description]"
            (valueChange)="formData && (formData[ExcursionKeys.description] = $event)"
            [label]="'Details'"
            [fileDirectory]="'conference-excursion-description'"
            [isReadOnly]="isReadOnly"
          ></shr-html-editor>
        </div>

        <div *dxTemplate="let details of 'shortDescriptionItemTmp'" class="p-2">
          <shr-html-editor
            [name]="ExcursionKeys.shortDescription"
            class="excursion-modal__html-editor"
            [isRequired]="true"
            [validationGroup]="excursionDescriptionValidationGroup"
            [value]="formData?.[ExcursionKeys.shortDescription]"
            (valueChange)="formData && (formData[ExcursionKeys.shortDescription] = $event)"
            [label]="'Short Description'"
            [fileDirectory]="'conference-excursion-description'"
            [isReadOnly]="isReadOnly"
            [toolbarItemType]="[HtmlEditorTypeKeys.textDecoration, HtmlEditorTypeKeys.list, HtmlEditorTypeKeys.link]"
          >
          </shr-html-editor>
        </div>

        <div *dxTemplate="let details of 'optionsItemTmp'" class="p-2">
          <dx-data-grid
            [dataSource]="formData?.[ExcursionKeys.preferences]"
            [showRowLines]="true"
            [rowAlternationEnabled]="true"
            (onInitNewRow)="onInitNewExcursionPreference($event)"
            (onRowInserting)="onExcursionInsertingPreference($event)"
            (onRowUpdating)="onExcursionUpdatingPreference($event)"
            (onRowRemoving)="onExcursionRemovingPreference($event)"
            height="100%"
          >
            <dxo-editing
              #excursionPreferencesEditingTmp
              mode="row"
              [useIcons]="true"
              [allowUpdating]="!isReadOnly"
              [allowDeleting]="!isReadOnly"
              [allowAdding]="!isReadOnly"
              [newRowPosition]="'last'"
            >
            </dxo-editing>

            <dxo-toolbar>
              <dxi-item location="before">
                <b *dxTemplate>Lookups</b>
              </dxi-item>

              <dxi-item name="addRowButton"></dxi-item>
            </dxo-toolbar>

            <dxo-master-detail [enabled]="false" template="detail" [autoExpandAll]="true"> </dxo-master-detail>

            <dxi-column [dataField]="ExcursionPreferenceKeys.label" caption="Label">
              <dxi-validation-rule type="required"></dxi-validation-rule>

              <dxi-validation-rule
                type="pattern"
                [pattern]="safeFirebaseFieldNameRegExp"
                message="Wrong format. Only use alphanumeric characters, hyphens, and underscores."
              ></dxi-validation-rule>

              <dxi-validation-rule
                type="custom"
                [validationCallback]="checkIsExcursionPreferenceUniq.bind(this, excursionPreferencesEditingTmp)"
                message="Same Label already exist."
              ></dxi-validation-rule>
            </dxi-column>

            <dxi-column [dataField]="ExcursionPreferenceKeys.instructions" caption="Instructions">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <dxi-column [dataField]="ExcursionPreferenceKeys.selectionType" caption="Selection Type">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <div *dxTemplate="let details of 'detail'">
              <dx-data-grid
                class="p-1"
                *var="details?.data?.[ExcursionPreferenceKeys.items] as items"
                [dataSource]="items"
                [showRowLines]="true"
                [rowAlternationEnabled]="true"
                (onRowInserting)="onExcursionInsertingPreferenceOption(details, $event)"
                (onRowUpdating)="onExcursionUpdatingPreferenceOption(details, $event)"
                (onRowRemoving)="onExcursionRemovingPreferenceOption(details, $event)"
                height="100%"
              >
                <dxo-editing
                  #excursionPreferencesItemsEditingTmp
                  mode="row"
                  [useIcons]="true"
                  [allowUpdating]="!isReadOnly"
                  [allowDeleting]="!isReadOnly"
                  [allowAdding]="!isReadOnly"
                  [newRowPosition]="'last'"
                >
                </dxo-editing>

                <dxi-column [dataField]="ExcursionOptionKey.name" caption="Name" editCellTemplate="">
                  <dxi-validation-rule type="required"></dxi-validation-rule>

                  <dxi-validation-rule
                    type="custom"
                    [validationCallback]="
                      checkIsExcursionPreferenceOptionUniq.bind(this, excursionPreferencesItemsEditingTmp, items)
                    "
                    message="Same Option Name already exist."
                  ></dxi-validation-rule>
                </dxi-column>

                <dxi-column
                  [dataField]="ExcursionOptionKey.cost"
                  caption="Cost"
                  [dataType]="'number'"
                  [format]="DX_USD_CURRENCY_FORMAT"
                  [editorOptions]="{ format: { type: 'currency', currency: 'USD', precision: 2 }, min: 0 }"
                ></dxi-column>
              </dx-data-grid>
            </div>
          </dx-data-grid>
        </div>

        <div *dxTemplate="let details of 'configurationItemTmp'" class="p-2">
          <ag-crm-excursion-configuration-grid
            [formData]="formData"
            [onSaveConfigurations]="onSaveConfiguration"
            [isReadOnly]="isReadOnly"
          ></ag-crm-excursion-configuration-grid>
        </div>
      </dx-tab-panel>
    </div>
  </div>
</ag-shr-modal-window>
