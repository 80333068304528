import { showPager } from 'ag-common-svc/lib/utils/data-grid.utils';
import { Pipe, PipeTransform } from '@angular/core';
import DxDataGrid from 'devextreme/ui/data_grid';

@Pipe({
  name: 'showPager',
})
export class ShowPagerPipe implements PipeTransform {
  transform(gridInstance: DxDataGrid<any, any>): boolean {
    if (!gridInstance) {
      return false;
    }
    return showPager(gridInstance);
  }
}
