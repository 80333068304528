import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentMediaComponent } from './agent-media.component';
import { AgMediaGalleryModule } from '../ag-media-gallery/ag-media-gallery.module';
import { AgentMediaComponentService } from './agent-media.service';
import { DxButtonModule, DxMenuModule, DxTextBoxModule, DxTreeViewModule } from 'devextreme-angular';
import { ButtonWithIndicatorModule } from '../../components';
import { AgMediaUploaderModalModule } from '../ag-media-uploader/ag-media-uploader-modal.module';
import { PipesModule } from '../../../shared/pipes/pipes.module';
import { AgentImagesService } from '../../services/agent-images.service';

@NgModule({
  declarations: [AgentMediaComponent],
  exports: [AgentMediaComponent],
  imports: [
    CommonModule,
    AgMediaGalleryModule,
    DxTreeViewModule,
    DxMenuModule,
    ButtonWithIndicatorModule,
    AgMediaUploaderModalModule,
    DxButtonModule,
    DxTextBoxModule,
    PipesModule,
  ],
  providers: [AgentMediaComponentService, AgentImagesService],
})
export class AgentMediaModule {}
