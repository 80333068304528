<dx-select-box
  [dataSource]="suffixesLookup$ | async"
  [(value)]="value"
  (valueChange)="valueChange.emit($event)"
  (selectedItemChange)="selectedItemChange.emit($event)"
  [readOnly]="readOnly"
  [disabled]="disabled"
  [label]="label"
  [labelMode]="labelMode"
  [placeholder]="placeholder"
  [displayExpr]="LookupKeys.description"
  [valueExpr]="BaseModelKeys.dbId"
  [searchEnabled]="true"
  [showClearButton]="showClearButton"
  [showDropDownButton]="showDropDownButton"
></dx-select-box>
