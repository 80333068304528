import { FileDirectory, SystemFileDirectories } from 'ag-common-lib/lib/models/utils/file-directory.model';

export enum AgentFileDirectoryCollections {
  files = 'files',
}

export enum AgentFileDirectoryKeys {
  agentDbId = 'agentDbId',
}

export class AgentFileDirectory extends FileDirectory {
  [AgentFileDirectoryKeys.agentDbId]: string;
}

export const AGENT_SYSTEM_DIRECTORY_NAMES: string[] = [SystemFileDirectories.Images, SystemFileDirectories.Documents];
