import { RoleEntityPermissionMap, EntityPermissionActivityKeys, Entity } from '@ag-common-lib/public-api';

export const hasPermissionGuard = (entities: Entity[], permissionMap: RoleEntityPermissionMap): boolean => {
  if (!entities || !entities?.length) {
    return false;
  }

  return entities.some(entity =>
    [EntityPermissionActivityKeys.read].some(activity => permissionMap?.[entity]?.[activity]),
  );
};
