import {
  IndexProperties,
  IndexPropertiesMap,
  MappingNestedProperty,
  ScriptSort,
  SearchRequest,
  SearchTotalHits,
  Sort,
  SupportedCollectionsKeys,
} from '@ag-common-lib/public-api';
import { GroupDescriptor, LoadOptions, SearchOperation } from 'devextreme/data';
import { CloudFunctionsService } from '../cloud-functions.service';
import {
  BehaviorSubject,
  combineLatest,
  lastValueFrom,
  map,
  Observable,
  take as rxJsTake,
  filter as rxJsFilter,
} from 'rxjs';
import {
  AggregationsAggregationContainer,
  AggregationsCompositeAggregationSource,
  SearchHit,
  SearchHitsMetadata,
  SearchResponse,
} from '@elastic/elasticsearch/lib/api/types';
import { HttpsCallableResult } from 'firebase/functions';

export enum DxFilterOperators {
  equal = '=',
  doNotEqual = '<>',
  more = '>',
  moreOrEqual = '>=',
  less = '<',
  lessOrEqual = '<=',
  between = 'between',
  startsWith = 'startswith',
  endsWith = 'endswith',
  contains = 'contains',
  notContains = 'notcontains',
  or = 'or',
  and = 'and',
  anyof = 'anyof',
}

export enum QueryOperation {
  must = 'must',
  should = 'should',
  mustNot = 'must_not',
}

function getGroupSelector<T = any>(groupDescriptor: GroupDescriptor<T>): string {
  if (typeof groupDescriptor === 'string') {
    return groupDescriptor;
  }
  if (typeof groupDescriptor === 'function') {
    return '';
  }

  if (typeof groupDescriptor?.selector === 'string') {
    return groupDescriptor?.selector;
  }

  if (typeof groupDescriptor?.selector === 'function') {
    return (groupDescriptor?.selector as any)(null, { groupSelector: true }) as string;
  }

  return '';
}

export class BaseElasticSearchService<T> {
  private isRuntimeMappingsFetched$ = new BehaviorSubject(false);
  private isInitializing$ = combineLatest([this.isRuntimeMappingsFetched$]).pipe(
    map(loads => {
      return loads.some(Boolean);
    }),
  );

  protected readonly defaultIndex: string;
  protected additionalFields: string[] = [];
  protected defaultSorting: Sort = ['_doc'];
  protected cloudFunctionsService: CloudFunctionsService;
  private indexProperties: IndexProperties;
  private readonly runtimeMappings: Map<string, { source: string; params: { [key: string]: string } }> = new Map();
  protected runtimeMappings$: Observable<{ [key: string]: { source: string; params: { [key: string]: string } } }>;

  constructor(index: string, collection: SupportedCollectionsKeys) {
    this.defaultIndex = `search-${index}`;

    this.indexProperties = IndexPropertiesMap.get(collection);
  }

  protected getAggregations: (loadOptions: LoadOptions) => Record<string, AggregationsAggregationContainer>;

  convertFilter(filter: any[]): any {
    const queries: any = {
      must: [],
      should: [],
      mustNot: [],
    };

    const firstOperand = filter[0];
    const filterLength = filter?.length;

    if (firstOperand === '!') {
      const bool = this.convertFilter(filter[1]);
      return {
        bool: {
          must_not: [bool],
        },
      };
    }

    if (typeof firstOperand === 'string') {
      const mappingProperty = this.indexProperties[firstOperand];
      const selectedFilterOperations = filterLength === 3 ? filter[1] : DxFilterOperators.equal;
      const secondOperand = filter[filterLength - 1];
      const query =
        mappingProperty?.type === 'nested'
          ? this.getNestedQuery(firstOperand, mappingProperty, secondOperand, selectedFilterOperations)
          : this.getQuery(firstOperand, secondOperand, selectedFilterOperations);

      switch (selectedFilterOperations) {
        case DxFilterOperators.notContains:
        case DxFilterOperators.doNotEqual:
          return { bool: { must_not: [query] } };
        default:
          return query;
      }
    }

    const operation = filter.find(item => typeof item === 'string') ?? 'and';
    filter.forEach(item => {
      if (!Array.isArray(item)) return;
      const query = this.convertFilter(item);

      switch (operation) {
        case 'or':
          queries.should.push(query);
          break;
        case 'and':
          queries.must.push(query);
          break;
      }
    });

    // Final Output Optimization
    const boolQuery: any = {};
    if (queries.must.length) boolQuery.must = queries.must;
    if (queries.should.length) boolQuery.should = queries.should;
    if (queries.mustNot.length) boolQuery.must_not = queries.mustNot;

    return { bool: boolQuery };
  }

  async getByIds(ids: string[]): Promise<Array<T | null>> {
    if (!ids || !ids?.length) {
      return [];
    }
    const payload: SearchRequest = {
      index: this.defaultIndex,
      filter_path: ['hits.hits._source'],
      query: {
        ids: {
          values: ids,
        },
      },
    };

    const response = await this.cloudFunctionsService.searchWithElastic(payload);

    return response?.data?.hits?.hits?.map(hit => {
      return hit?._source as T;
    });
  }

  protected async getById(id: string): Promise<T | null> {
    if (!id) {
      return null;
    }
    const payload: SearchRequest = {
      index: this.defaultIndex,
      filter_path: ['hits.hits._source'],
      query: {
        term: {
          _id: id,
        },
      },
    };

    const response = await this.cloudFunctionsService.searchWithElastic(payload);

    return (response?.data?.hits?.hits?.[0]?._source as T) ?? null;
  }

  protected normalizeLoadOption = async (param: LoadOptions): Promise<SearchRequest> => {
    await lastValueFrom(
      this.isInitializing$.pipe(
        rxJsFilter(isInitializing => !isInitializing),
        rxJsTake(1),
      ),
    );

    const { sort, take, filter, group, totalSummary, searchExpr, searchOperation, searchValue, userData } = param;

    const payload: SearchRequest = {
      index: this.defaultIndex,
      track_total_hits: true,
      size: take ?? 20,
      fields: ['mga_name'],
      from: param.skip ?? 0,
      sort: this.defaultSorting,
      aggregations: {},
      runtime_mappings: {},
    };

    this.runtimeMappings.forEach((mapping, field) => {
      payload.runtime_mappings[field] = {
        type: 'keyword',
        script: {
          source: mapping.source,
          params: mapping.params,
        },
      };
      payload.fields.push(field);
    });

    if (totalSummary) {
      const summaryAggregation = this.getSummaryAggregation(totalSummary);
      Object.assign(payload.aggregations, summaryAggregation);
    }

    if (sort || group) {
      const normalizedSort = [];
      const groupSortRules = Array.isArray(group) ? group : [group].filter(Boolean);
      for (const sortRule of groupSortRules) {
        const normalizedRule = await this.normalizeSort(sortRule);
        normalizedSort.push(...normalizedRule?.flat(1));
      }
      const sortRules = Array.isArray(sort) ? sort : [sort].filter(Boolean);
      for (const sortRule of sortRules) {
        const normalizedRule = await this.normalizeSort(sortRule);
        normalizedSort.push(...normalizedRule?.flat(1));
      }
      normalizedSort.push('_doc');
      Object.assign(payload, { sort: normalizedSort });
    }

    if (filter?.length) {
      const query = this.convertFilter(filter);

      Object.assign(payload, {
        query,
      });
    }

    if (searchExpr && searchValue) {
      Object.assign(payload, this.buildSearchQuery(searchExpr, searchOperation, searchValue));
    }

    return payload;
  };

  getFromElastic = async (
    param: LoadOptions,
  ): Promise<{
    data: any;
    groupCount?: number;
    totalCount?: number;
    aggregations?: any;
    searchAfter?: any;
  }> => {
    const payload = await this.normalizeLoadOption(param);

    const { group, userData } = param;

    if (userData?.withAggregations && this.getAggregations) {
      payload.aggregations = this.getAggregations(param);
    }

    if (userData?.aggregationsOnly) {
      payload.size = 0;
    }

    if (userData && 'source' in userData) {
      payload._source = userData.source;
    }

    if (group && Array.isArray(group)) {
      return this.getGropedData(payload, group, param);
    }

    if ((param as any)?.isLoadingAll || userData?.isLoadingAll) {
      return this.getAllData(payload);
    }

    const response = await this.getData(payload);

    return response;
  };

  private async getGropedData(payload: SearchRequest, group: GroupDescriptor<T>[], param: LoadOptions) {
    const { take, groupSummary, userData } = param;
    const isLoadingAll = userData?.isLoadingAll ?? false;
    const aggregations: Record<string, AggregationsAggregationContainer> = {};
    const compositeAggregationSources: Record<string, AggregationsCompositeAggregationSource>[] = [];
    const innerAggregation: Record<string, AggregationsAggregationContainer> = {};

    aggregations.groups = {
      composite: {
        size: isLoadingAll ? 500 : (take ?? 20),
        sources: compositeAggregationSources,
      },
      aggregations: innerAggregation,
    };

    let groupAggregations: Record<string, AggregationsAggregationContainer> = aggregations;

    for (const groupDescriptor of group) {
      const selector = getGroupSelector(groupDescriptor);

      groupAggregations.count = {
        cardinality: {
          field: selector,
        },
      };

      const summaryAggregation = this.getSummaryAggregation(groupSummary);

      Object.assign(innerAggregation, summaryAggregation);

      groupAggregations = innerAggregation;

      compositeAggregationSources.push({
        [selector]: {
          terms: {
            field: selector,
            order: (groupDescriptor as any)?.desc ? 'desc' : 'asc',
            missing_bucket: true,
            missing_order: 'default',
          },
        },
      });
    }

    Object.assign(payload.aggregations, aggregations);
    payload.size = 0;

    let searchAfter = userData?.searchAfter;

    const data = [];
    const results = {
      summary: undefined,
      groupCount: 0,
      totalCount: 0,
    };

    const getItems = async () => {
      const params: SearchRequest = Object.assign(payload, { size: 0 });

      if (searchAfter) {
        params.aggregations.groups.composite.after = searchAfter;
      }

      const response = await this.getData(params);

      results.groupCount = response?.groupCount;
      results.totalCount = response?.totalCount;

      if (!response || !response?.data?.length) {
        return;
      }
      searchAfter = response?.lastHit;

      data.push(...response?.data);

      if (response?.summary) {
        results.summary = response?.summary;
      }

      if (!isLoadingAll) {
        return;
      }

      return getItems();
    };

    await getItems();

    if (userData?.treeGroups) {
      const groupedData = this.groupResponse(data, group);

      return Object.assign({}, results, { data: groupedData, searchAfter });
    }

    return Object.assign({}, results, { data, searchAfter });
  }

  groupResponse(response, groupDescriptor: GroupDescriptor<T>[]) {
    function customGroupByOrdered(data, keySelector) {
      const groups = [];
      const groupMap = new Map();

      for (const item of data) {
        const key = keySelector(item);
        if (!groupMap.has(key)) {
          const group = { key, values: [] };
          groupMap.set(key, group);
          groups.push(group); // Maintain order based on first occurrence
        }
        groupMap.get(key).values.push(item);
      }

      return groups; // Always returns an array of grouped items
    }

    function groupByKeys(data, keys: GroupDescriptor<T>[]) {
      if (!keys.length) {
        return data; // Base case: return flat data if no more grouping descriptors
      }

      const [currentGroupDescriptor, ...remainingGroupDescriptors] = keys;
      const isLast = !remainingGroupDescriptors.length;
      const currentSelector = getGroupSelector(currentGroupDescriptor);

      const grouped = customGroupByOrdered(data, item => item.key[currentSelector]);

      return grouped.map(({ key, values }) => {
        const { count, summary } = values.reduce(
          (acc, item) => {
            const itemCount = item?.count || 0;
            const itemSummary = item?.summary || [];
            const accSummary = acc.summary || [];
            const maxLength = Math.max(itemSummary.length, accSummary.length);

            const updatedSummary = new Array(maxLength).fill(0).map((_, index) => {
              return (itemSummary[index] || 0) + (accSummary[index] || 0);
            });

            acc.count += itemCount;
            acc.summary = updatedSummary;

            return acc;
          },
          { count: 0, summary: [] },
        );

        const items = groupByKeys(values, remainingGroupDescriptors);

        return {
          key,
          count,
          summary,
          items: isLast ? null : items, // Ensure the nested structure or null at the last grouping
        };
      });
    }

    return groupByKeys(response, groupDescriptor);
  }

  protected async getAllData(payload: SearchRequest) {
    let searchAfter;
    const data = [];

    const getItems = async () => {
      const params: SearchRequest = Object.assign(payload, { size: 500 });

      if (data?.length && searchAfter) {
        Object.assign(params, { search_after: searchAfter, from: 0 });
      }

      const response = await this.getData(params);

      if (!response || !response?.data?.length) {
        return;
      }
      searchAfter = response?.lastHit?.sort;
      data.push(...response.data);

      return getItems();
    };

    await getItems();

    return { data, totalCount: data?.length };
  }

  protected async getData(payload: SearchRequest): Promise<any> {
    const response: HttpsCallableResult<SearchResponse> = await this.cloudFunctionsService.searchWithElastic(payload);
    const totalCount = (response.data.hits.total as SearchTotalHits)?.value;
    const aggregations: any = response?.data?.aggregations;
    const groupCount = aggregations?.count?.value ?? null;
    const groups = aggregations?.groups;
    const isGroupsRequest = !!groups;
    const hits = response?.data?.hits?.hits ?? [];
    const hitsSearchAfter = hits?.map(hit => hit?.sort);
    // console.log('hitsSearchAfter', hitsSearchAfter);
    const lastHit = isGroupsRequest ? groups?.after_key : hits[hits?.length - 1];
    const data = isGroupsRequest ? this.getGroupData(groups?.buckets) : this.getFlatData(hits);
    const summary = Object.keys(aggregations ?? {})
      .filter(key => key.match(/^summary_\d{1,}$/))
      .sort()
      .map(key => aggregations?.[key]?.value);

    return { data, totalCount, summary, groupCount, lastHit, aggregations: response?.data?.aggregations };
  }

  private getGroupData = (buckets: any[]) => {
    const data = [];

    buckets?.forEach(bucket => {
      const bucketKey = bucket?.key;
      const summary = Object.keys(bucket ?? {})
        .filter(key => key.match(/^summary_\d{1,}$/))
        .sort()
        .map(key => bucket?.[key]?.value);

      // const result = this.normalizeResponseBody(bucketItems.hits);

      const group = {
        key: bucketKey,
        summary,
        count: bucket?.doc_count,
        items: null, //result?.data ?? null,
      };

      data.push(group);
    });

    return data;
  };

  private getSummaryAggregation = summary => {
    const aggregation: Record<string, AggregationsAggregationContainer> = {};
    (Array.isArray(summary) ? summary : [summary]).filter(Boolean).forEach((summary: any, index) => {
      const selector = summary?.selector;
      const summaryType = summary?.summaryType;

      // 'sum' | 'avg' | 'min' | 'max' | 'count';
      switch (summaryType) {
        case 'sum':
          aggregation[`summary_${index}`] = {
            sum: { field: selector },
            meta: { index },
          };
          break;
        case 'avg':
          aggregation[`summary_${index}`] = {
            avg: { field: selector },
            meta: { index },
          };
          break;
        case 'min':
          aggregation[`summary_${index}`] = {
            min: { field: selector },
            meta: { index },
          };
          break;
        case 'max':
          aggregation[`summary_${index}`] = {
            max: { field: selector },
            meta: { index },
          };
          break;
        case 'count':
          aggregation[`summary_${index}`] = {
            value_count: { field: selector },
            meta: { index },
          };
          break;
        case 'topHits':
          aggregation[`topHits_${index}`] = {
            top_hits: { size: summary?.size ?? 1, _source: summary?.source ?? true },
          };
          break;
      }
    });

    return aggregation;
  };

  private getFlatData = (hits: SearchHit[]) => {
    const data = [];

    hits?.forEach(hit => {
      const item = Object.assign({ dbId: hit?._id }, hit?._source);

      Object.entries(hit?.fields ?? {}).forEach(([fieldName, value]) => {
        item[fieldName] = value;
      });

      data.push(item);
    });

    return data;
  };

  protected normalizeResponseBody = (hitsMetadata: SearchHitsMetadata) => {
    const data = [];
    const total = hitsMetadata?.total;
    const totalCount = typeof total === 'number' ? total : total?.value;
    const hits = hitsMetadata?.hits;
    const lastHit = hits[hits?.length - 1];

    hits?.forEach(hit => {
      data.push(hit?._source);
    });

    return { data, lastHit, totalCount };
  };

  private getQuery(dataField: string, value, selectedFilterOperations: DxFilterOperators) {
    switch (selectedFilterOperations) {
      case DxFilterOperators.equal:
      case DxFilterOperators.doNotEqual:
        return this.getEqualQuery(dataField, value);
      case DxFilterOperators.startsWith:
        return this.getStartsWithQuery(dataField, value);
      case DxFilterOperators.endsWith:
        return this.getEndWithQuery(dataField, value);
      case DxFilterOperators.more:
        return this.getMoreQuery(dataField, value);
      case DxFilterOperators.moreOrEqual:
        return this.getMoreOrEqualQuery(dataField, value);
      case DxFilterOperators.less:
        return this.getLessQuery(dataField, value);
      case DxFilterOperators.lessOrEqual:
        return this.getLessOrEqualQuery(dataField, value);
      case DxFilterOperators.between:
        return this.getBetweenQuery(dataField, value);
      case DxFilterOperators.anyof:
        return this.getAnyOfQuery(dataField, value);
      case DxFilterOperators.contains:
      case DxFilterOperators.notContains:
      default:
        return this.getContainsQuery(dataField, value);
    }
  }

  protected normalizeSort = async sortDescriptor => {
    let selector: string;

    if (typeof sortDescriptor === 'string') {
      selector = sortDescriptor;
    }

    if (typeof sortDescriptor?.selector === 'string') {
      selector = sortDescriptor?.selector;
    }

    if (typeof sortDescriptor?.selector === 'function') {
      selector = sortDescriptor?.selector(null, { sortSelector: true });
    }

    const desc = typeof sortDescriptor === 'string' ? false : sortDescriptor.desc;

    const mappingProperty = this.indexProperties[selector];
    const mappingType = mappingProperty?.type ?? 'keyword';

    if (mappingType === 'nested') {
      const sortRules = [];
      Object.entries((mappingProperty as any)?.properties ?? {}).forEach(([key, nestedMappingProperty]) => {
        if ((nestedMappingProperty as any)?.type === 'keyword') {
          sortRules.push({
            [`${selector}.${key}`]: {
              mode: 'max',
              order: desc ? 'desc' : 'asc',

              nested: {
                path: selector,
              },
            },
          });
        }
      });

      return sortRules;
    }

    if (mappingType === 'boolean') {
      return [this.getBooleanFieldsSortScript(selector, desc)];
    }

    if (mappingType === 'double') {
      return [
        {
          [selector]: {
            order: desc ? 'desc' : 'asc',
            unmapped_type: 'double',
            numeric_type: 'double',
          },
        },
      ];
    }

    if (new Set(['keyword', 'date', 'integer']).has(mappingType)) {
      return [
        {
          [selector]: { order: desc ? 'desc' : 'asc', unmapped_type: mappingType },
        },
      ];
    }

    return [];
  };

  protected getBooleanFieldsSortScript = (selector, desc): { _script: ScriptSort } => {
    return {
      _script: {
        type: 'string',
        script: {
          lang: 'painless',
          source: `
            if (doc['${selector}'].empty || doc['${selector}'].value == null || doc['${selector}'].value == false) {
              return 'No';
            }

            return 'Yes';
          `,
        },
        order: desc ? 'desc' : 'asc',
      },
    };
  };

  protected buildSearchQuery = (
    searchExpr?: string | Function | Array<string | Function>,
    searchOperation?: SearchOperation,
    searchValue?: any,
  ) => {
    const should = [];

    const expressions: string[] = [];

    if (typeof searchExpr === 'string') {
      expressions.push(searchExpr);
    }

    if (Array.isArray(searchExpr)) {
      searchExpr.forEach(expr => {
        if (typeof expr === 'string') {
          expressions.push(expr);
        }
      });
    }

    expressions.forEach(expr => {
      if (searchOperation === 'contains') {
        should.push(this.getContainsQuery(expr, searchValue));
      }
      if (searchOperation === 'startswith') {
        should.push(this.getStartsWithQuery(expr, searchValue));
      }
    });
    const payload: SearchRequest = {
      query: {
        bool: {
          should,
        },
      },
    };

    return payload;
  };

  protected getEqualQuery = (dataField: string, value) => {
    if (value === null || value === undefined) {
      return {
        bool: {
          must_not: [
            {
              exists: {
                field: dataField,
              },
            },
          ],
        },
      };
    }

    return {
      match: {
        [dataField]: value,
      },
    };
  };

  protected getContainsQuery = (dataField: string, value) => {
    // Split the value into parts
    const parts = value.trim().split(' ');

    // Create wildcard queries for each part of the value
    const wildcardQueries = parts.map(part => ({
      wildcard: {
        [dataField]: {
          value: `*${part}*`,
          case_insensitive: true,
        },
      },
    }));

    // Combine the wildcard queries using a bool filter with 'must' condition
    return {
      bool: {
        must: wildcardQueries,
      },
    };
  };

  protected getStartsWithQuery = (dataField: string, value) => {
    return {
      wildcard: {
        [dataField]: {
          value: `${value}*`,
          case_insensitive: true,
        },
      },
    };
  };

  protected getEndWithQuery = (dataField: string, value) => {
    return {
      wildcard: {
        [dataField]: {
          value: `*${value}`,
          case_insensitive: true,
        },
      },
    };
  };

  protected getMoreQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { gt: value },
      },
    };
  };

  protected getMoreOrEqualQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { gte: value },
      },
    };
  };

  protected getLessQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { lt: value },
      },
    };
  };

  protected getLessOrEqualQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { lte: value },
      },
    };
  };

  protected getBetweenQuery = (dataField: string, value) => {
    return {
      range: {
        [dataField]: { gte: value[0], lte: value[1] },
      },
    };
  };

  protected getAnyOfQuery = (dataField: string, value) => {
    return {
      terms: {
        [dataField]: value,
      },
    };
  };

  protected getNestedQuery = (
    parentDataField: string,
    mappingNestedProperty: MappingNestedProperty,
    value,
    selectedFilterOperations: DxFilterOperators,
  ) => {
    const should = [];
    Object.entries(mappingNestedProperty.properties).forEach(([key, nestedMappingProperty]) => {
      if (nestedMappingProperty.type === 'keyword') {
        should.push(this.getQuery(`${parentDataField}.${key}`, value, selectedFilterOperations));
      }
    });

    return {
      nested: {
        path: parentDataField,
        query: {
          bool: {
            should,
          },
        },
      },
    };
  };

  protected resolveRuntimeMappings = () => {
    this.isRuntimeMappingsFetched$.next(true);
    this.runtimeMappings$.subscribe(mappings => {
      Object.entries(mappings ?? {}).forEach(([key, mapping]) => {
        this.runtimeMappings.set(key, mapping);
      });

      this.isRuntimeMappingsFetched$.next(false);
    });
  };
}
