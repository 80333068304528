export enum HtmlEditorTypeKeys {
  header = 'header',
  insert = 'insert',
  font = 'font',
  fontStyle = 'fontStyle',
  textDecoration = 'textDecoration',
  paragraph = 'paragraph',
  list = 'list',
  image = 'image',
  link = 'link',
  variable = 'variable',
  preview = 'preview',
}

export const FONT_HTML_EDITOR_ITEMS = [
  { name: 'size', acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'] },
  {
    name: 'font',
    acceptedValues: [
      'Arial',
      'Courier New',
      'Georgia',
      'Impact',
      'Lucida Console',
      'Tahoma',
      'Times New Roman',
      'Verdana',
    ],
  },
  { name: 'separator' },
];

export const TEXT_DECORATION_HTML_EDITOR_ITEMS = [
  { name: 'bold' },
  { name: 'italic' },
  { name: 'strike' },
  { name: 'underline', locateInMenu: 'auto' },
  { name: 'separator', locateInMenu: 'auto' },
];

export const TEXT_COLOR_STYLE_HTML_EDITOR_ITEMS = [
  { name: 'color', locateInMenu: 'auto' },
  { name: 'background', locateInMenu: 'auto' },
  { name: 'separator' },
];

export const FONT_STYLE_HTML_EDITOR_ITEMS = [
    ...TEXT_DECORATION_HTML_EDITOR_ITEMS,
    ...TEXT_COLOR_STYLE_HTML_EDITOR_ITEMS
];

export const PARAGRAPH_STYLE_HTML_EDITOR_ITEMS = [
  { name: 'alignLeft', locateInMenu: 'auto' },
  { name: 'alignCenter', locateInMenu: 'auto' },
  { name: 'alignRight', locateInMenu: 'auto' },
  { name: 'alignJustify', locateInMenu: 'auto' },
  { name: 'separator' },
  // { name: 'orderedList', locateInMenu: 'auto' },
  // { name: 'bulletList', locateInMenu: 'auto' },
  // { name: 'separator' },
];

export const LIST_STYLE_HTML_EDITOR_ITEMS = [
  { name: 'orderedList', locateInMenu: 'auto' },
  { name: 'bulletList', locateInMenu: 'auto' },
  { name: 'separator' },
];

export const HEADER_STYLE_HTML_EDITOR_ITEMS = [
  { name: 'header', acceptedValues: [false, 1, 2, 3, 4, 5], locateInMenu: 'auto' },
  { name: 'separator' },
];

export const LINK_STYLE_HTML_EDITOR_ITEMS = [{ name: 'link', locateInMenu: 'auto' }];
export const IMAGE_STYLE_HTML_EDITOR_ITEMS = [{ name: 'image', locateInMenu: 'auto' }];
export const VARIABLE_STYLE_HTML_EDITOR_ITEMS = [{ name: 'variable', locateInMenu: 'auto' }];

export const INSERT_STYLE_HTML_EDITOR_ITEMS = [...LINK_STYLE_HTML_EDITOR_ITEMS, ...IMAGE_STYLE_HTML_EDITOR_ITEMS];

export const HTML_EDITOR_ITEMS = [
  ...FONT_HTML_EDITOR_ITEMS,
  ...FONT_STYLE_HTML_EDITOR_ITEMS,
  ...PARAGRAPH_STYLE_HTML_EDITOR_ITEMS,
  ...LIST_STYLE_HTML_EDITOR_ITEMS,
  ...HEADER_STYLE_HTML_EDITOR_ITEMS,
  ...INSERT_STYLE_HTML_EDITOR_ITEMS,
];
