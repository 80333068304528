import { IAspectRatio, LookupKeys } from '@ag-common-lib/public-api';

export enum AspectRatio {
  None = 'initial',
  // Horizontal Aspect Ratios
  Square_1_1 = '1 / 1',
  Standard_4_3 = '4 / 3',
  Widescreen_16_9 = '16 / 9',
  Classic_3_2 = '3 / 2',
  Traditional_5_4 = '5 / 4',
  Cinemascope_21_9 = '21 / 9',
  Anamorphic_2_35_1 = '2.35 / 1',

  // Vertical Aspect Ratios
  VerticalWidescreen_9_16 = '9 / 16',
  VerticalClassic_2_3 = '2 / 3',
  VerticalStandard_3_4 = '3 / 4',
  VerticalTraditional_4_5 = '4 / 5',
  VerticalTall_1_2 = '1 / 2',
}

export const AspectRatioMap = new Map<AspectRatio, string>([
  [AspectRatio.None, 'None'],
  // Horizontal Aspect Ratios
  [AspectRatio.Square_1_1, 'Square (1:1)'],
  [AspectRatio.Standard_4_3, 'Standard (4:3)'],
  [AspectRatio.Widescreen_16_9, 'Widescreen (16:9)'],
  [AspectRatio.Classic_3_2, 'Classic (3:2)'],
  [AspectRatio.Traditional_5_4, 'Traditional (5:4)'],
  [AspectRatio.Cinemascope_21_9, 'Cinemascope (21:9)'],
  [AspectRatio.Anamorphic_2_35_1, 'Anamorphic (2.35:1)'],

  // Vertical Aspect Ratios
  [AspectRatio.VerticalWidescreen_9_16, 'Vertical Widescreen (9:16)'],
  [AspectRatio.VerticalClassic_2_3, 'Vertical Classic (2:3)'],
  [AspectRatio.VerticalStandard_3_4, 'Vertical Standard (3:4)'],
  [AspectRatio.VerticalTraditional_4_5, 'Vertical Traditional (4:5)'],
  [AspectRatio.VerticalTall_1_2, 'Vertical Tall (1:2)'],
]);

export const ASPECT_RATIO_LOOKUP = Object.values(AspectRatio).map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: AspectRatioMap.get(value as any) };
});

export const aspectRationConverterMap: Record<AspectRatio, IAspectRatio> = {
  [AspectRatio.None]: { w: 0, h: 0 },
  [AspectRatio.Square_1_1]: { w: 1, h: 1 },
  [AspectRatio.Standard_4_3]: { w: 4, h: 3 },
  [AspectRatio.Widescreen_16_9]: { w: 16, h: 9 },
  [AspectRatio.Classic_3_2]: { w: 3, h: 2 },
  [AspectRatio.Traditional_5_4]: { w: 5, h: 4 },
  [AspectRatio.Cinemascope_21_9]: { w: 21, h: 9 },
  [AspectRatio.Anamorphic_2_35_1]: { w: 2.35, h: 1 },

  [AspectRatio.VerticalWidescreen_9_16]: { w: 9, h: 16 },
  [AspectRatio.VerticalClassic_2_3]: { w: 2, h: 3 },
  [AspectRatio.VerticalStandard_3_4]: { w: 3, h: 4 },
  [AspectRatio.VerticalTraditional_4_5]: { w: 4, h: 5 },
  [AspectRatio.VerticalTall_1_2]: { w: 1, h: 2 },
};
