import { Component, ViewChild } from '@angular/core';
import { ConferenceRegistrationGridService } from '../conference-registration-grid.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  EVENT_INQUIRY_REQUEST_STATUS_LOOKUP,
  EventInquiryRequest,
  EventInquiryRequestKeys,
  EventInquiryRequestStatus,
} from 'ag-common-lib/lib/models/registration/event-inquiry-request.model';
import { EventInquiryRequestsService } from 'ag-common-svc/lib/services/event-inquire-requests.service';
import { ColumnButtonClickEvent, RowDblClickEvent, RowRemovingEvent } from 'devextreme/ui/data_grid';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { confirm } from 'devextreme/ui/dialog';
import {
  AGENT_STATUS,
  AgentKeys,
  ApproveDenyReason,
  ApproveDenyReasonKeys,
  ApproveDenyReasonVisibilityLevel,
  ConferenceKeys,
  Constants,
  EmailActionTrigger,
  EmailTemplateConfigurationKeys,
  EmailTemplatesModels,
  Entity,
  EntityPermissionActivityKeys,
  LookupKeys,
  QUALIFIED_AS_LOOKUP,
  QualifiedAs,
  RegistrantKeys,
  REGISTRATION_TYPE_LOOKUP,
  RegistrationType,
} from '@ag-common-lib/public-api';
import CustomStore from 'devextreme/data/custom_store';
import { getAgentFullName } from 'ag-common-svc/lib/utils/agent-data.util';
import { AgentService, AuthService } from 'ag-common-svc/public-api';
import { firstValueFrom, lastValueFrom, map, shareReplay, startWith, take } from 'rxjs';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { DxFormComponent } from 'devextreme-angular';
import { AddAttendeesModalService } from '../../conferences/modals/attendees-list-modal/add-attendees-modal/add-attendees-modal.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { saveAs } from 'file-saver';
import { PhoneNumberMaskPipe } from 'ag-common-svc/shared/pipes/phone-number-mask.pipe';
import { RegistrationParticipationEmailService } from 'ag-common-svc/lib/services/registration-participation-email.service';
import { filterTemplatesByDataModel } from 'ag-common-svc/lib/utils/mailer.utils';
import { AgentApproveDenyReasonsService } from 'ag-common-svc/lib/services/agent-approve-deny-reason.service';

@UntilDestroy()
@Component({
  selector: 'ag-crm-participation-requests-grid',
  templateUrl: './participation-requests-grid.component.html',
  styleUrls: ['./participation-requests-grid.component.scss'],
  providers: [AddAttendeesModalService, PhoneNumberMaskPipe],
})
export class ParticipationRequestsGridComponent {
  @ViewChild('assignAgentModalRef', { static: true }) assignAgentModalComponent: ModalWindowComponent;
  @ViewChild('assignAgentFormRef', { static: false }) assignAgentFormComponent: DxFormComponent;
  @ViewChild('addAttendeeModalRef', { static: true }) addAttendeeModalComponent: ModalWindowComponent;

  protected EventInquiryRequestKeys = EventInquiryRequestKeys;
  protected LookupKeys = LookupKeys;
  protected AgentKeys = AgentKeys;
  protected Entity = Entity;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected BaseModelKeys = BaseModelKeys;
  protected RegistrantKeys = RegistrantKeys;
  protected EmailActionTrigger = EmailActionTrigger;
  protected EventInquiryRequestStatus = EventInquiryRequestStatus;
  protected eventInquiryRequestStatusLookup = EVENT_INQUIRY_REQUEST_STATUS_LOOKUP;
  protected participationRequests$ = this.conferenceRegistrationGridService.participationRequests$;
  protected inquiryRequestFormData: EventInquiryRequest;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected attendeeFormData;
  protected assignmentInProgress = false;
  protected isAddingInProgress$ = this.addAttendeesModalService.inProgress$;
  protected readonly emptyMessage: string = 'No Requests Currently Exist. Please select another conference.';
  protected readonly agents$ = this.agentService.agents$;
  protected readonly agentsMap$ = this.agentService.agentsMap$.pipe(startWith({}));
  protected readonly agentsDataSourceConfiguration = {
    paginate: true,
    pageSize: 25,
    map: data => {
      return Object.assign({}, data, {
        [AgentKeys.p_agent_name]: getAgentFullName(data),
      });
    },
    store: new CustomStore({
      key: 'value',
      loadMode: 'raw',
      load: async loadOptions => {
        const response = await lastValueFrom(this.agents$.pipe(take(1)));

        return response;
      },
    }),
  };
  protected readonly qualifiedAsLookup = QUALIFIED_AS_LOOKUP;
  protected readonly registrationTypeLookup = REGISTRATION_TYPE_LOOKUP;
  protected conferenceEmailTemplates$ = this.conferenceRegistrationGridService.conference$.pipe(
    map(conference => {
      const emailTemplates = conference?.[ConferenceKeys.emailTemplates];

      return filterTemplatesByDataModel(EmailTemplatesModels.registrationInquiry, emailTemplates);
    }),
    shareReplay(1),
  );

  constructor(
    private conferenceRegistrationGridService: ConferenceRegistrationGridService,
    private eventInquiryRequestsService: EventInquiryRequestsService,
    private agentService: AgentService,
    private agentApproveDenyReasonsService: AgentApproveDenyReasonsService,
    private addAttendeesModalService: AddAttendeesModalService,
    private phoneNumberMaskPipe: PhoneNumberMaskPipe,
    private registrationParticipationEmailService: RegistrationParticipationEmailService,
    private authService: AuthService,
  ) {}

  protected onParticipationRequestRemoving = (e: RowRemovingEvent<EventInquiryRequest>) => {
    const eventInquiryDbId = e?.data?.[EventInquiryRequestKeys.eventInquiryDbId];
    const eventInquiryRequestDbId = e?.data?.[BaseModelKeys.dbId];

    e.cancel = this.eventInquiryRequestsService
      .delete(eventInquiryDbId, eventInquiryRequestDbId)
      .then(() => false)
      .catch(() => true);
  };

  protected markAllReviewed = async data => {
    const loggedInUser = await firstValueFrom(this.authService.loggedInAgent$.pipe(map(agent => agent?.[BaseModelKeys.dbId])));
    const eventInquiryDbId = data?.[EventInquiryRequestKeys.eventInquiryDbId];
    const dbId = data?.[BaseModelKeys.dbId];
    this.eventInquiryRequestsService.update(eventInquiryDbId, dbId, {
      [EventInquiryRequestKeys.allRelatedAgentsReviewed]: true,
      [EventInquiryRequestKeys.reviewedByAgentDbId]: loggedInUser,
      [EventInquiryRequestKeys.reviewedDate]: new Date(),
    });
  };

  protected onRelatedAgentRemoving = (eventInquiryRequest: EventInquiryRequest, e: RowRemovingEvent) => {
    const eventInquiryDbId = eventInquiryRequest?.[EventInquiryRequestKeys.eventInquiryDbId];
    const relatedAgentsDbIds = eventInquiryRequest?.[EventInquiryRequestKeys.relatedAgentsDbIds];
    const eventInquiryRequestDbId = eventInquiryRequest?.[BaseModelKeys.dbId];
    const restRelatedAgentsDbIds = relatedAgentsDbIds.filter(dbId => dbId !== e.key);

    e.cancel = this.eventInquiryRequestsService
      .update(eventInquiryDbId, eventInquiryRequestDbId, {
        [EventInquiryRequestKeys.relatedAgentsDbIds]: restRelatedAgentsDbIds,
      })
      .then(() => false)
      .catch(() => true);
  };

  protected calculatePhoneNumberDisplayValue = (eventInquiryRequest: EventInquiryRequest) => {
    return this.phoneNumberMaskPipe.transform({ number: eventInquiryRequest?.phoneNumber }, false);
  };

  protected canApproveParticipation = (e: ColumnButtonClickEvent<EventInquiryRequest>) => {
    const eventInquiryRequest = e?.row?.data;
    const agentDbId = eventInquiryRequest?.[EventInquiryRequestKeys.agentDbId];
    const eventInquiryStatus = eventInquiryRequest?.[EventInquiryRequestKeys.status];
    return !!agentDbId && eventInquiryStatus !== EventInquiryRequestStatus.approved;
  };

  protected canRejectParticipation = (e: ColumnButtonClickEvent<EventInquiryRequest>) => {
    const eventInquiryRequest = e?.row?.data;
    const eventInquiryStatus = eventInquiryRequest?.[EventInquiryRequestKeys.status];
    return (
      eventInquiryStatus !== EventInquiryRequestStatus.approved &&
      eventInquiryStatus !== EventInquiryRequestStatus.rejected
    );
  };

  protected canRequestMoreInfo = (e: ColumnButtonClickEvent<EventInquiryRequest>) => {
    const eventInquiryRequest = e?.row?.data;
    const eventInquiryStatus = eventInquiryRequest?.[EventInquiryRequestKeys.status];
    return (
      eventInquiryStatus !== EventInquiryRequestStatus.approved &&
      eventInquiryStatus !== EventInquiryRequestStatus.noContact
    );
  };

  protected canAssignAgent = (eventInquiryRequest: EventInquiryRequest) => {
    const eventInquiryStatus = eventInquiryRequest?.[EventInquiryRequestKeys.status];
    return eventInquiryStatus !== EventInquiryRequestStatus.approved;
  };

  protected approveParticipation = async (e: ColumnButtonClickEvent<EventInquiryRequest>) => {
    const eventInquiryRequest = e?.row?.data;
    this.inquiryRequestFormData = structuredClone(eventInquiryRequest);
    this.attendeeFormData = {
      [BaseModelKeys.dbId]: eventInquiryRequest?.[EventInquiryRequestKeys.agentDbId],
      [RegistrantKeys.registrationType]: RegistrationType.agent,
      [RegistrantKeys.qualifiedAs]: QualifiedAs.agtMoreThan75,
    };

    this.addAttendeeModalComponent.showModal();
  };

  protected rejectParticipation = async (e: ColumnButtonClickEvent<EventInquiryRequest>) => {
    const eventInquiryRequest = e?.row?.data;
    const eventInquiryRequestDbId = eventInquiryRequest?.[BaseModelKeys.dbId];
    const eventInquiryDbId = eventInquiryRequest?.[EventInquiryRequestKeys.eventInquiryDbId];
    const confirmationMessage = `<div>Are you sure you want to Reject Participation ?</div>`;
    const isConfirmed = await confirm(confirmationMessage, 'Confirm');
    if (!isConfirmed) {
      return;
    }

    const conference = await firstValueFrom(this.conferenceRegistrationGridService.conference$);
    const emailTemplates = conference?.[ConferenceKeys.emailTemplates];
    const emailTemplateConfiguration = emailTemplates?.find(
      template => template?.[EmailTemplateConfigurationKeys.trigger] === EmailActionTrigger.rejectApplication,
    );
    const noTemplateMessage = `<div>There is No Email Template Assigned to notify Agent. Do You wanna continue?</div>`;
    const isSilentConfirmed = !!emailTemplateConfiguration || (await confirm(noTemplateMessage, 'Continue silent'));
    if (!isSilentConfirmed) {
      return;
    }

    this.eventInquiryRequestsService
      .update(eventInquiryDbId, eventInquiryRequestDbId, {
        [EventInquiryRequestKeys.status]: EventInquiryRequestStatus.rejected,
      })
      .then(() => {
        return this.registrationParticipationEmailService.sendRegistrationParticipationEmail({
          eventInquiryDbId,
          eventInquiryRequestDbId,
          emailActionTrigger: EmailActionTrigger.rejectApplication,
        });
      });
  };

  protected requestMoreInfo = async (e: ColumnButtonClickEvent<EventInquiryRequest>) => {
    const eventInquiryRequest = e?.row?.data;
    const dbId = eventInquiryRequest?.[BaseModelKeys.dbId];
    const eventInquiryDbId = eventInquiryRequest?.[EventInquiryRequestKeys.eventInquiryDbId];

    const confirmationMessage = `<div>Are you sure you want to Request More info ?</div>`;
    const isConfirmed = await confirm(confirmationMessage, 'Confirm');
    if (!isConfirmed) {
      return;
    }

    this.eventInquiryRequestsService.update(eventInquiryDbId, dbId, {
      [EventInquiryRequestKeys.status]: EventInquiryRequestStatus.noContact,
    });
  };

  protected changeAgent = async (e: ColumnButtonClickEvent<EventInquiryRequest>) => {
    const eventInquiryRequest = e?.row?.data;
    this.inquiryRequestFormData = structuredClone(eventInquiryRequest);
    this.assignAgentModalComponent.showModal();
  };

  protected handleAssignAgentClick = async () => {
    const validationResults = this.assignAgentFormComponent.instance.validate();

    if (!validationResults?.isValid) {
      return;
    }
    this.assignmentInProgress = true;

    try {
      const dbId = this.inquiryRequestFormData?.[BaseModelKeys.dbId];
      const eventInquiryDbId = this.inquiryRequestFormData?.[EventInquiryRequestKeys.eventInquiryDbId];

      await this.eventInquiryRequestsService.update(eventInquiryDbId, dbId, {
        [EventInquiryRequestKeys.agentDbId]: this.inquiryRequestFormData?.[EventInquiryRequestKeys.agentDbId],
      });

      this.assignAgentModalComponent.forceCloseModal();
    } catch (error) {}

    this.assignmentInProgress = false;
  };

  protected handleAddAgentToEvent = async () => {
    this.assignmentInProgress = true;
    try {
      const conference = await firstValueFrom(this.conferenceRegistrationGridService.conference$);
      const conferenceDbId = conference?.[BaseModelKeys.dbId];

      this.addAttendeesModalService.setConferenceDbId(conferenceDbId);

      await this.addAttendeesModalService.addAgentToConference(this.attendeeFormData);

      const agentDbId = this.inquiryRequestFormData?.[EventInquiryRequestKeys.agentDbId];
      const agentData = await this.agentService.getById(agentDbId);

      const eventName = conference?.[ConferenceKeys.eventName];
      const emailTemplates = conference?.[ConferenceKeys.emailTemplates];
      const eventInquiryRequestDbId = this.inquiryRequestFormData?.[BaseModelKeys.dbId];
      const eventInquiryDbId = this.inquiryRequestFormData?.[EventInquiryRequestKeys.eventInquiryDbId];

      const emailTemplateConfiguration = emailTemplates?.find(
        template => template?.[EmailTemplateConfigurationKeys.trigger] === EmailActionTrigger.approveApplication,
      );
      const confirmationMessage = `<div>There is No Email Template Assigned to notify Agent. Do You wanna continue?</div>`;
      const isConfirmed = !!emailTemplateConfiguration || (await confirm(confirmationMessage, 'Continue silent'));
      if (!isConfirmed) {
        return;
      }

      this.eventInquiryRequestsService
        .update(eventInquiryDbId, eventInquiryRequestDbId, {
          [EventInquiryRequestKeys.status]: EventInquiryRequestStatus.approved,
        })
        .then(() => {
          const agentStatus = agentData?.[AgentKeys.agent_status];
          const isNewUser = new Set([AGENT_STATUS.NEW_REGISTRATION, AGENT_STATUS.NEW_AGENT]).has(agentStatus);

          if (isNewUser) {
            this.agentService.updateAgentFields(agentDbId, { [AgentKeys.agent_status]: AGENT_STATUS.APPROVED });
            const reason: ApproveDenyReason = {
              [ApproveDenyReasonKeys.activity]: ` Agent Status is automatically changed to Approved by the system after ${eventName} attendance request was approved.`,
              [ApproveDenyReasonKeys.visibilityLevel]: ApproveDenyReasonVisibilityLevel.AllianceGroupLevel,
              [ApproveDenyReasonKeys.isSystem]: true,
            };
            this.agentApproveDenyReasonsService.create(agentDbId, Object.assign({}, reason));
          }

          this.registrationParticipationEmailService.sendRegistrationParticipationEmail({
            eventInquiryDbId,
            eventInquiryRequestDbId,
            emailActionTrigger: EmailActionTrigger.approveApplication,
          });

          return;
        });

      this.conferenceRegistrationGridService.forceRefresh();
      this.addAttendeeModalComponent.forceCloseModal();
    } catch (error) {}

    this.assignmentInProgress = false;
  };

  protected calculateRelatedAgentCellValue = row => {
    return row;
  };

  protected showAgentDetails = (row: RowDblClickEvent) => {
    this.conferenceRegistrationGridService.showAgentDetails(row.key);
  };

  protected async onExporting(e: DxDataGridTypes.ExportingEvent) {
    const conference = await firstValueFrom(this.conferenceRegistrationGridService.conference$);
    const eventName = conference?.[ConferenceKeys.eventName];
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`Event Inquiry Requests for ${eventName}`);

    exportDataGrid({
      component: e.component,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Event Inquiry Requests for ${eventName}.xlsx`,
        );
      });
    });
  }
}
