import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeLogsDiffHtmlPipe } from './change-logs-diff-html.pipe';
import { ChangeLogExcursionNameByIdPipe } from './change-log-excursion-name-by-id.pipe';
import { ChangeLogChildKeyPipe } from './change-log-child-key.pipe';
import { ChangeLogDiffByKeysPipe } from './change-log-diff-by-keys.pipe';
import { ChangeLogCaptionByIndexPipe } from './change-log-caption-by-index.pipe';
import { ChangeLogTimeStampToDatePipe } from './change-log-time-stamp-to-date.pipe';
import { IsLogArrayPipe } from './is-log-array.pipe';
import { ShowPagerPipe } from './show-pager.pipe';

@NgModule({
  declarations: [
    ChangeLogsDiffHtmlPipe,
    ChangeLogExcursionNameByIdPipe,
    ChangeLogChildKeyPipe,
    ChangeLogDiffByKeysPipe,
    ChangeLogCaptionByIndexPipe,
    ChangeLogTimeStampToDatePipe,
    IsLogArrayPipe,
    ShowPagerPipe,
  ],
  imports: [CommonModule],
  exports: [
    ChangeLogsDiffHtmlPipe,
    ChangeLogExcursionNameByIdPipe,
    ChangeLogChildKeyPipe,
    ChangeLogDiffByKeysPipe,
    ChangeLogCaptionByIndexPipe,
    ChangeLogTimeStampToDatePipe,
    IsLogArrayPipe,
    ShowPagerPipe,
  ],
})
export class ChangeLogPipesModule {}
