import { Component, Input, ViewChild } from '@angular/core';
import {
  DX_USD_CURRENCY_FORMAT,
  LookupKeys,
  ProcessRefundPayload,
  ProcessRefundPayloadKeys,
  REFUND_REASON_LOOKUP,
  StripeTransaction,
  StripeTransactionKeys,
  TransactionsStatuses,
  TransactionType,
} from 'ag-common-lib/public-api';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { AttendeeRefundPaymentModalService } from '../attendee-refund-payment-modal/attendee-refund-payment-modal.service';
import { confirm } from 'devextreme/ui/dialog';
import { CurrencyPipe } from '@angular/common';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { ToastrService } from 'ngx-toastr';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ag-crm-attendee-refund-payment-modal',
  templateUrl: './attendee-refund-payment-modal.component.html',
  styleUrls: ['./attendee-refund-payment-modal.component.scss'],
  providers: [CurrencyPipe],
})
export class AttendeeRefundPaymentModalComponent {
  @ViewChild('attendeeRefundTransactionModalRef', { static: true }) refundModalComponent: ModalWindowComponent;
  @Input() handleSaveTransaction: (transaction: Partial<StripeTransaction>) => Promise<void>;

  totalAmountToRefund$ = this.attendeeRefundPaymentModalService.totalAmountToRefund$;

  protected formData: ProcessRefundPayload;
  protected readonly ProcessRefundPayloadKeys = ProcessRefundPayloadKeys;
  inProgress$ = new BehaviorSubject<boolean>(false);
  protected readonly reasonTypesLookup = REFUND_REASON_LOOKUP;
  protected readonly validationGroup = 'refundPaymentValidationGroup';
  protected readonly StripeTransactionKeys = StripeTransactionKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;

  private transactionDbId: string;

  constructor(
    private attendeeRefundPaymentModalService: AttendeeRefundPaymentModalService,
    private currencyPipe: CurrencyPipe,
    private cloudFunctions: CloudFunctionsService,
    private toastrService: ToastrService,
  ) {}

  showModal = async (transactionDbId: string, data?: Partial<ProcessRefundPayload>): Promise<void> => {
    this.formData = this.attendeeRefundPaymentModalService.getFormData(data);
    this.transactionDbId = transactionDbId;
    this.refundModalComponent.showModal();
  };

  protected onSaveRefund = async e => {
    const isValid = await validateDxGroups(this.validationGroup);
    if (!isValid) {
      return;
    }
    const amount = this.formData[ProcessRefundPayloadKeys.amount];
    const amountString = this.currencyPipe.transform(amount);
    const paymentIntent = this.formData[ProcessRefundPayloadKeys.paymentIntent];
    const reason = this.formData[ProcessRefundPayloadKeys.reason];
    const result = await confirm(
      `<i>Are you sure you wish to process this refund ${amountString}? This action cannot be undone.</i>`,
      'Confirm',
    );

    if (!result) {
      return;
    }

    this.inProgress$.next(true);
    const response = await this.cloudFunctions
      .refundPayment({
        amount: amount * 100, // to cents
        payment_intent: paymentIntent,
        reason: reason,
      })
      .catch(e => {
        this.toastrService.error('Failed to Refund');
        console.log('Error: ', e);
      });

    if (response) {
      this.toastrService.success('Refund Successfully Created');
      await this.saveRefundTransaction(response.data);
    }

    this.refundModalComponent.hideModal();
    this.inProgress$.next(false);
  };

  async saveRefundTransaction(refundDetails) {
    await this.handleSaveTransaction(
      Object.assign({}, this.formData, {
        [StripeTransactionKeys.stripeId]: refundDetails.id,
        [StripeTransactionKeys.type]: TransactionType.refund,
        [StripeTransactionKeys.status]: TransactionsStatuses.inProgress,
        [StripeTransactionKeys.description]: refundDetails?.description,
        [StripeTransactionKeys.parentStripeId]: refundDetails.payment_intent,
        [StripeTransactionKeys.parentDbId]: this.transactionDbId,
      }),
    );
  }
}
