import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangeLogsType, FLIGHT_BOOKING_KEYS_CONFIG, FlightBookingKeys, LookupBase } from '@ag-common-lib/public-api';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import { getLogIndexes } from '../flight-info-log.utils';

@Component({
  selector: 'ag-shr-change-log-flight-booking-info',
  templateUrl: './change-log-flight-booking-info.component.html',
  styleUrls: ['./change-log-flight-booking-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogFlightBookingInfoComponent {
  @Input()
  set logs(delta: Delta) {
    // Delta for changed object is object of objects
    if (!Array.isArray(delta)) {
      this._logs = delta;
      this.flightBookingLogIndexes = getLogIndexes(Object.entries(this._logs));
      return;
    }
    // Delta for new value is array with array of objects
    this._logs = delta[0] as Delta;
    this.flightBookingLogIndexes = getLogIndexes(this._logs);
  }
  get logs(): Delta {
    return this._logs;
  }
  @Input() attendeeName: string;

  flightBookingLogIndexes: number[] = [];

  protected readonly hasLog = hasLog;
  protected readonly normalizeLog = normalizeLog;
  protected readonly bookingInfoLookup = FLIGHT_BOOKING_KEYS_CONFIG;
  protected readonly FlightBookingKeys = FlightBookingKeys;
  protected readonly ChangeLogsType = ChangeLogsType;
  protected readonly Array = Array;
  private _logs: Delta;
}
