import { BaseModel } from '../base.model';

export enum SystemFileDirectories {
  Documents = 'Documents',
  Images = 'Images',
}

export enum SystemMediaDirectories {
  Images = 'Images',
}

export enum FileDirectoryKeys {
  name = 'name',
  isSystem = 'isSystem',
}

export class FileDirectory extends BaseModel {
  [FileDirectoryKeys.name]: string;
  [FileDirectoryKeys.isSystem]: boolean;
}

export const AGENT_SYSTEM_DIRECTORY_NAMES: string[] = [SystemFileDirectories.Images, SystemFileDirectories.Documents];
export const SYSTEM_MEDIA_DIRECTORY_NAMES: string[] = [SystemFileDirectories.Images];
