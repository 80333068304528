import videojs from 'video.js';

const Button = videojs.getComponent('Button');

class ShareButton extends Button {
  private _options: any;

  constructor(player, options) {
    super(player, options);
    this.addClass('vjs-menu-button');
    this.addClass('vjs-share-control');
    this.addClass('vjs-icon-share');
    this.addClass('vimeo-video__btn-share');
    this.setAttribute('title', 'Share');
    this._options = options;
  }

  handleClick() {
    if (navigator.share) {
      const videoLink = this._options?.videoLink;
      const videoTitle = this._options?.videoTitle ?? '';
      const videoText = `Check out “${videoTitle}” from Alliance Group on Vimeo.\n The video is available for your viewing pleasure at ${videoLink}.\n`;
      navigator
        .share({
          title: videoTitle,
          text: videoText,
          url: videoLink,
        })
        .catch(err => console.log('Error sharing', err));
      return;
    }
    // no navigator.share => show devextreme share modal
    this.player().trigger('share');
  }
}

export default ShareButton;
