import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FLIGHT_INFO_KEYS_LOOKUP, FlightInfoKeys, LookupKeys, TRUE_FALSE_LOOKUP } from '@ag-common-lib/public-api';
import { Delta } from 'jsondiffpatch/lib/types';
import {
  getLogDiffValues,
  getLogIndexes,
  hasLog,
  isLogArray,
  normalizeLog,
  normalizeToValue
} from '../../../utils/normalize-log';
import { AirlinePreferenceKeys, FlightPreferenceKeys } from '@ag-common-lib/lib/models/utils/flight-preference.model';
import { ChangeHistory } from '../../../change-history.model';

@Component({
  selector: 'ag-shr-change-log-flight-preference-grid-detail',
  templateUrl: './change-log-flight-preference-grid.component.html',
  styleUrls: ['./change-log-flight-preference-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogFlightPreferenceGridComponent {
  @Input() set log(value: ChangeHistory) {
    console.log('Flight Preference data ', value);
    this._log = value?.log;
    this.createFlightPreferenceDataSource(value);
  }
  get log(): Delta {
    return this._log;
  }

  private _log: Delta;
  dataSource = [];
  airlinePreferencesDataSource = [];
  protected readonly flightPreferenceKeys = [
    FlightInfoKeys.knownTravelerNumber,
    FlightInfoKeys.seatPreference,
    FlightInfoKeys.preferredDepartureAirport,
  ];
  protected readonly flightInfoKeysConfig = FLIGHT_INFO_KEYS_LOOKUP;
  protected readonly airlinePreferencesKeys = Object.keys(AirlinePreferenceKeys);
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly FlightInfoKeys = FlightInfoKeys;
  protected readonly LookupKeys = LookupKeys;

  private createFlightPreferenceDataSource = value => {
    const log = value.log as Delta;
    const oldData = value?.oldData;
    let flightPreferenceLog = {};
    const flightPreferenceLogMap = new Set();
    Object.entries(log).forEach(([key, data]) => {
      if (key === FlightPreferenceKeys.airlinePreferences) {
        this.createAirlinePreferenceDataSource(data, oldData[key]);
        return;
      }
      if (!flightPreferenceLogMap.has(key)) {
        flightPreferenceLogMap.add(key);
      }
      const valuesLookup = this.getLookupByKey(key);
      const logByKey = { [key]: data } as Delta;
      const logsByValue = getLogDiffValues(logByKey, valuesLookup);
      Object.assign(flightPreferenceLog, { [key]: logsByValue });
    });
    this.flightPreferenceKeys.forEach(key => {
      if (!flightPreferenceLogMap.has(key)) {
        Object.assign(flightPreferenceLog, {
          [key]: { oldValue: normalizeToValue(oldData?.[key], this.getLookupByKey(key)) },
        });
      }
    });
    this.dataSource.push(flightPreferenceLog);
  };

  private createAirlinePreferenceDataSource = (value, oldData) => {
    const log = isLogArray(value) as Delta;
    const logIndexes = getLogIndexes(Object.entries(log));
    logIndexes.forEach(ind => {
      let airlinePreferenceLog = {};
      const airlinePreferenceLogMap = new Set();
      const normalizedLog = isLogArray(log[ind]);
      Object.entries(normalizedLog).forEach(([key, data]) => {
        if (!airlinePreferenceLogMap.has(key)) {
          airlinePreferenceLogMap.add(key);
        }
        const valuesLookup = this.getLookupByKey(key);
        const logByKey = { [key]: data } as Delta;
        const logsByValue = getLogDiffValues(logByKey, valuesLookup);
        Object.assign(airlinePreferenceLog, { [key]: logsByValue });
      });
      this.airlinePreferencesKeys.forEach(key => {
        if (!airlinePreferenceLogMap.has(key)) {
          Object.assign(airlinePreferenceLog, {
            [key]: { oldValue: normalizeToValue(oldData?.[ind]?.[key], this.getLookupByKey(key)) },
          });
        }
      });
      this.airlinePreferencesDataSource.push(airlinePreferenceLog);
    });
  };

  getLookupByKey = (key: AirlinePreferenceKeys | FlightInfoKeys | string) => {
    switch (key) {
      case AirlinePreferenceKeys.isFirstChoiceAirline:
      case AirlinePreferenceKeys.isSecondChoiceAirline:
        return this.trueFalseLookup;

      default:
        return null;
    }
  };
}
