import { LookupKeys } from '@ag-common-lib/public-api';
import { ObjectFit, ObjectPosition } from './template-builder-object-fit.model';
import { CaptionOptions, CaptionPosition } from './template-builder-caption.model';
import { AspectRatio } from './template-builder-aspect-ration.model';

export enum ImageWidgetMode {
  single = 'single',
  grid = 'grid',
}

export const ImageWidgetModeMap = new Map([
  [ImageWidgetMode.single, 'Single'],
  [ImageWidgetMode.grid, 'Grid'],
]);

export const IMAGE_WIDGET_MODE_LOOKUP = [ImageWidgetMode.single, ImageWidgetMode.grid].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: ImageWidgetModeMap.get(value) };
});

export class ImageWidgetItem {
  wasabiPath?: string;
  aspectRation?: number;
  objectPosition?: ObjectPosition = ObjectPosition.center;
  title?: string = '';
  description?: string = '';
}

export class ImageWidgetData {
  items: ImageWidgetItem[] = [];
  mode: ImageWidgetMode = ImageWidgetMode.single;
  gap: number = 8;
  width: number = 100;
  minWidth: number = 128;
  maxHeight: number;
  imagesPerRow: number = 3;
  rowsPerPage: number = 3;
  objectFit: ObjectFit = ObjectFit.cover;
  aspectRation: AspectRatio = AspectRatio.None;
  titleMode: CaptionPosition = CaptionPosition.none;
  titleOptions: CaptionOptions = new CaptionOptions();
  descriptionMode: CaptionPosition = CaptionPosition.none;
  descriptionOptions: CaptionOptions = new CaptionOptions(15, '#444');
}
