import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { NotificationSchedulerEditModalComponent } from './notification-scheduler-edit-modal.component';
import { ModalWindowModule } from '../../../../modal-window/modal-window.module';
import { RecipientsDataSourcePipe } from './recipients-data-source.pipe';
import { HtmlEditorModule } from '../../../../html-editor/html-editor.module';
import { NotificationAddRecipientsModalModule } from '../../notification-add-recipients-modal/notification-add-recipients-modal.module';
import { DxDropDownButtonModule, DxFilterBuilderModule, DxTabPanelModule } from 'devextreme-angular';
import { NotificationNotificationChannelModalModule } from '../../notification-notification-channel-modal/notification-notification-channel-modal.module';
import { NotificationsChannelNamePipe } from './notifications-channel-name.pipe';
import { AddNotificationOptInGroupModalModule } from '../../add-notification-opt-in-group-modal/add-notification-opt-in-group-modal.module';
import { NotificationAddSegmentsModalModule } from '../../dynamic-segments-group/notification-add-segments-modal/notification-add-segments-modal.module';
import { DynamicListsAndSegmentsPipesModule } from 'ag-common-svc/shared/pipes/dynamic-lists-and-segments/dynamic-lists-and-segments-pipes.module';
import { NotificationAddListsModalModule } from '../../dynamic-lists-group/notification-add-lists-modal/notification-add-lists-modal.module';
import { NotificationsSchedulerCheckedRecipientsGridModule } from '../notifications-scheduler-checked-recipients-grid/notifications-scheduler-checked-recipients-grid.module';
// import { DynamicListsGroupService, DynamicSegmentsGroupService } from 'ag-common-svc/public-api';

@NgModule({
  declarations: [NotificationSchedulerEditModalComponent, RecipientsDataSourcePipe, NotificationsChannelNamePipe],
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    HtmlEditorModule,
    NotificationAddRecipientsModalModule,
    DxTabPanelModule,
    DxDropDownButtonModule,
    NotificationNotificationChannelModalModule,
    AddNotificationOptInGroupModalModule,
    DxFilterBuilderModule,
    NotificationAddSegmentsModalModule,
    NotificationAddListsModalModule,
    DynamicListsAndSegmentsPipesModule,
    NotificationsSchedulerCheckedRecipientsGridModule,
  ],

  exports: [NotificationSchedulerEditModalComponent, RecipientsDataSourcePipe],
})
export class NotificationSchedulerEditModalModule {}
