<ag-crm-attendee-transaction-modal
  [billingDetails]="billingDetails"
  [handleSaveTransaction]="handleSaveTransaction"
></ag-crm-attendee-transaction-modal>

<ag-crm-attendee-refund-payment-modal
  [handleSaveTransaction]="handleSaveTransaction"
></ag-crm-attendee-refund-payment-modal>

<ag-crm-attendee-transaction-details-modal></ag-crm-attendee-transaction-details-modal>

<ag-crm-attendee-manual-refund-modal
  [handleSaveTransaction]="handleSaveTransaction"
></ag-crm-attendee-manual-refund-modal>

<dx-tree-list
  #transactionsHistoryGrid
  class="transactions-details-table"
  *ngIf="transactions"
  [dataSource]="transactions"
  [keyExpr]="transactionKeyExpr"
  [parentIdExpr]="transactionParentIdExpr"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  [showBorders]="true"
  [showColumnLines]="true"
  (onRowRemoving)="onTransactionRemoving($event)"
  width="100%"
  height="100%"
>
  <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

  <dxo-editing
    #transactionEditingTmp
    mode="row"
    [useIcons]="true"
    [allowUpdating]="false"
    [allowDeleting]="allowEditingTransaction"
    [allowAdding]="false"
    [newRowPosition]="'last'"
  >
  </dxo-editing>

  <dxo-toolbar>
    <dxi-item location="before">
      <b *dxTemplate class="master-detail-caption ms-2">Transactions History:</b>
    </dxi-item>

    <dxi-item
      [visible]="editMode && canEditTransaction"
      location="after"
      widget="dxButton"
      [options]="{ text: 'Make the manual refund', stylingMode: 'outlined', onClick: onMakeManualRefund }"
    ></dxi-item>

    <dxi-item
      [visible]="editMode && canEditTransaction"
      location="after"
      widget="dxButton"
      [options]="{ text: 'Make the manual payment', stylingMode: 'outlined', onClick: onMakeManualPayment }"
    ></dxi-item>

    <dxi-item
      [visible]="editMode && canCreateTransaction"
      location="after"
      widget="dxButton"
      [options]="{ icon: 'plus', onClick: addTransactionClick }"
    ></dxi-item>
  </dxo-toolbar>

  <dxi-column
    [visible]="editMode"
    [dataField]="StripeTransactionKeys.stripeId"
    caption="Transaction ID"
    [width]="270"
    [calculateCellValue]="formatStripeId"
  ></dxi-column>

  <dxi-column [visible]="editMode" [dataField]="StripeTransactionKeys.type" [width]="130">
    <dxo-lookup
      [dataSource]="TRANSACTION_TYPE_LOOKUP"
      [displayExpr]="LookupKeys.description"
      [valueExpr]="LookupKeys.value"
    >
    </dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="StripeTransactionKeys.status" [width]="100">
    <dxo-lookup
      [dataSource]="TRANSACTIONS_STATUSES_LOOKUP"
      [displayExpr]="LookupKeys.description"
      [valueExpr]="LookupKeys.value"
    >
    </dxo-lookup>
  </dxi-column>

  <dxi-column
    [visible]="editMode"
    caption="Created Date"
    [dataField]="BaseModelKeys.createdDate"
    dataType="datetime"
    [width]="150"
  ></dxi-column>

  <dxi-column
    [visible]="editMode"
    caption="Made By"
    [dataField]="BaseModelKeys.createdByAgentDbId"
    cellTemplate="createdByAgentDbIdCellTemplate"
  >
    <div *dxTemplate="let cell of 'createdByAgentDbIdCellTemplate'">
      {{ cell.value | lookupDescriptionText: (agentsList$ | async) }}
      <ng-container *ngIf="cell.data?.[BaseModelKeys.createdByEmail] as email"> ({{ email }})</ng-container>
    </div>
  </dxi-column>

  <dxi-column [dataField]="StripeTransactionKeys.description"></dxi-column>

  <dxi-column
    [dataField]="StripeTransactionKeys.amount"
    caption="Amount"
    [alignment]="'right'"
    [dataType]="'number'"
    [format]="DX_USD_CURRENCY_FORMAT"
    [width]="100"
  ></dxi-column>

  <dxi-column type="buttons" [minWidth]="125" [fixed]="true" caption="Actions" [visible]="editMode">
    <dxi-button name="edit" [onClick]="editTransactionClick" [visible]="canEditTransaction"></dxi-button>
    <dxi-button name="delete" [visible]="canDeleteTransaction"></dxi-button>
    <dxi-button hint="Refund" icon="revert" [onClick]="refundClick" [visible]="canRefundTransaction"></dxi-button>
    <dxi-button hint="Details" icon="money" [onClick]="detailsClick" [visible]="canShowDetailsTransaction"></dxi-button>
    <dxi-button
      icon="copy"
      hint="Copy Transaction ID"
      [visible]="canCopyStripeId"
      [onClick]="copyParentStripeId"
    ></dxi-button>
  </dxi-column>

  <!--  Templates-->
  <div *dxTemplate="let cell of 'currencyCellTmp'">
    {{ cell.data[StripeTransactionKeys.amount] | customCurrency: "USD" : "en-US" }}
  </div>
</dx-tree-list>
