import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ChangeLogsHistoryType,
  ADDRESS_LOOKUP,
  AddressModelKeys,
  LookupBase,
  LookupKeys,
  TRUE_FALSE_LOOKUP,
} from '@ag-common-lib/public-api';
import { Delta } from 'jsondiffpatch/lib/types';
import {
  getLogDiffValues,
  getLogIndexes,
  hasLog,
  isLogArray,
  normalizeLog,
  normalizeToValue
} from '../../../utils/normalize-log';
import { ChangeHistory } from '../../../change-history.model';

@Component({
  selector: 'ag-shr-change-log-address-grid-detail',
  templateUrl: './change-log-address-grid.component.html',
  styleUrls: ['./change-log-address-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogAddressGridComponent {
  @Input()
  set changeLogType(value: ChangeLogsHistoryType) {
    this.addressKeys =
        this.changeLogType === ChangeLogsHistoryType.registrant
            ? this.registrantAddressKeys
            : this.registrantAddressKeys.filter(key => key !== AddressModelKeys.isPhysicalLocation);
  };
  @Input() set log(value: ChangeHistory) {
    console.log('Address Grid data ', value);
    this._log = value?.log;
    this.createAddressDataSource(value);
  }
  get log(): Delta {
    return this._log;
  }

  private _log: Delta;
  dataSource = [];
  // for agent: hide AddressModelKeys.isPhysicalLocation and 'addressType' (for summary red wiz only)
  protected readonly registrantAddressKeys = [
    AddressModelKeys.address1,
    AddressModelKeys.address2,
    AddressModelKeys.city,
    AddressModelKeys.state,
    AddressModelKeys.zip,
    AddressModelKeys.country,
    AddressModelKeys.county,
    AddressModelKeys.addressType,
    AddressModelKeys.isPrimaryShipping,
    AddressModelKeys.isPrimaryBilling,
    AddressModelKeys.isPhysicalLocation,
    AddressModelKeys.geoPoint,
  ];
  addressKeys = [];
  protected readonly addressLookup = ADDRESS_LOOKUP;
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly geoPointLookup: LookupBase[] = [
    {
      [LookupKeys.value]: AddressModelKeys.lat,
      [LookupKeys.description]: 'Latitude',
    },
    {
      [LookupKeys.value]: AddressModelKeys.lng,
      [LookupKeys.description]: 'Longitude',
    },
  ];

  private createAddressDataSource = (value) => {
    const logIndexes = getLogIndexes(Object.entries(this._log));
    const log = isLogArray(value.log) as Delta;
    const oldData = value?.oldData;
    logIndexes.forEach(ind => {
      let addressLog = {};
      const addressKeysLogMap = new Set();
      const normalizedLog = isLogArray(log[ind]);
      Object.entries(normalizedLog).forEach(([key, data]) => {
        const valuesLookup = this.getLookupByKey(key);
        const logByKey = { [key]: data } as Delta;
        if (!addressKeysLogMap.has(key)) {
          addressKeysLogMap.add(key);
        }
        const logsByValue = getLogDiffValues(logByKey, valuesLookup);
        Object.assign(addressLog, { [key]: logsByValue });
      });
      this.addressKeys.forEach(key => {
        if (!addressKeysLogMap.has(key)) {
          Object.assign(addressLog, { [key]: { oldValue: normalizeToValue(oldData?.[ind]?.[key], this.getLookupByKey(key)) } });
        }
      });
      this.dataSource.push(addressLog);
    });
  };

  getLookupByKey = (key: AddressModelKeys | string) => {
    switch (key) {
      case AddressModelKeys.isPrimaryShipping:
      case AddressModelKeys.isPrimaryBilling:
      case AddressModelKeys.isPhysicalLocation:
        return this.trueFalseLookup;

      default:
        return null;
    }
  };
}
