import { AlignItems } from './template-builder-align-items.model';
import { BackgroundImageContainer, BackgroundImageOptions } from './template-builder-background.model';
import { Padding } from './template-builder-padding.model';

// png 582fb0c7-c562-4e9a-a5be-c26d59cad9c2
// conf 941f3f49-9a56-472a-af9c-710c70413e47
export class TemplateBuilderContainerProperties {
  minHeight: number = null;
  alignItems: AlignItems = AlignItems.flexStart;
  padding: Padding = new Padding(8);
  backgroundColor?: string;
  contentBackgroundColor?: string;
  textColor?: string;
  backgroundImageContainer: BackgroundImageContainer = BackgroundImageContainer.content;
  backgroundImageWasabiPath?: string;
  backgroundImageOptions?: BackgroundImageOptions = new BackgroundImageOptions();
}
