import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ADDRESS_LOOKUP, AddressModelKeys, LookupBase, LookupKeys, TRUE_FALSE_LOOKUP } from '@ag-common-lib/public-api';
import { Delta } from 'jsondiffpatch/lib/types';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import { of } from 'rxjs';

@Component({
  selector: 'ag-shr-change-log-address-detail',
  templateUrl: './change-log-address.component.html',
  styleUrls: ['./change-log-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogAddressComponent {
  @Input() log: Delta;

  protected readonly addressKeys = [
    AddressModelKeys.address1,
    AddressModelKeys.address2,
    AddressModelKeys.city,
    AddressModelKeys.state,
    AddressModelKeys.zip,
    AddressModelKeys.country,
    AddressModelKeys.county,
    AddressModelKeys.addressType,
    AddressModelKeys.isPrimaryShipping,
    AddressModelKeys.isPrimaryBilling,
    AddressModelKeys.isPhysicalLocation,
    AddressModelKeys.geoPoint,
    'addressType'
  ];
  protected readonly addressLookup = ADDRESS_LOOKUP;
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly geoPointKeys = [AddressModelKeys.lat, AddressModelKeys.lng];
  protected readonly geoPointLookup: LookupBase[] = [
    {
      [LookupKeys.value]: AddressModelKeys.lat,
      [LookupKeys.description]: 'Latitude',
    },
    {
      [LookupKeys.value]: AddressModelKeys.lng,
      [LookupKeys.description]: 'Longitude',
    },
  ];

      getLookup(key: AddressModelKeys | string) {
    switch (key) {
      case AddressModelKeys.isPrimaryShipping:
      case AddressModelKeys.isPrimaryBilling:
      case AddressModelKeys.isPhysicalLocation:
        return of(this.trueFalseLookup);

      default:
        return null;
    }
  }
}
