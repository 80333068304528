import { BaseModel } from '../base.model';

export enum MediaContentType {
  image = 'image',
}

export enum MediaContentDisposition {
  attachment = 'attachment',
  inline = 'inline',
}

export enum AGMediaKeys {
  fileName = 'fileName',
  fileSize = 'fileSize',
  wasabiPath = 'wasabiPath',
  // mediaType = 'mediaType',
  contentType = 'contentType',
  aspectRation = 'aspectRation',
}

export class AGMedia extends BaseModel {
  [AGMediaKeys.fileName]: string;
  [AGMediaKeys.fileSize]: number;
  [AGMediaKeys.wasabiPath]: string;
  [AGMediaKeys.contentType]: string;
  // [AGMediaKeys.mediaType]: MediaContentType;
  [AGMediaKeys.aspectRation]?: number;

  constructor(/* mediaType: MediaContentType */) {
    super();

    // this[AGMediaKeys.mediaType] = mediaType;
  }
}
