import {
  AT_LEISURE_EXCURSION_SUFFIX,
  Excursion,
  ExcursionDataType,
  ExcursionKeys,
  LocalDateTimeString,
  SelectedExcursionConfiguration,
  SelectedExcursions,
  SelectedExcursionsKeys,
} from '@ag-common-lib/public-api';
import {
  ExcursionInfoTableData,
  ExcursionInfoTableDataKeys,
} from '../components/excursions-info-table/excursions-info-table.models';
import { dateFromTimestamp } from './date-from-timestamp';
import { format, parse } from 'date-fns';

export const normalizeSelectedExcursions = (selectedExcursions: SelectedExcursions) => {
  if (!selectedExcursions) {
    return null;
  }

  const excursion = Object.assign({}, selectedExcursions);

  Object.values(excursion).forEach(value => {
    if (!value) {
      return;
    }
    const bookingDate = value?.[SelectedExcursionsKeys.bookingDate];
    Object.assign(value, {
      [SelectedExcursionsKeys.bookingDate]: dateFromTimestamp(bookingDate),
    });
  });

  return excursion;
};

export function getExcursionsMap(excursions: Excursion[]): {
  excursionsDates: Set<LocalDateTimeString>;
  excursionsMap: Map<string, Excursion>;
} {
  const excursionsDates = new Set<string>();
  const excursionsMap = new Map<string, Excursion>();

  excursions.forEach(excursion => {
    const date = excursion?.[ExcursionKeys.date];

    excursionsDates.add(date);
    excursionsMap.set(excursion?.[ExcursionKeys.id], excursion);
  });

  return {
    excursionsDates,
    excursionsMap,
  };
}

export function calculateExcursionConfigurationsRowData(selectedConfigurations: SelectedExcursionConfiguration) {
  if (selectedConfigurations.hasOwnProperty('configurations')) {
    delete selectedConfigurations['configurations'];
  }

  return Object.entries(selectedConfigurations).map(([configurationsName, configurationOption]) => {
    const configurationsNameUpdated = configurationsName.split(/(?=[A-Z])/).join(' ');
    const displayValue = getExcursionConfigurationDisplayValue(
      configurationOption?.value,
      configurationOption?.dataType,
    );
    const title = `${configurationsNameUpdated}: ${displayValue}`;

    return { title };
  });
}

export function getExcursionConfigurationDisplayValue(value: string, dataType: ExcursionDataType): string {
  if (!value) {
    return '';
  }

  switch (dataType) {
    case ExcursionDataType.time:
      const dateTime = parse(value, 'HH:mm', new Date());
      return format(dateTime, 'hh:mm a');

    default:
      return value;
  }
}
export const getConfigurationName = configurationName => {
  const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1);
  return capitalize(configurationName)
    .split(/(?=[A-Z])/)
    .join(' ');
};

export const getConfigurationDescription = (configurations: SelectedExcursionConfiguration) => {
  const details = [];
  Object.entries(configurations ?? {}).forEach(([configurationName, configurationOption]) => {
    const configurationsNameUpdated = getConfigurationName(configurationName);

    const displayValue = getExcursionConfigurationDisplayValue(
      configurationOption?.value,
      configurationOption?.dataType,
    );
    const item = ` ${configurationsNameUpdated}: ${displayValue}`;
    details.push(item);
  });

  return details.filter(Boolean).join(', ');
};

export const convertSelectedExcursionsToList = (
  excursionsMap: Map<string, Excursion>,
  selectedExcursions: SelectedExcursions,
  additionalData: Partial<ExcursionInfoTableData> = null,
  requiredConferenceExcursionsDates?: Set<LocalDateTimeString>,
): ExcursionInfoTableData[] => {
  const list: ExcursionInfoTableData[] = [];
  const requiredExcursionsDates = new Set(requiredConferenceExcursionsDates);

  Object.entries(selectedExcursions ?? {}).forEach(([excursionId, excursionPreferences]) => {
    const item = Object.assign({}, new ExcursionInfoTableData(), additionalData, {
      [ExcursionInfoTableDataKeys.excursionId]: excursionId,
      [ExcursionInfoTableDataKeys.bookingDate]: excursionPreferences?.[SelectedExcursionsKeys.bookingDate],
      [ExcursionInfoTableDataKeys.isAdminSelected]: excursionPreferences?.[SelectedExcursionsKeys.isAdminSelected],
      [ExcursionInfoTableDataKeys.isPaid]: excursionPreferences?.[SelectedExcursionsKeys.isPaid],
      [ExcursionInfoTableDataKeys.selectedPreferences]: excursionPreferences?.[SelectedExcursionsKeys.preferences],
      [ExcursionInfoTableDataKeys.configurations]: excursionPreferences?.[SelectedExcursionsKeys.configurations],
    });

    if (excursionId.endsWith(AT_LEISURE_EXCURSION_SUFFIX)) {
      const dateString = excursionId.replace(AT_LEISURE_EXCURSION_SUFFIX, 'T00:00:00');

      Object.assign(item, {
        [ExcursionInfoTableDataKeys.excursionDate]: dateString,
        [ExcursionInfoTableDataKeys.excursionName]: 'At Leisure',
      });
    }

    if (excursionsMap.has(excursionId)) {
      const excursion = excursionsMap.get(excursionId);
      const date = excursion?.[ExcursionKeys.date];

      const timeStringToFormatTime = (value: string): Date => {
        return !value ? null : parse(value, 'HH:mm', new Date);
      }

      Object.assign(item, {
        [ExcursionInfoTableDataKeys.excursionDate]: date,
        [ExcursionInfoTableDataKeys.excursionName]: excursion?.[ExcursionKeys.name],
        [ExcursionInfoTableDataKeys.excursionType]: excursion?.[ExcursionKeys.type],
        [ExcursionInfoTableDataKeys.excursionCost]: excursion?.[ExcursionKeys.cost],
        [ExcursionInfoTableDataKeys.shortDescription]: excursion?.[ExcursionKeys.shortDescription],
        [ExcursionInfoTableDataKeys.capacity]: excursion?.[ExcursionKeys.capacity],
        [ExcursionInfoTableDataKeys.isExactTime]: excursion?.[ExcursionKeys.isExactTime],
        [ExcursionInfoTableDataKeys.departureTime]: timeStringToFormatTime(excursion?.[ExcursionKeys.departureTime]),
        [ExcursionInfoTableDataKeys.returnTime]: timeStringToFormatTime(excursion?.[ExcursionKeys.returnTime]),
      });
    }
    requiredExcursionsDates.delete(item?.[ExcursionInfoTableDataKeys.excursionDate]);
    list.push(item);
  });

  requiredExcursionsDates.forEach(dateString => {
    const item = Object.assign({}, new ExcursionInfoTableData(), additionalData, {
      [ExcursionInfoTableDataKeys.excursionDate]: dateString,
    });
    list.push(item);
  });

  return list;
};
