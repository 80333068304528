import { Pipe, PipeTransform } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { isLogArray } from '../utils/normalize-log';

@Pipe({
  name: 'isLogArray',
})
export class IsLogArrayPipe implements PipeTransform {
  transform(delta: Delta) {
    if (!delta) {
      return null;
    }
    return isLogArray(delta);
  }
}
