import {
  AttendeeType,
  ExcursionType,
  LocalDateTimeString,
  PhoneNumber,
  SelectedExcursionConfiguration,
  SelectedExcursionOptions,
  WizardStepState,
} from '@ag-common-lib/public-api';

export enum ExcursionInfoTableDataKeys {
  fullName = 'fullName',
  attendeeId = 'attendeeId',
  conferenceDbId = 'conferenceDbId',
  registrantDbId = 'registrantDbId',
  attendeeType = 'attendeeType',
  attendeeDob = 'attendeeDob',
  excursionId = 'excursionId',
  excursionDate = 'excursionDate',
  excursionName = 'excursionName',
  excursionType = 'excursionType',
  isPaid = 'isPaid',
  isAdminSelected = 'isAdminSelected',
  bookingDate = 'bookingDate',
  selectedPreferences = 'selectedPreferences',
  excursionCost = 'excursionCost',
  capacity = 'capacity',
  phoneNumber = 'phoneNumber',
  inviteeEmail = 'inviteeEmail',
  excursionState = 'excursionState',
  configurations = 'configurations',
  shortDescription = 'shortDescription',
  isExactTime = 'isExactTime',
  departureTime = 'departureTime',
  returnTime = 'returnTime',
}

export class ExcursionInfoTableData {
  [ExcursionInfoTableDataKeys.fullName]: string;
  [ExcursionInfoTableDataKeys.conferenceDbId]: string;
  [ExcursionInfoTableDataKeys.registrantDbId]: string;
  [ExcursionInfoTableDataKeys.attendeeId]: string;
  [ExcursionInfoTableDataKeys.attendeeType]: AttendeeType;
  [ExcursionInfoTableDataKeys.attendeeDob]?: LocalDateTimeString;
  [ExcursionInfoTableDataKeys.excursionId]: string;
  [ExcursionInfoTableDataKeys.excursionName]: string;
  [ExcursionInfoTableDataKeys.excursionCost]: number;
  [ExcursionInfoTableDataKeys.excursionDate]: LocalDateTimeString;
  [ExcursionInfoTableDataKeys.excursionType]?: ExcursionType;
  [ExcursionInfoTableDataKeys.selectedPreferences]: SelectedExcursionOptions;
  [ExcursionInfoTableDataKeys.capacity]?: number;
  [ExcursionInfoTableDataKeys.isPaid]?: boolean;
  [ExcursionInfoTableDataKeys.isAdminSelected]?: boolean;
  [ExcursionInfoTableDataKeys.bookingDate]?: string;
  [ExcursionInfoTableDataKeys.inviteeEmail]?: string;
  [ExcursionInfoTableDataKeys.shortDescription]?: string;
  [ExcursionInfoTableDataKeys.phoneNumber]?: PhoneNumber;
  [ExcursionInfoTableDataKeys.excursionState]?: WizardStepState;
  [ExcursionInfoTableDataKeys.configurations]?: SelectedExcursionConfiguration;
  [ExcursionInfoTableDataKeys.isExactTime]: boolean = false;
  [ExcursionInfoTableDataKeys.departureTime]?: Date;
  [ExcursionInfoTableDataKeys.returnTime]?: Date;
}
