<ng-container *ngIf="!!log">
  <div class="change-log-address_grid">
    <dx-data-grid
      #addressGridRef
      [dataSource]="dataSource"
      [showBorders]="true"
      [showRowLines]="true"
      [columnAutoWidth]="true"
      [noDataText]="'No Addresses Selected'"
      height="100%"
    >
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <ng-container *ngFor="let key of addressKeys">
        @switch (key) {
          @case (AddressModelKeys.geoPoint) {
            <dxi-column [caption]="key | lookupDescriptionText: addressLookup">
              <dxi-column
                [caption]="AddressModelKeys.lat | lookupDescriptionText: geoPointLookup"
                [dataField]="AddressModelKeys.geoPoint"
                cellTemplate="geoPointLatCellTmp"
              >
              </dxi-column>
              <dxi-column
                [caption]="AddressModelKeys.lng | lookupDescriptionText: geoPointLookup"
                [dataField]="AddressModelKeys.geoPoint"
                cellTemplate="geoPointLngCellTmp"
              >
              </dxi-column>
              <div *dxTemplate="let cell of 'geoPointLatCellTmp'">
                <ng-container
                  *ngTemplateOutlet="
                    logViewTemplate;
                    context: {
                      oldValue: cell?.value?.oldValue?.[AddressModelKeys.lat],
                      fromValue: cell?.value?.fromValue?.[AddressModelKeys.lat],
                      toValue: cell?.value?.toValue?.[AddressModelKeys.lat],
                    }
                  "
                ></ng-container>
              </div>
              <div *dxTemplate="let cell of 'geoPointLngCellTmp'">
                <ng-container
                  *ngTemplateOutlet="
                    logViewTemplate;
                    context: {
                      oldValue: cell?.value?.oldValue?.[AddressModelKeys.lng],
                      fromValue: cell?.value?.fromValue?.[AddressModelKeys.lng],
                      toValue: cell?.value?.toValue?.[AddressModelKeys.lng],
                    }
                  "
                ></ng-container>
              </div>
            </dxi-column>
          }
          @default {
            <dxi-column
              [dataField]="key"
              [caption]="key | lookupDescriptionText: addressLookup"
              cellTemplate="logCellTmp"
              [width]="'auto'"
            ></dxi-column>
          }
        }
      </ng-container>

      <div *dxTemplate="let cell of 'logCellTmp'">
        <ng-container
          *ngTemplateOutlet="
            logViewTemplate;
            context: {
              oldValue: cell?.value?.oldValue,
              fromValue: cell?.value?.fromValue,
              toValue: cell?.value?.toValue,
            }
          "
        ></ng-container>
      </div>
    </dx-data-grid>
  </div>
</ng-container>

<ng-template #logViewTemplate let-oldValue="oldValue" let-fromValue="fromValue" let-toValue="toValue">
  <span class="change-log-detail--value me-3" *ngIf="!!oldValue">{{ oldValue }}</span>
  <span class="change-log-detail--old-value me-3" *ngIf="!!fromValue">{{ fromValue }}</span>
  <span class="change-log-detail--new-value">{{ toValue }}</span>
</ng-template>
