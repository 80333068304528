import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, EMPTY, lastValueFrom, map, take, tap } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Injectable()
export class VimeoService {
  private accessToken = 'd6dd4115affc43f2f62be38d58150ce7';
  private baseApiUrl = 'https://api.vimeo.com';
  private meApiUrl = 'https://api.vimeo.com/me';

  constructor(private http: HttpClient) {}

  getVideos(page: number = 1, perPage: number = 10, searchQuery: string = ''): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
    };

    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>([this.meApiUrl, 'videos'].join('/'), { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }

  getVideoById(path: string): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });

    const response = this.http.get<any>(`${this.meApiUrl}${path}`, { headers }).pipe(
      catchError(e => {
        return EMPTY;
      }),
    );

    return lastValueFrom(response.pipe(take(1)));
  }

  videoDataSource = new DataSource({
    paginate: true,
    pageSize: 15,
    store: new CustomStore({
      key: 'uri',
      byKey: uri => this.getVideoById(uri),
      load: async loadOptions => {
        const page = Math.ceil((loadOptions.skip || 0) / (loadOptions.take || 10)) + 1;
        const pageSize = loadOptions.take || 20;

        return this.getVideos(page, pageSize, loadOptions?.searchValue)

          .then(response => {
            return {
              data: response.data, // Vimeo API returns the videos in `data`
              totalCount: response.total, // Total video count for pagination
            };
          })
          .catch(error => {
            throw error;
          });
      },
    }),
  });

  getVideosByFolder(projectId: string) {
    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    const response = this.http.get<any>([this.meApiUrl, 'projects', projectId, 'videos'].join('/'), { headers });

    return lastValueFrom(response.pipe(take(1)));
  }

  getFolders(page: number = 1, perPage: number = 10, searchQuery: string = '') {
    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
      fields: 'uri,name,project_id',
    };

    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>([this.meApiUrl, 'projects'].join('/'), { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }

  getFolderById(path: string): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });

    const response = this.http.get<any>(`${this.baseApiUrl}${path}`, { headers }).pipe(
      map(data => {
        return data;
      }),
      catchError(e => {
        return EMPTY;
      }),
    );

    return lastValueFrom(response.pipe(take(1)));
  }

  foldersDataSource = new DataSource({
    paginate: true,
    pageSize: 15,
    store: new CustomStore({
      key: 'uri',
      byKey: uri => this.getFolderById(uri),
      load: async loadOptions => {
        const page = Math.ceil((loadOptions.skip || 0) / (loadOptions.take || 10)) + 1;
        const pageSize = loadOptions.take || 20;

        return this.getFolders(page, pageSize, loadOptions?.searchValue)

          .then(response => {
            return {
              data: response.data, // Vimeo API returns the videos in `data`
              totalCount: response.total, // Total video count for pagination
            };
          })
          .catch(error => {
            throw error;
          });
      },
    }),
  });

  getVideosInFolder(
    folderPath: string,
    page: number = 1,
    perPage: number = 10,
    searchQuery: string = '',
  ): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
      fields: 'uri,name,description',
    };

    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>(`${this.baseApiUrl}${folderPath}/videos`, { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }

  getSubFolders(folderId: number, page: number = 1, perPage: number = 10, searchQuery: string = '') {
    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
    };

    // No query params for GET "projects/{project_id}/items"
    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>([this.meApiUrl, 'projects', folderId, 'items'].join('/'), { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }

  getSubFolderById(path: string): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });

    const response = this.http.get<any>(`${this.baseApiUrl}${path}`, { headers }).pipe(
        map(data => {
          return data;
        }),
        catchError(e => {
          return EMPTY;
        }),
    );

    return lastValueFrom(response.pipe(take(1)));
  }

  getAllFolders(page: number = 1, perPage: number = 10, searchQuery: string = '') {
    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
      fields: 'uri,name,has_subfolder,created_time,metadata.connections',
    };

    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>([this.meApiUrl, 'projects'].join('/'), { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }

  getVideoByIdWithParams(path: string, fields?: string): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    const params: any = {
      fields: 'uri, name, type, height, width, description',
    };
    if (fields) {
      params.fields = params.fields.concat(`, ${fields}`);
    }

    const response = this.http.get<any>(`${this.meApiUrl}${path}`, { headers, params }).pipe(
        catchError(e => {
          return EMPTY;
        }),
    );

    return lastValueFrom(response.pipe(take(1)));
  }

  getMediaItemByIdWithParams(path: string, fields?: string): Promise<any> {
    console.log('getMediaItemByIdWithParams path', path);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    const params: any = {
      fields: 'uri, name, type, height, width, description',
    };
    if (fields) {
      params.fields = params.fields.concat(`, ${fields}`);
    }

    const response = this.http.get<any>(`${this.meApiUrl}${path}`, { headers, params }).pipe(
        catchError(e => {
          return EMPTY;
        }),
    );

    return lastValueFrom(response.pipe(take(1)));
  }

  getMediaItems(page: number = 1, perPage: number = 10, searchQuery: string = '', fields?: string) {
    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
      fields: 'type,video.id,video.name,video.uri,video.last_user_action_event_date,video.link,video.review_page.link,video.pictures,video.metadata.interactions.edit,video.manage_link,video.privacy.view,meta,folder.name,folder.uri,folder.last_user_action_event_date,folder.metadata.connections.items.uri,folder.metadata.connections.items.total',
      sort: 'last_user_action_event_date',
      precision: 3,
    };

    if (fields) {
      params.fields = params.fields.concat(`, ${fields}`);
    }

    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>([this.meApiUrl, 'items'].join('/'), { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }

  getAllVideosSubFolders(folderId: number, page: number = 1, perPage: number = 10, searchQuery: string = '') {
    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
      include_subfolders: true,
    };

    // No query params for GET "projects/{project_id}/items"
    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>([this.meApiUrl, 'projects', folderId, 'videos'].join('/'), { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }
}
