import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';
import { DxiColumnModule } from 'devextreme-angular/ui/nested';
import { ChangeLogFlightPreferenceGridComponent } from './change-log-flight-preference-grid.component';
import { ChangeLogAirlinePreferencesGridModule } from './airline-preferences/change-log-airline-preferences-grid.module';

@NgModule({
  declarations: [ChangeLogFlightPreferenceGridComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule, DxiColumnModule, ChangeLogAirlinePreferencesGridModule],
  exports: [ChangeLogFlightPreferenceGridComponent],
})
export class ChangeLogFlightPreferenceGridModule {}
