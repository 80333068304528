import { ButtonActionType, ButtonWidgetData, LinkButtonActionPayload } from './template-builder-button-widget.model';
import { TemplateBuilderContainerProperties } from './template-builder-container-properties';
import { ImageWidgetData } from './template-builder-image-widget.model';
import { MapWidgetData } from './template-builder-map-widget.model';
import { ResponsiveOptions } from './template-builder-responsive-options';
import { TextWidgetData } from './template-builder-text-widget.model';
import { VideoWidgetData } from './template-builder-video-widget.model';
import { generateShortUuid } from '../utils/generate-item-uuid';
import {
  Heading1TextWidgetData,
  Heading2TextWidgetData,
  Heading3TextWidgetData,
  Heading4TextWidgetData,
} from '../models/template-builder-headings-widget.model';

export enum TemplateBuilderWidgetTypes {
  text = 'text',
  button = 'button',
  image = 'image',
  video = 'video',
  map = 'map',
  heading1 = 'heading1',
  heading2 = 'heading2',
  heading3 = 'heading3',
  heading4 = 'heading4',
}

type TemplateBuilderWidgetDataTypeMap = {
  [TemplateBuilderWidgetTypes.text]: TextWidgetData;
  [TemplateBuilderWidgetTypes.image]: ImageWidgetData;
  [TemplateBuilderWidgetTypes.button]: ButtonWidgetData;
  [TemplateBuilderWidgetTypes.video]: VideoWidgetData;
  [TemplateBuilderWidgetTypes.map]: MapWidgetData;

  [TemplateBuilderWidgetTypes.heading1]: TextWidgetData;
  [TemplateBuilderWidgetTypes.heading2]: TextWidgetData;
  [TemplateBuilderWidgetTypes.heading3]: TextWidgetData;
  [TemplateBuilderWidgetTypes.heading4]: TextWidgetData;
};

type TemplateBuilderWidgetDataType<T extends TemplateBuilderWidgetTypes> = TemplateBuilderWidgetDataTypeMap[T];

export const TemplateBuilderWidgetTypesMap = new Map([[TemplateBuilderWidgetTypes.text, 'Text Settings']]);

export enum TemplateBuilderWidgetKeys {
  id = 'id',
  type = 'type',
  desktopData = 'desktopData',
  mobileData = 'mobileData',
  desktopContainerProperties = 'desktopContainerProperties',
  mobileContainerProperties = 'mobileContainerProperties',
  responsiveOptions = 'responsiveOptions',
}

export class TemplateBuilderWidget<T extends TemplateBuilderWidgetTypes = TemplateBuilderWidgetTypes> {
  [TemplateBuilderWidgetKeys.id]: string;
  [TemplateBuilderWidgetKeys.type]: T;
  [TemplateBuilderWidgetKeys.desktopData]?: TemplateBuilderWidgetDataType<T>;
  [TemplateBuilderWidgetKeys.mobileData]?: Partial<TemplateBuilderWidgetDataType<T>> = {};
  [TemplateBuilderWidgetKeys.desktopContainerProperties]: TemplateBuilderContainerProperties =
    new TemplateBuilderContainerProperties();
  [TemplateBuilderWidgetKeys.mobileContainerProperties]: Partial<TemplateBuilderContainerProperties> = {};
  [TemplateBuilderWidgetKeys.responsiveOptions]: ResponsiveOptions = new ResponsiveOptions();

  constructor(type: T, data?: TemplateBuilderWidgetDataType<T>) {
    this[TemplateBuilderWidgetKeys.id] = generateShortUuid();
    this[TemplateBuilderWidgetKeys.type] = type;
    if (data) {
      this[TemplateBuilderWidgetKeys.desktopData] = data;
    }
  }
}

export const DEFAULT_WIDGETS: TemplateBuilderWidget[] = [
  new TemplateBuilderWidget(TemplateBuilderWidgetTypes.text, new TextWidgetData()),
  new TemplateBuilderWidget(TemplateBuilderWidgetTypes.image, new ImageWidgetData()),
  new TemplateBuilderWidget(TemplateBuilderWidgetTypes.video, new VideoWidgetData()),
  new TemplateBuilderWidget(
    TemplateBuilderWidgetTypes.button,
    new ButtonWidgetData(ButtonActionType.link, new LinkButtonActionPayload()),
  ),
  new TemplateBuilderWidget(TemplateBuilderWidgetTypes.map, new MapWidgetData()),
];

export const HEADING_WIDGETS: TemplateBuilderWidget[] = [
  new TemplateBuilderWidget(TemplateBuilderWidgetTypes.heading1, new Heading1TextWidgetData()),
  new TemplateBuilderWidget(TemplateBuilderWidgetTypes.heading2, new Heading2TextWidgetData()),
  new TemplateBuilderWidget(TemplateBuilderWidgetTypes.heading3, new Heading3TextWidgetData()),
  new TemplateBuilderWidget(TemplateBuilderWidgetTypes.heading4, new Heading4TextWidgetData()),
];
