import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, firstValueFrom, map } from 'rxjs';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { AGMedia, AGMediaKeys, MediaContentType } from '@ag-common-lib/public-api';
import { confirm } from 'devextreme/ui/dialog';
import { ItemClickEvent } from 'devextreme/ui/tabs';
import { FILE_NAME_VALIDATION_GROUP, MediaUploaderTabs } from './ag-media-uploader-modal.models';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { base64WithoutPrefix } from './ag-media-uploader-modal.utils';

export interface FileData {
  contentType: string;
  fileName: string;
  fileSize?: number;
  base64: string;
  file: File;
}
@Injectable()
export class AgMediaUploaderModalService {
  private _file$ = new BehaviorSubject<FileData>(null);
  file$ = this._file$.asObservable();

  private readonly _inProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this._inProgress$.asObservable();

  private _selectedTab$ = new BehaviorSubject<any>({ itemIndex: 0, id: MediaUploaderTabs.FromFile });
  selectedTab$ = this._selectedTab$.asObservable();
  selectedTabIndex$ = this._selectedTab$.pipe(map(tab => tab?.itemIndex));

  selectedFromGallery$ = new BehaviorSubject<AGMedia>(null);

  isFileSelected$ = combineLatest({ image: this._file$, selectedFromGallery: this.selectedFromGallery$ }).pipe(
    map(({ image, selectedFromGallery }) => {
      return !!image?.base64 || !!selectedFromGallery;
    }),
  );

  private _urlPrefix: string;

  constructor(
    private toastrService: ToastrService,
    private cloudFunctions: CloudFunctionsService,
  ) {}

  setFile = (image?: FileData) => {
    this._file$.next(image);
  };

  setSelectedFromGallery = (mediaItem: AGMedia) => {
    this.selectedFromGallery$.next(mediaItem);
  };

  setPrefix = (urlPrefix: string) => {
    this._urlPrefix = urlPrefix;
  };

  handleSave = async () => {
    const tab = this._selectedTab$.value;
    const tabData = tab?.itemData;

    if (tabData?.id === MediaUploaderTabs.Gallery) {
      return this.selectedFromGallery$.value;
    }

    const isValid = await validateDxGroups(FILE_NAME_VALIDATION_GROUP);

    if (!isValid) {
      throw new Error('formValidation');
    }

    return await this.saveFile();
  };

  private saveFile = async (): Promise<AGMedia> => {
    this._inProgress$.next(true);

    const { base64, contentType, fileName, fileSize, file } = this._file$.value;
    const clearBase64 = base64WithoutPrefix(base64);
    const stats = await this.getImageDimensionsFromBase64(clearBase64, contentType);

    return this.cloudFunctions
      .uploadMedia({
        mediaData: base64WithoutPrefix(base64),
        filePath: this._urlPrefix,
        contentType: contentType,
        metadata: {
          fileName,
        },
      })
      .then(path => {
        this.toastrService.success('File Successfully Uploaded');

        return {
          [AGMediaKeys.wasabiPath]: path,
          // [AGMediaKeys.mediaType]: MediaContentType.image,
          [AGMediaKeys.fileName]: fileName,
          [AGMediaKeys.fileSize]: fileSize ?? file.size,
          [AGMediaKeys.contentType]: contentType,
          [AGMediaKeys.aspectRation]: stats?.aspectRatio ?? null,
        };
      })
      .catch(e => {
        this.toastrService.error('Failed to Upload File');
        throw new Error(e);
      })
      .finally(() => {
        this._inProgress$.next(false);
      });
  };

  onSelectedIndexChange = async (e: ItemClickEvent) => {
    const itemIndex = e.itemIndex;
    const itemData = e?.itemData;
    const isFileSelected = await firstValueFrom(this.isFileSelected$);

    if (!isFileSelected) {
      this._selectedTab$.next({ itemIndex, itemData });
      return;
    }

    const continueWithoutSaving = await confirm(
      '<i>You are have not saved data. <br/> Are you sure you want to Switch without Saving?</i>',
      'Confirm',
    );

    if (continueWithoutSaving) {
      this.setSelectedFromGallery(null);
      this.setFile(null);
      this._selectedTab$.next({ itemIndex, itemData });
      return;
    }

    const currentTab = this._selectedTab$.value;
    this._selectedTab$.next(null);
    setTimeout(() => {
      this._selectedTab$.next(currentTab);
    }, 0);
  };

  setSelectedTab = tab => {
    this._selectedTab$.next(tab);
  };

  onCancelEdit = async (onAfterRevertChanges?: () => void): Promise<boolean> => {
    this.selectedFromGallery$.next(null);
    this._file$.next(null);

    onAfterRevertChanges && onAfterRevertChanges();
    return true;
  };

  private getImageDimensionsFromBase64(
    base64: string,
    contentType: string,
  ): Promise<{ width: number; height: number; aspectRatio: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.src = `data:image/${contentType};base64,${base64}`;

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const aspectRatio = Number((width / height).toFixed(2));
        resolve({ width, height, aspectRatio });
      };

      img.onerror = err => {
        resolve(null);
      };
    });
  }
}
