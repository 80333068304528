import { Delta } from 'jsondiffpatch/lib/types';

export enum ChangeHistoryKey {
  log = 'log',
  oldData = 'oldData',
}

export interface ChangeHistory {
  [ChangeHistoryKey.log]: Delta;
  [ChangeHistoryKey.oldData]: any;
}
