import { LookupKeys } from '@ag-common-lib/public-api';

export enum CaptionPosition {
  none = 'none',
  above = 'above',
  below = 'below',
}

export const CaptionPositionMap = new Map([
  [CaptionPosition.none, 'None'],
  [CaptionPosition.above, 'Above'],
  [CaptionPosition.below, 'Below'],
]);

export const CAPTION_POSITION_LOOKUP = [CaptionPosition.none, CaptionPosition.above, CaptionPosition.below].map(
  value => {
    return { [LookupKeys.value]: value, [LookupKeys.description]: CaptionPositionMap.get(value) };
  },
);

export enum TextAlignment {
  start = 'start',
  center = 'center',
  end = 'end',
}

export const TextAlignmentMap = new Map([
  [TextAlignment.start, 'Left'],
  [TextAlignment.center, 'Center'],
  [TextAlignment.end, 'Right'],
]);

export const TEXT_ALIGNMENT_LOOKUP = [TextAlignment.start, TextAlignment.center, TextAlignment.end].map(value => {
  return { [LookupKeys.value]: value, [LookupKeys.description]: TextAlignmentMap.get(value) };
});

export class CaptionOptions {
  alignment: TextAlignment = TextAlignment.start;

  constructor(
    public fontSize: number = 21,
    public color: string = 'inherit',
  ) {}
}
