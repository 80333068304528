import {
  Airlines,
  FlightInfoKeys,
  FlightInformationModel,
} from 'ag-common-lib/lib/models/registration/flight-information.model';
import { LookupKeys } from 'ag-common-lib/lib/models/crm/lookup.model';

export enum FlightPreferenceKeys {
  airlinePreferences = 'airlinePreferences',
}

export type FlightPreference = Pick<
  FlightInformationModel,
  FlightInfoKeys.knownTravelerNumber | FlightInfoKeys.seatPreference | FlightInfoKeys.preferredDepartureAirport
> & {
  [FlightPreferenceKeys.airlinePreferences]: AirlinePreference[];
};

export enum AirlinePreferenceKeys {
  airline = 'airline',
  loyaltyNumber = 'loyaltyNumber',
  isFirstChoiceAirline = 'isFirstChoiceAirline',
  isSecondChoiceAirline = 'isSecondChoiceAirline',
  otherPreferredAirline = 'otherPreferredAirline',
}

export interface AirlinePreference {
  [AirlinePreferenceKeys.airline]: Airlines;
  [AirlinePreferenceKeys.loyaltyNumber]?: string;
  [AirlinePreferenceKeys.isFirstChoiceAirline]?: boolean;
  [AirlinePreferenceKeys.isSecondChoiceAirline]?: boolean;
  [AirlinePreferenceKeys.otherPreferredAirline]?: string;
}

export const FlightPreferencesKeysToPick = [
  FlightInfoKeys.knownTravelerNumber,
  FlightInfoKeys.seatPreference,
  FlightInfoKeys.preferredDepartureAirport,
] as const;

export const FlightPreferenceMap = {
  [FlightInfoKeys.knownTravelerNumber]: 'Known Traveler #',
  [FlightInfoKeys.seatPreference]: 'Seat Preference',
  [FlightInfoKeys.preferredDepartureAirport]: 'Preferred Departure Airport',
};

export const AIRLINE_PREFERENCE_LOOKUP = [
  { [LookupKeys.value]: AirlinePreferenceKeys.airline, [LookupKeys.description]: 'Preferred Airline' },
  { [LookupKeys.value]: AirlinePreferenceKeys.isFirstChoiceAirline, [LookupKeys.description]: '1st Choice' },
  { [LookupKeys.value]: AirlinePreferenceKeys.isSecondChoiceAirline, [LookupKeys.description]: '2nd Choice' },
  { [LookupKeys.value]: AirlinePreferenceKeys.loyaltyNumber, [LookupKeys.description]: 'Loyalty Number' },
  { [LookupKeys.value]: AirlinePreferenceKeys.otherPreferredAirline, [LookupKeys.description]: 'Other Airline' },
];
