import { RoleEntityPermissionMap } from './entity-permission.model';
import { BaseModel } from '../base.model';
import { EntityApplications } from '../../lists/entity-applications.enum';

export enum UserRoleModelKeys {
  role = 'role',
  activity = 'activity',
  isEnable = 'isEnable',
  appId = 'appId',
}

export class UserRole extends BaseModel {
  [UserRoleModelKeys.role]: string;
  [UserRoleModelKeys.appId]: EntityApplications;
  [UserRoleModelKeys.activity]: RoleEntityPermissionMap;
  [UserRoleModelKeys.isEnable]: boolean = true;

  constructor(appId?: EntityApplications) {
    super();

    this[UserRoleModelKeys.appId] = appId;
  }
}
