import { Address, AddressModelKeys } from '@ag-common-lib/public-api';
import { Pipe, PipeTransform } from '@angular/core';
import { Agent, AgentKeys } from '@ag-common-lib/public-api';

@Pipe({ name: 'agentMainInfoPrimaryAddresses' })
export class AgentMainInfoPrimaryAddressesPipe implements PipeTransform {
  transform(agentData: Agent): Address[] {
    return agentData?.[AgentKeys.addresses]?.filter(
      address => address?.[AddressModelKeys.isPrimaryBilling] || address?.[AddressModelKeys.isPrimaryShipping],
    );
  }
}
