<ng-container *ngIf="!!logs">
  <p class="fw-bold" *ngIf="showCaption">{{ caption }}:</p>

  <ng-container *ngIf="dataType === ChangeLogsDataType.headshot; else logsByIndexesTemplate">
    <ag-shr-change-log-headshot-detail [log]="{log: logs, oldData: oldData}"></ag-shr-change-log-headshot-detail>
  </ng-container>

  <ng-template #logsByIndexesTemplate>
    <div class="ps-3" [ngSwitch]="dataType">
      <div *ngSwitchCase="ChangeLogsDataType.address">
        <ag-shr-change-log-address-grid-detail
          [changeLogType]="ChangeLogsHistoryType.agent"
          [log]="{ log: logs | isLogArray, oldData: oldData }"
        ></ag-shr-change-log-address-grid-detail>
      </div>

      <ng-container *ngFor="let ind of logIndexes">
        <ng-container *ngIf="logs[ind] as log">
          <div *ngSwitchCase="ChangeLogsDataType.phoneNumber">
            <ag-shr-change-log-phone-detail
              [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
              [log]="log | isLogArray"
            ></ag-shr-change-log-phone-detail>
          </div>

          <div *ngSwitchCase="ChangeLogsDataType.dietaryConsideration">
            <ag-shr-change-log-dietary-consideration-detail
              [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
              [log]="log | isLogArray"
            ></ag-shr-change-log-dietary-consideration-detail>
          </div>

          <div *ngSwitchCase="ChangeLogsDataType.tShirtSizes">
            <ag-shr-change-log-tshirt-size-detail
              [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
              [log]="log | isLogArray"
            ></ag-shr-change-log-tshirt-size-detail>
          </div>

          <div *ngSwitchCase="ChangeLogsDataType.goals">
            <ag-shr-change-log-goals-detail
              [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
              [log]="log | isLogArray"
            ></ag-shr-change-log-goals-detail>
          </div>

          <div *ngSwitchCase="ChangeLogsDataType.association">
            <ag-shr-change-log-association-detail
              [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
              [log]="log | isLogArray"
            ></ag-shr-change-log-association-detail>
          </div>

          <div *ngSwitchCase="ChangeLogsDataType.favorites">
            <ag-shr-change-log-favourite-detail
              [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
              [log]="log | isLogArray"
            ></ag-shr-change-log-favourite-detail>
          </div>

          <div *ngSwitchCase="ChangeLogsDataType.agentCarrier">
            <ag-shr-change-log-carrier-detail
              [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
              [log]="log | isLogArray"
            ></ag-shr-change-log-carrier-detail>
          </div>

          <div *ngSwitchCase="ChangeLogsDataType.agentStateLicense">
            <ag-shr-change-log-state-license-detail
              [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
              [log]="log | isLogArray"
            ></ag-shr-change-log-state-license-detail>
          </div>

          <div *ngSwitchCase="ChangeLogsDataType.agentCampaigns">
            <ag-shr-change-log-agent-campaigns-detail
              [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
              [log]="log | isLogArray"
            ></ag-shr-change-log-agent-campaigns-detail>
          </div>

          <div *ngSwitchDefault>
            <ng-container
              *ngTemplateOutlet="
                defaultChangeLogTemp;
                context: {
                  keysConfig: keysConfig,
                  log: log,
                  ind: ind,
                }
              "
            ></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-template #defaultChangeLogTemp let-log="log" let-keysConfig="keysConfig" let-ind="ind">
  <ag-shr-change-log-detail-by-type
    [keysConfig]="keysConfig"
    [changeLogs]="log | isLogArray"
    [showRemovedData]="false"
    [ngClass]="{ 'change-log-detail--removed-item': isRemovedLog(ind) }"
  ></ag-shr-change-log-detail-by-type>
</ng-template>

<ng-template #emptyLog><span>&mdash;</span></ng-template>
