import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Headshot, HeadshotKeys, MediaSize, RegistrantKeys } from '@ag-common-lib/public-api';
import { normalizeLog } from '../../../utils/normalize-log';
import { ChangeHistory, ChangeHistoryKey } from '../../../change-history.model';

@Component({
  selector: 'ag-shr-change-log-headshot-detail',
  templateUrl: './change-log-headshot.component.html',
  styleUrls: ['./change-log-headshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogHeadshotComponent {
  @Input()
  set log(item: ChangeHistory) {
    const headshotLog = item?.[ChangeHistoryKey.log];
    const headshotOldData = item?.[ChangeHistoryKey.oldData];
    const cropperPayload = headshotLog?.[HeadshotKeys.imageCropperPayload];
    const isCropperPayloadArray = Array.isArray(cropperPayload);
    this.isCropped = isCropperPayloadArray ? !!cropperPayload?.filter(Boolean).length : !!cropperPayload;
    const fromRelativeX1 = !isCropperPayloadArray
      ? cropperPayload?.relativeCropperPosition?.relativeX1?.[0]
      : cropperPayload?.[0]?.relativeCropperPosition?.relativeX1;
    const fromRelativeX2 = !isCropperPayloadArray
      ? cropperPayload?.relativeCropperPosition?.relativeX2?.[0]
      : cropperPayload?.[0]?.relativeCropperPosition?.relativeX2;
    const fromRelativeY1 = !isCropperPayloadArray
      ? cropperPayload?.relativeCropperPosition?.relativeY1?.[0]
      : cropperPayload?.[0]?.relativeCropperPosition?.relativeY1;
    const fromRelativeY2 = !isCropperPayloadArray
      ? cropperPayload?.relativeCropperPosition?.relativeY2?.[0]
      : cropperPayload?.[0]?.relativeCropperPosition?.relativeY2;
    const toRelativeX1 = !isCropperPayloadArray
      ? cropperPayload?.relativeCropperPosition?.relativeX1?.[1]
      : cropperPayload?.[1]?.relativeCropperPosition?.relativeX1;
    const toRelativeX2 = !isCropperPayloadArray
      ? cropperPayload?.relativeCropperPosition?.relativeX2?.[1]
      : cropperPayload?.[1]?.relativeCropperPosition?.relativeX2;
    const toRelativeY1 = !isCropperPayloadArray
      ? cropperPayload?.relativeCropperPosition?.relativeY1?.[1]
      : cropperPayload?.[1]?.relativeCropperPosition?.relativeY1;
    const toRelativeY2 = !isCropperPayloadArray
      ? cropperPayload?.relativeCropperPosition?.relativeY2?.[1]
      : cropperPayload?.[1]?.relativeCropperPosition?.relativeY2;

    const headshotWasabiPath = headshotLog?.[HeadshotKeys.wasabiPath];
    const fromHeadshotWasabiPath = headshotWasabiPath?.[0] ?? headshotOldData?.[HeadshotKeys.wasabiPath];
    const toHeadshotWasabiPath = headshotWasabiPath?.[1] ?? headshotOldData?.[HeadshotKeys.wasabiPath];

    if (!!toHeadshotWasabiPath) {
      this.fromHeadshot = {
        [HeadshotKeys.wasabiPath]: fromHeadshotWasabiPath,
        [HeadshotKeys.imageCropperPayload]: {
          relativeCropperPosition: {
            relativeX1: fromRelativeX1 ?? null,
            relativeX2: fromRelativeX2 ?? null,
            relativeY1: fromRelativeY1 ?? '0',
            relativeY2: fromRelativeY2 ?? '1',
          },
        },
      };

      this.toHeadshot = {
        [HeadshotKeys.wasabiPath]: toHeadshotWasabiPath,
        [HeadshotKeys.imageCropperPayload]: {
          relativeCropperPosition: {
            relativeX1: toRelativeX1 ?? null,
            relativeX2: toRelativeX2 ?? null,
            relativeY1: toRelativeY1 ?? '0',
            relativeY2: toRelativeY2 ?? '1',
          },
        },
      };
    }
  }

  fromHeadshot: Headshot;
  toHeadshot: Headshot;
  isCropped = false;

  protected readonly normalizeLog = normalizeLog;
  protected readonly HeadshotKeys = HeadshotKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly MediaSize = MediaSize;
}
