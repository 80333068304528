<div class="md-4">
  <ng-container *ngFor="let item of agentInfoConfig">
    <ng-container *var="item.value | changeLogChildKey as key">
      <ng-container *ngIf="changeLogs?.[key] as log">
        <ng-container [ngSwitch]="key">
          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.websites">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="websitesKeysLookup"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.socials">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="socialsKeysLookup"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.addresses">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="addressesKeysLookup"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
              [dataType]="item.dataType"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.personal_goals">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="goalsKeysLookup"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
              [dataType]="item.dataType"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.conference_goals">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="goalsKeysLookup"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
              [dataType]="item.dataType"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.manager_goals">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="goalsKeysLookup"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
              [dataType]="item.dataType"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.phone_numbers">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [showCaption]="true"
              [keysConfig]="phoneNumbersKeysLookup"
              [dataType]="item.dataType"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.approve_deny_reasons">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="approveDenyReasonKeysLookup"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.agent_carriers">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="agentCarrierKeysLookup"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.state_licenses">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="agentStateLicensesKeysLookup"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.favorites">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [showCaption]="true"
              [keysConfig]="favoritesKeysLookup"
              [dataType]="item.dataType"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.campaigns">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [keysConfig]="campaignsKeysLookup"
              [logs]="[log]"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.state_licenses">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [showCaption]="true"
              [keysConfig]="agentStateLicensesKeysLookup"
              [dataType]="item.dataType"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.headshot">
            <ag-shr-agent-change-log-details-by-type
              [caption]="item.description"
              [showCaption]="true"
              [keysConfig]="agentHeadshotKeysLookup"
              [dataType]="item.dataType"
              [logs]="log | isLogArray"
              [oldData]="oldData?.[key]"
            ></ag-shr-agent-change-log-details-by-type>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchCase="AgentKeys.flightPreference">
            <ag-shr-change-log-flight-preference-grid-detail
              [log]="{ log: log, oldData: oldData?.[key] }"
            ></ag-shr-change-log-flight-preference-grid-detail>
          </div>

          <div class="pl-4 ps-4 mb-2" *ngSwitchDefault>
            <ag-shr-change-log-detail-by-type
              [keysConfig]="[item]"
              [changeLogs]="changeLogs"
              [showRemovedData]="true"
            ></ag-shr-change-log-detail-by-type>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
