import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'ag-common-svc/public-api';
import { ValidationResults } from '@ag-common-lib/lib';
import { elementAt } from 'rxjs';

@Pipe({ name: 'addressValidationButton' })
export class AddressValidationButtonPipe implements PipeTransform {
  constructor() {}

  transform(validationResults: ValidationResults, validateAddress): any {
    const verdict = validationResults?.verdict;

    if (!verdict) {
      return [
        {
          widget: 'dxButton',
          location: 'before',
          toolbar: 'top',
          visible: true,
          options: {
            type: 'danger',
            stylingMode: 'text',
            icon: 'agi-ban',
            onClick: validateAddress,
          },
        },
      ];
    }

    if (!!verdict?.addressComplete && !verdict?.hasUnconfirmedComponents) {
      return [
        {
          widget: 'dxButton',
          location: 'before',
          toolbar: 'top',
          visible: true,
          options: {
            type: 'success',
            stylingMode: 'text',
            icon: 'check',
            onClick: validateAddress,
          },
        },
      ];
    }
    if (!verdict?.addressComplete || verdict?.hasUnconfirmedComponents) {
      return [
        {
          widget: 'dxButton',
          location: 'before',
          toolbar: 'top',
          visible: true,
          options: {
            type: 'normal',
            stylingMode: 'text',
            icon: 'warning',
            onClick: validateAddress,
            elementAttr: {
              class: 'addresses-modal__validation-button--warning',
            },
          },
        },
      ];
    }

    return [];
  }
}
