import { Component, Input, ViewChild } from '@angular/core';
import { Dimensions, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { IAspectRatio, ImageCropperPayload } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent {
  @ViewChild('imageCropModalRef', { static: true }) imageCropModalModalComponent: ModalWindowComponent;
  @ViewChild(ImageCropperComponent, { static: false }) imageCropperComponent: ImageCropperComponent;

  @Input() imageCropperTitle: string = 'Crop Profile Picture';
  @Input() roundCropper = true;
  @Input() maintainAspectRatio = true;
  @Input() ratio: IAspectRatio = { w: 3, h: 4 };
  @Input() showToolbar = true;
  @Input() showURL = true;
  @Input() containWithinAspectRatio: boolean = false;
  @Input() onImageCropped: (payload: any) => Promise<void>;

  protected isShown = false;
  protected isCropperReady = true;
  protected pictureUrl: string;
  protected transform: ImageTransform = {};
  private relativeCropperPosition: any;

  showModal(pictureUrl: string, imageCropperPayload?: ImageCropperPayload) {
    this.isShown = false;
    this.pictureUrl = pictureUrl;

    this.relativeCropperPosition = imageCropperPayload?.relativeCropperPosition;

    this.imageCropModalModalComponent.showModal();
    this.isCropperReady = false;
  }

  protected cropperReady = (dimensions: Dimensions) => {
    if (this.relativeCropperPosition) {
      const relativeX1 = this?.relativeCropperPosition?.relativeX1;
      const relativeX2 = this?.relativeCropperPosition?.relativeX2;
      const relativeY1 = this?.relativeCropperPosition?.relativeY1;
      const relativeY2 = this?.relativeCropperPosition?.relativeY2;
      const { width, height } = dimensions;

      const x1 = Number(relativeX1) * width;
      const x2 = Number(relativeX2) * width;
      const y1 = Number(relativeY1) * height;
      const y2 = Number(relativeY2) * height;

      const cropper = {
        x1,
        x2,
        y1,
        y2,
      };

      this.imageCropperComponent.cropper = cropper;
    }
  };

  protected handleApplyCrop = async (): Promise<void> => {
    if (!this.onImageCropped) {
      return;
    }

    const { width, height } = this.imageCropperComponent.maxSize;
    const { x1, x2, y1, y2 } = this.imageCropperComponent.cropper;
    const relativeX1 = String(x1 / width);
    const relativeX2 = String(x2 / width);
    const relativeY1 = String(y1 / height);
    const relativeY2 = String(y2 / height);

    await this.onImageCropped({
      relativeCropperPosition: {
        relativeX1,
        relativeX2,
        relativeY1,
        relativeY2,
      },
    }).then(() => {
      this.imageCropModalModalComponent.hideModal();
    });
  };

  protected toggleContainWithinAspectRatio(): void {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  protected resetImage(): void {
    this.transform = {};
    this.containWithinAspectRatio = false;
  }

  protected zoomOut(): void {
    this.transform = {
      ...this.transform,
      scale: (this.transform?.scale ?? 1) - 0.1,
    };
  }

  protected zoomIn(): void {
    this.transform = {
      ...this.transform,
      scale: (this.transform?.scale ?? 1) + 0.1,
    };
  }

  protected imageLoaded = event => {
    this.isCropperReady = true;
  };
}
