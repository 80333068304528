import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import {
  Lookup,
  Agent,
  AgentKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  Lookups,
} from '@ag-common-lib/public-api';
import { DxFormComponent } from 'devextreme-angular';
import { BehaviorSubject } from 'rxjs';
import { FullAddressPipe } from '../../../../../shared/pipes/full-address.pipe';
import { PhoneNumberMaskPipe } from '../../../../../shared/pipes/phone-number-mask.pipe';
import { WasabiImgPipe } from '../../../../../shared/pipes/wasabi-img.pipe';
import { AgentImagesService } from '../../../../services/agent-images.service';

import { InitializedEvent } from 'devextreme/ui/form';

@Component({
  selector: 'ag-shr-agent-profile-data',
  templateUrl: './agent-profile-data.component.html',
  styleUrls: ['./agent-profile-data.component.scss'],
  providers: [FullAddressPipe, PhoneNumberMaskPipe, WasabiImgPipe, AgentImagesService],
})
export class AgentProfileDataComponent {
  @HostBinding('class') className = 'agent-profile-data';

  @ViewChild('agentHeaderFormRef', { static: false }) agentHeaderFormComponent: DxFormComponent;

  protected readonly AgentKeys = AgentKeys;
  protected readonly Lookups = Lookups;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  @Input() agentFormData: Agent;
  @Input() tripleColCountByScreen = {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 3,
  };
  @Input() dualColCountByScreen = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
  };

  @Input() nameItemColCountByScreen = {
    xs: 1,
    sm: 1,
    md: 3,
    lg: 3,
  };
  @Output() selectedPrefixChange = new EventEmitter<Lookup>();
  @Output() selectedSuffixChange = new EventEmitter<Lookup>();
  protected formSize$ = new BehaviorSubject(null);

  constructor(
    public phoneNumberMaskPipe: PhoneNumberMaskPipe,
    public fullAddressPipe: FullAddressPipe,
  ) {}

  public handlePrefixSelect = item => {
    this.selectedPrefixChange.emit(item);
  };

  public handleSuffixSelect = item => {
    this.selectedSuffixChange.emit(item);
  };

  public phoneNumberDisplayExpr = item => {
    return this.phoneNumberMaskPipe.transform(item, true);
  };
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;

  protected onFormInitialized = (e: InitializedEvent) => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === e.element) {
          this.formSize$.next(entry.contentRect.width);
          break;
        }
      }
    });

    resizeObserver.observe(e.element);
  };
}
