import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { DxPopoverComponent } from 'devextreme-angular';
import { SHARE_MEDIA_BUTTON_ACTION, ShareMediaButtonActionType } from './vimeo-video-share-modal.model';

@Component({
  selector: 'ag-vimeo-video-share-modal',
  templateUrl: './vimeo-video-share-modal.component.html',
  styleUrls: ['./vimeo-video-share-modal.component.scss'],
})
export class VimeoVideoShareModalComponent {
  @HostBinding('class') className = 'vimeo-video-share-modal';
  @ViewChild('shareMediaModalRef', { static: true }) shareMediaModalComponent: ModalWindowComponent;
  @ViewChild('copyPopoverTarget', { static: false }) copyPopoverTargetComponent: DxPopoverComponent;
  @ViewChild('vimeoVideoRef', { static: false }) videoElement!: ElementRef<HTMLVideoElement>;
  private _currentVideo: any;

  protected readonly shareMediaButtonAction = SHARE_MEDIA_BUTTON_ACTION;

  showModal(link: string, title: string) {
    this._currentVideo = { id: link.split('/').pop(), link: link, title: title };
    this.shareMediaModalComponent.showModal();
  }

  shareVimeo(action: ShareMediaButtonActionType) {
    const videoId = this._currentVideo.id;
    const videoTitle = this._currentVideo.title;
    const videoLink = this._currentVideo.link;

    if (action === ShareMediaButtonActionType.mailTo) {
      const mailSubject = encodeURIComponent(`Check out “${videoTitle}” from Alliance Group on Vimeo`);
      const mailBody = encodeURIComponent(
        `Check out “${videoTitle}” from Alliance Group on Vimeo.\n\n The video is available for your viewing pleasure at ${videoLink}.\n\n If you like this video, make sure you share it, too!\n\n Vimeo is filled with lots of amazing videos. See more at https://vimeo.com.`,
      );
      location.href = `mailto:?subject=${mailSubject}&body=${mailBody}`;
      return;
    }

    if (action === ShareMediaButtonActionType.facebook) {
      location.href = `https://player.vimeo.com/video/${videoId}/share/facebook`;
      return;
    }

    if (action === ShareMediaButtonActionType.twitter) {
      location.href = `https://player.vimeo.com/video/${videoId}/share/twitter`;
      return;
    }

    if (action === ShareMediaButtonActionType.tumblr) {
      location.href = `https://player.vimeo.com/video/${videoId}/share/tumblr`;
      return;
    }

    if (action === ShareMediaButtonActionType.linkedin) {
      const encodedVideoTitle = encodeURIComponent(videoTitle);
      location.href = `https://www.linkedin.com/shareArticle?mini=true&url=${videoLink}&title=${encodedVideoTitle}`;
      return;
    }
  }

  copyVimeoLink() {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(this._currentVideo.link)
        .then(() => {
          this.displayPopover();
        })
        .catch(error => {
          console.error('Error', error);
        });
    }
  }

  private displayPopover = () => {
    this.copyPopoverTargetComponent.visible = true;
    setTimeout(() => {
      this.copyPopoverTargetComponent.visible = false;
    }, 2000);
  };
}
