import { Component, EventEmitter, HostBinding, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { WasabiImgPipe } from '../../../shared/pipes/wasabi-img.pipe';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { BehaviorSubject } from 'rxjs';
import {
  AGMedia,
  AGMediaKeys,
} from '../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/ag-media.model';
import { MediaAction } from './ag-media-gallery.models';
import {
  DeleteMediaPayloadKeys,
  MediaSize,
} from '../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/functions/wasabi';
import { confirm } from 'devextreme/ui/dialog';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ag-shr-media-gallery',
  templateUrl: './ag-media-gallery.component.html',
  styleUrls: ['./ag-media-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgMediaGalleryComponent {
  @HostBinding('class') className = 'ag-media-gallery';
  @ViewChild('fullScreenImg', { static: true }) fullScreenImgModalComponent: ModalWindowComponent;

  @Input() multipleSelection: boolean = true;
  @Input() allowActions: boolean = true;
  @Input() set allowDelete(allowDelete: boolean) {
    this.editOptions.find(item => item.text === 'Delete').visible = allowDelete;
  }
  @Input() allowZoomIn: boolean = true;
  @Input() mediaItems: AGMedia[];
  @Input() set mediaActions(mediaActions: MediaAction[]) {
    this.editOptions.push(...mediaActions);
  }

  @Output() selectedItemsChange = new EventEmitter<AGMedia[]>();
  @Output() itemDeleted = new EventEmitter<AGMedia>();

  fullScreenImgUrl$ = new BehaviorSubject<string>('');
  isAllSelected = false;

  protected _selectedItems = new Set<AGMedia>();

  protected readonly MediaSize = MediaSize;
  protected readonly AGMediaKeys = AGMediaKeys;

  protected readonly editOptions: MediaAction[] = [
    { icon: 'agi-download', text: 'Download', actionFn: this.onDownloadItemClicked.bind(this) },
    {
      icon: 'agi-trash-alt',
      text: 'Delete',
      actionFn: this.onDeleteItemClicked.bind(this),
    },
  ];

  constructor(
    private wasabiImgPipe: WasabiImgPipe,
    private cloudFunctionsService: CloudFunctionsService,
    private tosterService: ToastrService,
  ) {}

  toggleImageSelection = (image: AGMedia, event: Event) => {
    if (this._selectedItems.has(image)) {
      this._selectedItems.delete(image);
      this.selectedItemsChange.next(Array.from(this._selectedItems.values()));
      return;
    }

    if (!this.multipleSelection) {
      this._selectedItems.clear();
    }

    this._selectedItems.add(image);

    this.selectedItemsChange.next(Array.from(this._selectedItems.values()));
  };

  zoomImage(image: AGMedia) {
    this.fullScreenImgUrl$.next(image[AGMediaKeys.wasabiPath]);
    this.fullScreenImgModalComponent.showModal();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  selectAll() {
    this.isAllSelected = !this.isAllSelected;
    this._selectedItems = new Set<AGMedia>(this.isAllSelected ? [...this.mediaItems] : []);
    this.selectedItemsChange.next(Array.from(this._selectedItems.values()));
  }

  onDeleteItemClicked(imageItem: AGMedia): void {
    const result = confirm('<i>Are you sure you want to Delete the file?</i>', 'Confirm');
    result.then(dialogResult => {
      if (dialogResult) {
        this.cloudFunctionsService
          .deleteMedia({ [DeleteMediaPayloadKeys.filePath]: imageItem[AGMediaKeys.wasabiPath] })
          .then(() => {
            this._selectedItems.delete(imageItem);
            this.itemDeleted.next(imageItem);
          })
          .catch(() => {
            this.tosterService.error('File Deleting Failed');
          });
      }
    });
  }

  async downloadSelectedImages() {
    if (this._selectedItems.size === 1) {
      const [head] = this._selectedItems;
      this.onDownloadItemClicked(head);
      return;
    }
    const zip = new JSZip();

    for (const image of this._selectedItems) {
      const file = await this.getBlob(image);
      zip.file(image.fileName, file);
    }

    // Generate and download the zip file
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'media.zip');
    });
  }

  async getBlob(image: AGMedia) {
    const url = this.wasabiImgPipe.transform(image.wasabiPath);
    return fetch(url).then(res => res.blob());
  }

  async onDownloadItemClicked(imageItem: AGMedia) {
    const blob = await this.getBlob(imageItem);
    saveAs(blob, imageItem[AGMediaKeys.fileName]);
  }

  logAction(e, imageItem) {
    e.itemData.actionFn(imageItem);
  }
}
