import { Inject, Injectable } from '@angular/core';
import { CommonFireStoreDao, QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import {
  EVENT_INQUIRY_COLLECTION,
  EVENT_INQUIRY_REQUEST_COLLECTION,
  EventInquiryRequest,
  EventInquiryRequestKeys,
  EventInquiryRequestStatus,
} from '@ag-common-lib/lib/models/registration/event-inquiry-request.model';
import { lastValueFrom, map, Observable, take } from 'rxjs';
import { ConferenceService } from './conference.service';
import { dateFromTimestamp } from 'ag-common-svc/lib/utils/date-from-timestamp';

@Injectable()
export class EventInquiryRequestsService {
  public readonly fsDao: CommonFireStoreDao<EventInquiryRequest>;
  private readonly eventInquiriesCollectionPath = EVENT_INQUIRY_COLLECTION;
  private readonly eventInquiryRequestsCollectionPath = EVENT_INQUIRY_REQUEST_COLLECTION;

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private conferenceService: ConferenceService,
  ) {
    this.fsDao = new CommonFireStoreDao<EventInquiryRequest>(
      fireBaseApp,
      EventInquiryRequestsService.fromFirestore,
      null,
    );
  }

  static readonly fromFirestore = (data): EventInquiryRequest => {
    return Object.assign({}, data, {
      [EventInquiryRequestKeys.reviewedDate]: dateFromTimestamp(data[EventInquiryRequestKeys.reviewedDate]),
    });
  };

  getAllByAgentDbId(agentDbId: string): Observable<EventInquiryRequest[]> {
    const qp: QueryParam[] = [
      new QueryParam(EventInquiryRequestKeys.agentDbId, WhereFilterOperandKeys.equal, agentDbId),
    ];

    return this.fsDao.getCollectionGroupSnapshot(this.eventInquiryRequestsCollectionPath, qp).pipe(
      map(snapshot => {
        return snapshot.docs.map(doc => {
          if (!doc.exists()) {
            return null;
          }
          const data = doc.data();

          return data;
        });
      }),
    );
  }

  getPendingRequestsByAgentDbId = (agentDbId: string): Observable<EventInquiryRequest[]> => {
    return this.getAllByAgentDbId(agentDbId).pipe(
      map(requests => {
        const pendingRequests = requests?.filter(request => {
          const status = request?.[EventInquiryRequestKeys.status];

          if (new Set([EventInquiryRequestStatus.approved]).has(status)) {
            return false;
          }
          return true;
        });

        return pendingRequests;
      }),
    );
  };

  getList(eventInquiryDbId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(eventInquiryDbId);

    return this.fsDao.getList(table, qp);
  }

  getIsEmailUniq = (eventInquiryDbId: string, email: string): Promise<boolean> => {
    const table = this.getCollectionPath(eventInquiryDbId);

    return lastValueFrom(
      this.fsDao
        .getList(table, [new QueryParam(EventInquiryRequestKeys.email, WhereFilterOperandKeys.equal, email)], {
          sortField: EventInquiryRequestKeys.email,
          limit: 1,
        })
        .pipe(
          map(list => {
            return !list?.length;
          }),
          take(1),
        ),
    );
  };

  async create(eventInquiryDbId: string, data: EventInquiryRequest) {
    const table = this.getCollectionPath(eventInquiryDbId);
    const eventInquiryRequest = await this.fsDao.create(
      Object.assign(data, { [EventInquiryRequestKeys.eventInquiryDbId]: eventInquiryDbId }),
      table,
    );

    return eventInquiryRequest;
  }

  getData(eventInquiryDbId: string, documentId: string): Observable<EventInquiryRequest> {
    const table = this.getCollectionPath(eventInquiryDbId);

    return this.fsDao.getDocument(table, documentId).pipe(
      map(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          return data;
        }
        return null;
      }),
    );
  }

  async update(eventInquiryDbId: string, documentId: any, updates: Partial<EventInquiryRequest>) {
    const table = this.getCollectionPath(eventInquiryDbId);

    const eventInquiryRequest = await this.fsDao.updateFields(updates, documentId, table);

    return eventInquiryRequest;
  }

  delete(eventInquiryDbId: string, documentId: any) {
    const table = this.getCollectionPath(eventInquiryDbId);

    return this.fsDao.delete(documentId, table).then(response => {
      return response;
    });
  }

  private getCollectionPath(eventInquiryDbId: string) {
    return [this.eventInquiriesCollectionPath, eventInquiryDbId, this.eventInquiryRequestsCollectionPath].join('/');
  }
}
