import { TemplateBuilderContainerProperties } from './template-builder-container-properties';
import { ResponsiveOptions } from './template-builder-responsive-options';
import { TemplateBuilderColumn } from './template-builder-column.model';
import { TemplateEditorItems } from './template-builder-item.model';
import { generateShortUuid } from '../utils/generate-item-uuid';

export class TemplateBuilderBlock {
  id: string;
  type: TemplateEditorItems.block = TemplateEditorItems.block;
  columns: TemplateBuilderColumn[];
  desktopContainerProperties: TemplateBuilderContainerProperties = new TemplateBuilderContainerProperties();
  mobileContainerProperties?: Partial<TemplateBuilderContainerProperties> = {};
  responsiveOptions: ResponsiveOptions = new ResponsiveOptions();

  constructor(columnsConfig: number | number[]) {
    this.id = generateShortUuid();
    if (Array.isArray(columnsConfig)) {
      this.columns = columnsConfig.map(ration => new TemplateBuilderColumn(ration));
    }

    if (typeof columnsConfig === 'number') {
      this.columns = new Array(columnsConfig)
        .fill(Math.round(100 / columnsConfig))
        .map(ration => new TemplateBuilderColumn(ration));
    }
  }
}

export const DEFAULT_BLOCKS: TemplateBuilderBlock[] = [
  1,
  2,
  3,
  4,
  [33, 67],
  [67, 33],
  [17, 33, 17, 33],
  [33, 17, 33, 17],
].map(columnsConfig => {
  return new TemplateBuilderBlock(columnsConfig);
});
