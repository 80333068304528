import { NgModule } from '@angular/core';
import { DxDropDownButtonModule } from 'devextreme-angular';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { PrefixSelectBoxModule } from '../../../prefix-select-box/prefix-select-box.module';
import { SuffixSelectBoxModule } from '../../../suffix-select-box/suffix-select-box.module';
import { AgentProfileDataComponent } from './agent-profile-data.component';

@NgModule({
  imports: [SharedModule, ModalWindowModule, SuffixSelectBoxModule, PrefixSelectBoxModule, DxDropDownButtonModule],

  declarations: [AgentProfileDataComponent],
  exports: [AgentProfileDataComponent],
})
export class AgentProfileDataModule {}
